import * as React from 'react';

import Alert from '@mui/material/Alert'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import TextField from '@mui/material/TextField';
import {formatDate} from '../utils.tsx'
import { ValidateDate, ValidateDateRange, ValidateString } from '../utils/Validator.tsx';
import { Typography } from '@mui/material';

export default function CharismaAlertForm(props){
    
    const [subject, setSubject] = React.useState(props.subject === undefined ? "" : props.subject);
    const [alert, setAlert] = React.useState(props.alert === undefined ? "": props.alert);
    const [wait, setWait] = React.useState(false);
    
    function handleSubmit(){
        props.handleSubmit(subject, alert);
    }
    
    return(
        <Dialog
            open={true}
        >
            <DialogTitle>
                Alerte                
            </DialogTitle>
            <DialogContent>
                {props.author !== undefined && props.date !== undefined ?
                    <div>
                        <Typography>{"Crée le" + formatDate(props.date)+ " par " +props.author}</Typography>                        
                    </div>
                    :
                    null
                }
                <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="subject"
                    label="Objet"
                    name="fName"
                    autoFocus
                    disabled={props.readOnly}
                    value={subject}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                        setSubject(event.target.value);;
                    }}
                />
                <TextField
                    margin="normal"
                    required
                    fullWidth
                    label="Description"
                    name="alert"
                    multiline
                    disabled={props.readOnly}
                    rows={5}
                    value={alert}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                        setAlert(event.target.value);
                    }}
                />                
                {wait ?
                    <Alert severity='info'>Création en cours...</Alert>
                    :
                    null
                }
            </DialogContent>
            <DialogActions>
                {props.readOnly ?
                <Button onClick={()=>props.handleCancel()}>OK</Button>
                    :
                <div>
                <Button disabled={wait} onClick={()=>handleSubmit()}>Valider</Button>
                <Button disabled={wait} onClick={()=>props.handleCancel()}>Annuler</Button>
                </div>
                }
            </DialogActions>
        </Dialog>        
    );
}