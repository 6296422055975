import * as React from 'react';

import Alert from '@mui/material/Alert'
import Autocomplete from '@mui/material/Autocomplete'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import FormControl from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import Select from '@mui/material/Select'
import TextField from '@mui/material/TextField';

import { ValidateEmail, ValidateString } from '../utils/Validator.tsx';

export default function CharismaStudentForm(props){
    
    const [firstName, setFirstName] = React.useState(props.firstName);
    const [fError, setFError] = React.useState(0);
    const [lastName, setLastName] = React.useState(props.lastName);
    const [lError, setLError] = React.useState(0);
    const [mail, setMail] = React.useState(props.mail);
    const [mError, setMError] = React.useState(0);    
    const [phone, setPhone] = React.useState(props.phone);
    const [trainingId, setTrainingId] = React.useState(props.trainings.length > 0 ? props.trainings[0].id : 0);
    const [courses, setCourses] = React.useState([]);
    const [courseId, setCourseId] = React.useState(0);
    const [financalId, setFinancalId] = React.useState(0);
    const [tutorId, setTutorId] = React.useState(0);
    const [startDate, setStartDate] = React.useState('');
    const [endDate, setEndDate] = React.useState('');
    const [courseError, setCourseError] = React.useState(0);
    const [wait, setWait] = React.useState(false);
    
    React.useEffect(()=>{
        for(let lIndex = 0; lIndex < props.trainings.length; lIndex++){
            if(props.trainings[lIndex].id === trainingId){                
                setCourses(props.trainings[lIndex].courses);
                if(props.trainings[lIndex].courses.length > 0){
                    setCourseId(props.trainings[lIndex].courses[0].id);
                    setStartDate(props.trainings[lIndex].startDate);
                    setEndDate(props.trainings[lIndex].endDate);
                }
                else{
                    setCourseId(0);
                }
                break;
            }
        } 
        
    }, [trainingId, props.trainings])    
    
    function handleSubmit(){
        const lValidFname: boolean = ValidateString(firstName, 100);
        const lValidLname: boolean = ValidateString(lastName, 100);
        const lValidMail: boolean = ValidateEmail(mail);
        const lValidCourse = courseId > 0;
        if(!lValidFname){
            setFError(1);
        }
        if(!lValidLname){
            setLError(1);
        }
        if(!lValidMail){
            setMError(1);
        }
        if(!lValidCourse){
            setCourseError(1);
        }
        else{
            setCourseError(0);
        }
        if(lValidFname && lValidLname && lValidMail && lValidCourse){
            setWait(true);
            props.handleSubmit(firstName, lastName, mail, phone, trainingId, courseId, financalId, tutorId, startDate, endDate)
        }
    }
    
    return(
        <Dialog
            open={true}
        >
            <DialogTitle>
                {props.title === undefined ?
                    'Modification des coordonnées'
                    :
                    props.title
                }
            </DialogTitle>
            <DialogContent>
                <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="fName"
                    label="Prénom"
                    name="fName"
                    autoFocus
                    error={fError > 0}
                    helperText={fError > 0 ? 'Prénom invalide' : null}
                    value={firstName}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                        setFirstName(event.target.value);
                        setFError(0);
                    }}
                />
                <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="lName"
                    label="Nom"
                    name="lName"
                    autoFocus
                    value={lastName}
                    error={lError > 0}
                    helperText={lError > 0 ? 'Nom invalide' : null}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                        setLastName(event.target.value);
                        setLError(0);
                    }}
                />
                <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="mail"
                    label="Mail"
                    name="mail"
                    autoFocus
                    value={mail}
                    error={mError > 0}
                    helperText={mError > 0 ? 'Mail invalide' : null}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                        setMail(event.target.value);
                        setMError(0)
                    }}
                />
                <TextField
                    margin="normal"
                    fullWidth
                    id="phone"
                    label="Téléphone"
                    name="phone"
                    autoFocus
                    value={phone}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                        setPhone(event.target.value);
                    }}
                />
                <FormControl sx={{mr: 4, mt:'20px'}} fullWidth>
                <InputLabel id="training-label">Formation</InputLabel>
                <Select
                    id="trainings"
                    labelId='training-label'
                    value={trainingId}
                    label='Formation'
                    onChange={(event)=>setTrainingId(event.target.value)}
                >
                {props.trainings.map((training)=>(
                    <MenuItem key={training.id}value={training.id}>{training.name}</MenuItem>
                    ))
                }
                </Select>
                </FormControl>     
                <FormControl sx={{mr: 4, mt:'20px'}} fullWidth>
                <InputLabel id="course-label">Parcours</InputLabel>
                <Select
                    id="courses"
                    labelId='course-label'
                    value={courseId}
                    label='Parcours'
                    onChange={(event)=>setCourseId(event.target.value)}
                >
                {courses.map((course)=>(
                    <MenuItem key={course.id}value={course.id}>{course.name}</MenuItem>
                    ))
                }
                </Select>
                </FormControl>
                <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="endDate"
                    label="Début"
                    name="startDate"
                    InputLabelProps={{ shrink: true, required: true }}
                    autoFocus
                    type={'date'}
        
                    value={startDate}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                        setStartDate(event.target.value);
                }}
                />                  
                <Autocomplete
                    freeSolo
                    id="free-solo-2-demo"
                    disableClearable
                    value={tutorId}
                    options={props.tutors}
                    getOptionLabel={(option) => (option.firstName + " " + option.lastName)}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Tuteur"
                        InputProps={{
                          ...params.InputProps,
                          type: 'search',
                        }}
                      />
                    )}
                    onChange={(event, newValue) => {
                        setTutorId(newValue);
                    }}
                    sx={{mt:'10px'}}
                />                                       
                <FormControl sx={{mr: 4, mt:'20px'}} fullWidth>
                <InputLabel id="financal-label">Financement</InputLabel>
                <Select
                    id="financals"
                    labelId='financal-label'
                    value={financalId}
                    label='Financement'
                    onChange={(event)=>setFinancalId(event.target.value)}
                >
                {props.financals.map((financal)=>(
                    <MenuItem key={financal.id}value={financal.id}>{financal.name}</MenuItem>
                    ))
                }
                </Select>
                </FormControl>
                {
                    wait ?
                    <Alert severity="info">{"Créaton en cours"}</Alert>
                    :
                    null
                }
            </DialogContent>
            <DialogActions>
                <Button disabled={wait} onClick={()=>handleSubmit()}>Valider</Button>
                <Button disabled={wait} onClick={()=>props.handleCancel()}>Annuler</Button>
            </DialogActions>
        </Dialog>        
    );
}