import * as React from 'react';


import Box from '@mui/material/Box'

import AddIcom from "@mui/icons-material/Add"
import EditIcon from '@mui/icons-material/Edit'
import Alert from '@mui/material/Alert'
import Button from '@mui/material/Button'
import { CharismaEvaluationTemplateApi } from '../api/evaluations/CharismaEvaluationTemplateApi.tsx';
import CharismaEvaluationTemplateQcmItemForm from './evaluationTemplateQcmItemForm.tsx';
import Checkbox from '@mui/material/Checkbox';
import DeleteIcon from '@mui/icons-material/Delete'
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Toolbar from "@mui/material/Toolbar"
import Typography from '@mui/material/Typography';





export default function CharismaEvaluationTemplateQcmItems(props){
    
    const [createItem, setCreateItem] = React.useState(false);
    const [items, setItems] = React.useState(props.items === undefined  || props.items === null ? [] : props.items);
    const [currentItem, setCurrentItem] = React.useState(null);
    const [deleteItems, setDeleteItems] = React.useState(false);
    const [selected, setSelected] = React.useState([]);
    

    
    const lEvaluationTemplateId = props.evaluationTemplateId
    const lSectionId = props.sectionId
    
    React.useEffect(()=>{
        setItems(props.items === undefined || props.items === null? [] : props.items);

    }, [props.items])
  
    function handleAddItem(pItem : string, pComment : string, pType: number, pParentId : number, pMax : number){
        const lResponse = CharismaEvaluationTemplateApi.AddItem(lEvaluationTemplateId, pItem, pComment, pType, lSectionId, pMax);
        lResponse.then((value)=>{
            if(value.error === 0){ 
                if(value.items !== null){
                    setItems(value.items);
                    setCreateItem(false);
                }
            }
            else{                
            }
        }).catch((error)=>{
            console.log("catch error");
        })
    }
    
    function handleCancelCreateItem(){
        setCreateItem(false);
    }
    
    
    
    function handleUpdateItem(pName : string, pComment : string, pType : number, pId : number ){
        const lResponse = CharismaEvaluationTemplateApi.UpdateItem(pId, pName, pComment, pType, 0);
        lResponse.then((value)=>{
            if(value.error === 0){ 
                if(value.item !== null){
                    setCurrentItem(null);
                    const lId = value.item.id;
                    let lItems = items.slice();
                    for(let lIndex = 0; lIndex < lItems.length ; lIndex++){                                               
                        if(lItems[lIndex].id === lId){
                            lItems[lIndex].comment = value.item.comment;
                            lItems[lIndex].title = value.item.title;
                            lItems[lIndex].type = value.item.type;
                        }
                    }
                    setItems(lItems);
                }
            }
            else{                
            }
        }).catch((error)=>{
            console.log("catch error");
        })
    }
    
    function handleCancelUpdateItem(){
        setCurrentItem(null);
    }    
    
    function handleDeleteItems(){
        const lResponse = CharismaEvaluationTemplateApi.DeleteItems(selected);
        lResponse.then((value)=>{
            if(value.error === 0){ 
                if(value.ids !== null){       
                    const lIds = value.ids;
                    const lItems = items.filter((item)=>(lIds.indexOf(item.id) === -1));            
                    setItems(lItems);
                }
            }
            else{                
            }
        }).catch((error)=>{
            console.log("catch error");
        })
    }
    
    
    function handleEditItem(pItem){
        setCurrentItem(pItem);
    }

    function handleSelected(pEvent : React.ChangeEvent<HTMLInputElement>, pId : number){
        let lNewSelected : number[] = [];
        let lIndex = selected.indexOf(pId);
        if(pEvent.target.checked && lIndex === -1){
            lNewSelected = lNewSelected.concat(selected, pId);
        }
        else if(lIndex !== -1){
            if(lIndex === 0){
                lNewSelected = lNewSelected.concat(selected.slice(1));
            }
            else if(lIndex === selected.length - 1){
                lNewSelected = lNewSelected.concat(selected.slice(0, -1));
            }
            else{
                lNewSelected = lNewSelected.concat(selected.slice(0, lIndex), selected.slice(lIndex + 1),);
            }
        }
        setSelected(lNewSelected);
    }        
        
    return(
        
        <Box>
            <Toolbar>
                <IconButton onClick={()=>setCreateItem(true)}>
                    <AddIcom/>
                </IconButton>
                {selected.length > 0 ?
                <IconButton onClick={()=>setDeleteItems(true)}>
                    <DeleteIcon/>
                </IconButton>
                :
                null}
            </Toolbar>        
            <Table>                               
                <TableHead>
                    <TableRow>
                        <TableCell padding="checkbox">
                        </TableCell>
                        <TableCell>
                            Nom
                        </TableCell>
                        <TableCell>
                            Correct
                        </TableCell>
                        <TableCell padding="checkbox">
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                {
                    items.map((item)=>(
                        <TableRow>
                            <TableCell>
                                <Checkbox
                                    checked={selected.indexOf(item.id) !== -1}
                                    onClick={(event)=>handleSelected(event, item.id)}
                                />
                            </TableCell>
                            <TableCell>
                                {item.title}
                            </TableCell>
                            <TableCell>
                            <Checkbox
                                checked={item.type===4}
                            />
                            </TableCell>
                            <TableCell padding="checkbox">
                                <IconButton
                                    onClick={()=>handleEditItem(item)}
                                >
                                    <EditIcon/>
                                </IconButton>
                            </TableCell>
                        </TableRow>     
                    ))
                }
                </TableBody>
            </Table>  
            {createItem ?
                <CharismaEvaluationTemplateQcmItemForm
                    title={"Ajout d'un item"}                    
                    handleSubmit={handleAddItem}
                    templateType={props.templateType}                    
                    parentId={lSectionId}
                    handleCancel={handleCancelCreateItem}
                />
                :
                null
            }
            {currentItem !== null ?
                <CharismaEvaluationTemplateQcmItemForm
                    title={"Modification d'un item"}
                    templateType={props.templateType}               
                    handleSubmit={handleUpdateItem}
                    handleCancel={handleCancelUpdateItem}
                    id={currentItem.id}
                    item={currentItem}
                />
                :
                null
            }        
            <Dialog
                open={deleteItems}
            >
                <DialogTitle>Suppression d'items</DialogTitle>
                <DialogContent>
                    <div>
                        <Typography>Etes-vous sûr de vouloir supprimer définitivement les items sélectionnés?</Typography>
                        <Alert severity='warning'>Cette action sera définitive</Alert>
                    </div>
                    
                </DialogContent>
                <DialogActions>
                    <Button autoFocus onClick={()=>setDeleteItems(false)}>
                        Annuler
                    </Button>
                    <Button onClick={()=>{handleDeleteItems();setDeleteItems(false)}}>
                        Valider
                    </Button>
                </DialogActions>
            </Dialog> 
        </Box>      
    );
    
    
}