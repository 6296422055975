
import { CharismaApi } from "../CharismaApi.js";

export const CharismaEvaluationTemplateApi = {
    CreateEvaluationTemplate: async function(pTitle : string, pType : number){
        const lDatas = new FormData();
        lDatas.append('title', pTitle);
        lDatas.append('type', pType);
        const lResponse = await CharismaApi.request({
            url:`/evaluationTemplates/createEvaluationTemplate.php`,
            method:"POST",
            data:lDatas
        })
        return lResponse.data;
    },
    ListEvaluationTemplates: async function(pType : number){

        const lResponse = await CharismaApi.request({
            url:`/evaluationTemplates/listEvaluationTemplates.php`,
            method:"GET",
            params:{
                "type":pType
            }
        })
        return lResponse.data;
    },    

    ListEvaluationTemplatesByProfile: async function(pType : number, pProfile : number){

        const lResponse = await CharismaApi.request({
            url:`/evaluationTemplates/listEvaluationTemplatesByProfile.php`,
            method:"GET",
            params:{
                "type":pType,
                "profile":pProfile
            }
        })
        return lResponse.data;
    },        
    UpdateEvaluationTemplate: async function(pTitle : string, pEvaluationTemplateId: number){
        const lDatas = new FormData();
        lDatas.append('title', pTitle);
        lDatas.append('id', pEvaluationTemplateId);
        const lResponse = await CharismaApi.request({
            url:`/evaluationTemplates/updateEvaluationTemplate.php`,
            method:"POST",
            data:lDatas
        })
        return lResponse.data;
    },
    
    DeleteEvaluationTemplates: async function(pIds : number[]){
        const lDatas = new FormData();
        pIds.forEach((lId)=>{
            lDatas.append("ids[]", lId);    
        });
        const lResponse = await CharismaApi.request({
            url:`/evaluationTemplates/deleteEvaluationTemplates.php`,
            method:"POST",
            data:lDatas
        })
        return lResponse.data;
    },
    GetEvaluationTemplate: async function(pId : number){
   
        const lResponse = await CharismaApi.request({
            url:`/evaluationTemplates/getEvaluationTemplate.php`,
            method:"GET",
            params:{
                "id" : pId
            }
        })
        return lResponse.data;
    },
    GetEvaluationTemplateSection: async function(pId : number){
   
        const lResponse = await CharismaApi.request({
            url:`/evaluationTemplates/getEvaluationTemplateSection.php`,
            method:"GET",
            params:{
                "id" : pId
            }
        })
        return lResponse.data;
    },
    AddItem: async function(pId : number, pTitle : string, pComment:string, pType : number, pParentId : number, pMax : number){
        const lDatas = new FormData();
        lDatas.append('id', pId);
        lDatas.append('title', pTitle);
        lDatas.append('type', pType);
        lDatas.append('comment', pComment);
        lDatas.append('parentId', pParentId);
        lDatas.append('max', pMax);
        const lResponse = await CharismaApi.request({
            url:`/evaluationTemplates/createEvaluationTemplateItem.php`,
            method:"POST",
            data:lDatas
        })
        return lResponse.data;
    },
    UpdateItem: async function(pId : number, pTitle : string, pComment : string, pType:number, pMax : number){
        const lDatas = new FormData();
        lDatas.append('id', pId);
        lDatas.append('title', pTitle);
        lDatas.append('type', pType);
        lDatas.append('comment', pComment);
        lDatas.append('max', pMax);
        const lResponse = await CharismaApi.request({
            url:`/evaluationTemplates/updateEvaluationTemplateItem.php`,
            method:"POST",
            data:lDatas
        })
        return lResponse.data;
    },
    DeleteItems: async function(pIds : number[]){
        const lDatas = new FormData();
        pIds.forEach((lId)=>{
            lDatas.append("ids[]", lId);    
        });
        const lResponse = await CharismaApi.request({
            url:`/evaluationTemplates/deleteEvaluationTemplateItems.php`,
            method:"POST",
            data:lDatas
        })
        return lResponse.data;
    },
    UpdateTemplateProfile: async function(pId:number, pProfile:number){
        const lDatas = new FormData();
        lDatas.append("templateId", pId);
        lDatas.append("profile", pProfile);
        const lResponse = await CharismaApi.request({
            url:`/evaluationTemplates/updateEvaluationTemplateProfile.php`,
            method:"POST",
            data:lDatas
        })
        return lResponse.data;
    },  
    ImportTemplate: async function(pFile : File,  pType : number){
        const lDatas = new FormData();
        lDatas.append('file', pFile);
        lDatas.append('type', pType);
        const lResponse = await CharismaApi.request({
            url:`/evaluationTemplates/import.php`,
            method:"POST",
            data:lDatas
        })
        return lResponse.data;
    },    
}