import * as React from 'react';

import AddIcon from '@mui/icons-material/Add';
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import CardHeader from '@mui/material/CardHeader';
import {CharismaTrainingApi} from '../api/trainings/CharismaTrainingApi.tsx';
import {CharismaAssessmentTemplateApi} from '../api/assessments/CharismaAssessmentTemplateApi.tsx';
import CharismaTrainingAssessmentForm from './trainingAssessmentForm.tsx';
import DeleteIcon from '@mui/icons-material/Delete'
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Typography from '@mui/material/Typography';



export default function CharismaTrainingAssessmentTemplate(props){
    
    const lType = props.type;
    const lTrainingId = props.trainingId;
    
    const [add, setAdd] = React.useState(false);
    const [template, setTemplate] = React.useState(null);
    const [templates, setTemplates] = React.useState([]);
    const [deleteTemplate, setDeleteTemplate] = React.useState(false);
    
    //const lCurrentUser = React.useContext(CharismaUserContext);
    const lReadWrite = !props.readOnly;
    
    
    function onCancel(){
        setAdd(false);
    }
    
        
    React.useEffect(()=>{
        const lResponse = CharismaTrainingApi.GetAssessmentTemplate(lTrainingId, lType);
        lResponse.then((value)=>{
            if(value.error === 0){
                if(value.template !== null){
                    setTemplate(value.template);
                }
                
            }
        }).catch((error)=>{
            
        })
        const lResponseTemplates = CharismaAssessmentTemplateApi.ListAssessmentTemplates(lType);
        lResponseTemplates.then((value)=>{
            if(value.error === 0){
                if(value.assessmentTemplates !== null){
                    setTemplates(value.assessmentTemplates);
                }
                
            }
        }).catch((error)=>{
            
        })
    }, [lTrainingId, lType])
    
    function handleAddTemplate(pId: number){
        const lResponse = CharismaTrainingApi.AddAssessmentTemplate(pId, lTrainingId, lType)
        lResponse.then((value)=>{
            if(value.error === 0){
                setTemplate(value.template);    
                setAdd(false);    
            }
        }).catch((error)=>{
            
        })                
    } 
    
    function handleDeleteTemplate(){
        const lResponse = CharismaTrainingApi.DeleteAssessmentTemplate(template.id);
        lResponse.then((value)=>{
            if(value.error === 0){
                setTemplate(null);        
                setDeleteTemplate(false);
            }
        }).catch((error)=>{
            
        })                
    }
        
    return(
        <Box>
            <Card>
                <CardHeader
                    title={props.title}
                    action={
                        
                        lReadWrite ?
                        <div>
                        <IconButton onClick={()=>setAdd(true)}>
                            <AddIcon/>
                        </IconButton>
                        <IconButton onClick={()=>setDeleteDocuments(true)}>
                                <DeleteIcon/>
                        </IconButton>
                        </div>
                        :
                        null                                            
                    }
                />
                <CardContent>

                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Nom</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>                                            
                        {
                            template !== null ?
                                <TableRow key={template.id}>
                                    <TableCell>
                                        {template.title}
                                    </TableCell>
                                </TableRow>
                            :
                            null
                        }                                              
                        </TableBody>
                    </Table>                
                </CardContent>
            </Card>  
            {
                add?
                <CharismaTrainingAssessmentForm
                    handleCancel={onCancel}
                    handleSubmit={handleAddTemplate}
                    templates = {templates}
                />
                :
                null
            }
            <Dialog
                open={deleteTemplate}
            >
                <DialogTitle>Suppression du modèle</DialogTitle>
                <DialogContent>
                    <Typography>
                        Etes-vous sûr de vouloir supprimer le modèle?
                    </Typography>                                             
                </DialogContent>
                <DialogActions>
                    <Button autoFocus onClick={()=>setDeleteTemplate(false)}>
                        Annuler
                    </Button>
                    <Button onClick={handleDeleteTemplate}>
                        Valider
                    </Button>
                </DialogActions>
            </Dialog> 
        </Box>      
    );
}