import * as React from 'react';

import Alert from "@mui/material/Alert"
import Button from '@mui/material/Button'
import { CharismaUserApi } from '../api/users/CharismaUserApi.tsx';
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import FormControl from '@mui/material/FormControl';
import ReactCrop, {
  centerCrop,
  makeAspectCrop,
  Crop,
  PixelCrop,
  convertToPixelCrop,
} from 'react-image-crop'
import 'react-image-crop/dist/ReactCrop.css'
import { ValidatePicture, ValidateFormat } from '../utils/Validator.tsx';

export default function CharismaSignatureFileForm(props){
    
    const [file, setFile] = React.useState(null);
    const [error, setError] = React.useState(0);
    const [message, setMessage] = React.useState('');
    const [wait, setWait] = React.useState(false);
    const [imgSrc, setImgSrc] = React.useState(null);
    const [completedCrop, setCompletedCrop] = React.useState<PixelCrop>()
    const [crop, setCrop] = React.useState<Crop>();
    const imgRef = React.useRef<HTMLImageElement>(null)
    
    function onSelectFile(e: React.ChangeEvent<HTMLInputElement>) {
        if (e.target.files && e.target.files.length > 0) {
            setCrop(undefined) // Makes crop preview update between images.
            const reader = new FileReader()
            reader.addEventListener('load', () =>
                setImgSrc(reader.result?.toString() || ''),
            )
            reader.readAsDataURL(e.target.files[0])
        }
    }
    
    function centerAspectCrop(
      mediaWidth: number,
      mediaHeight: number,
      aspect: number,
    ) {
      return centerCrop(
        makeAspectCrop(
          {
            unit: '%',
            width: 90,
          },
          aspect,
          mediaWidth,
          mediaHeight,
        ),
        mediaWidth,
        mediaHeight,
      )
    }    

    async function submit(){
        const image = imgRef.current;
        const scaleX = image.naturalWidth / image.width
        const scaleY = image.naturalHeight / image.height

        const offscreen = new OffscreenCanvas(
            completedCrop.width * scaleX,
            completedCrop.height * scaleY,
        )

        const ctx = offscreen.getContext('2d')
        if (!ctx) {
            console.log('no ctx')
        }
        const cropX = crop.x * scaleX
        const cropY = crop.y * scaleY

        ctx.drawImage(
            image,
            cropX,
            cropY,
            image.naturalWidth,
            image.naturalHeight,
            0,
            0,
            image.naturalWidth,
            image.naturalHeight
        );
        const blob = await offscreen.convertToBlob({
            type: 'image/png',
        })
        setWait(true);
        const lResponse = CharismaUserApi.UpdateSignature(blob, props.userId);        
        lResponse.then((value)=>{
            if(value.error === 0){
                setWait(false);
                if(value.signature !== undefined && props.updated !== undefined){
                    props.updated(value.signature);
                }
                props.handleCancel()
            }
            else{
                
            }
        }).catch((error)=>{
                console.log("catch error")
        })       
        
    }
    
    function handleSubmitSignature(){
        const lResponse = CharismaUserApi.UpdateSignature(file, props.userId);
        lResponse.then((value)=>{
            if(value.error === 0){
                setImgSrc(value.signature);
                setWait(false);
            }
            else{
                
            }
        }).catch((error)=>{
                console.log("catch error")
        })
    }
    
    return(
        <Dialog
            open={true}
        >
            <DialogTitle>
                {props.title === undefined ?
                    'Signature'
                    :
                    props.title
                }
            </DialogTitle>
            <DialogContent>
                <FormControl>
                    <input 
                        type="file" 
                        accept="image/*" 
                        onChange={onSelectFile} 
                    />
                </FormControl>               
                {error > 0 ?
                    <Alert severity="error">
                        {"Format de fichier invalide : " + message}
                    </Alert>                    
                    :
                    null
                }
                {wait ?
                    <Alert severity="info">{"Transfert du fichier en cours..."}</Alert>
                    :
                    null
                }
                {imgSrc !== null ?
                    <ReactCrop 
                        crop={crop} 
                        onChange={c => setCrop(c)}
                        aspect={undefined}
                        onComplete={(c) => setCompletedCrop(c)}
                    >
                        <img
                            ref={imgRef}
                            alt="Crop me"
                            src={imgSrc}
                        />
                    </ReactCrop>
                    :
                    null                    
                }
            </DialogContent>
            <DialogActions>
                <Button disabled={wait} onClick={()=>submit()}>Valider</Button>
                <Button disabled={wait} onClick={()=>props.handleCancel()}>Annuler</Button>
            </DialogActions>
        </Dialog>        
    );
}