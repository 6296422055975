import * as React from 'react';


import CharismaUserFilesManager from './userFilesManager.tsx';
import { CharismaUserApi } from '../api/users/CharismaUserApi.tsx';
import CharismaStudentDetails from '../students/studentDetails.tsx'
import CharismaTutorDetails from '../tutors/tutorDetails.tsx'
import CharismaUserInfo from './userInfo.tsx';
import Grid from '@mui/material/Grid'
import { useParams} from 'react-router-dom';



export default function CharismaUserDetails(props){
    
    let params = useParams();
    const lUserId: number =  parseInt(params['userId']);
    const lUserProfile: number =  parseInt(params['userProfile']);
    const [user, setUser] = React.useState(null);

    React.useEffect(()=>{
        const lResponse = CharismaUserApi.Get(lUserId);
        lResponse.then((value)=>{
            if(value.error === 0 && value.user !== null){
                setUser(value.user);
            }
        }).catch((error)=>{
            
        })


    }, [lUserId])     
    
    return(
        <div>
        {user !== null?
        <Grid container spacing={4}>
            <Grid item xs={12} sm={4}>
                <CharismaUserInfo
                    user={user}
                />
            </Grid>
            <Grid item xs={12} sm={8}>
                <CharismaUserFilesManager
                    userId={user.id}
                    userPofile={lUserProfile}
                />
            </Grid>
            {
                lUserProfile === 3 && user !== null?
                <Grid item xs={12} md={12}>
                    
                    <CharismaStudentDetails
                        studentId={user.id}
                    />
                </Grid>
                :
                lUserProfile === 2 && user !== null?
                <Grid item xs={12} md={12}>
                    <CharismaTutorDetails
                        tutorId={user.id}
                    />
                </Grid>
                :
                null
            }
        </Grid>
        :
        null
       }
       </div>

    );
    
    
}