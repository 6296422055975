import * as React from 'react';

import Alert from '@mui/material/Alert'
import Button from '@mui/material/Button'
import { CharismaUserApi } from '../api/users/CharismaUserApi.tsx';
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';
import VisibilityIcon from '@mui/icons-material/Visibility';



export default function CharismaUserUpdatePasswordForm(props){
    
    const [password, setPassword] = React.useState('');
    const [showPassword, setShowPassword] = React.useState(false);
    const [newPassword, setNewPassword] = React.useState('');
    const [showNew, setShowNew] = React.useState(false);
    const [confirmPassword, setConfirmPassword] = React.useState('');
    const [showConfirm, setShowConfirm] = React.useState(false);
    const [error, setError] = React.useState(0);
    const [wait, setWait] = React.useState(false);
    const [valid, setValid] = React.useState(false);
    
    function handleSubmit() {
        let lValid :boolean = confirmPassword === newPassword;
        
        if(lValid){
            setWait(true);
            const lResponse = CharismaUserApi.UpdatePassword(props.userId, password, newPassword);
            lResponse.then((value)=>{
            if(value.error === 0){
                setError(0);
                setValid(true);
            }
            else{
                setError(2);
            }
            }).catch((error)=>{
                console.log("catch error")
            })
        }
        else{
            setError(1);
        }
    }
    
    return(
        <Dialog
            open={true}
        >
            <DialogTitle>
                {props.title === undefined ?
                    'Modification du mot de passe'
                    :
                    props.title
                }
            </DialogTitle>
            <DialogContent>
                <TextField
                    margin="normal"
                    required
                    fullWidth
                    name="changepass"
                    label="Mot de Passe"
                    type={showPassword ? "text" : "password"}
                    id="changepass"
                    autoComplete="current-password"
                    value={password}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                        setPassword(event.target.value);
                    }}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="start">
                                <IconButton
                                    onClick={() => { setShowPassword(false) }}
                                    onMouseDown={() => { setShowPassword(true) }}
                                >
                                    <VisibilityIcon />
                                </IconButton>
                            </InputAdornment>
                        ),
                    }}
                />     
                <TextField
                    margin="normal"
                    required
                    fullWidth
                    name="changenew"
                    label="Nouveau mot de Passe"
                    type={showNew ? "text" : "password"}
                    id="changenew"
                    autoComplete="current-password"
                    value={newPassword}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                        setNewPassword(event.target.value);
                    }}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="start">
                                <IconButton
                                    onClick={() => { setShowNew(false) }}
                                    onMouseDown={() => { setShowNew(true) }}
                                >
                                    <VisibilityIcon />
                                </IconButton>
                            </InputAdornment>
                        ),
                    }}
                />   
                <TextField
                    margin="normal"
                    required
                    fullWidth
                    name="changeconfirm"
                    label="Confirmer le nouveau mot de Passe"
                    type={showConfirm ? "text" : "password"}
                    id="changenew"
                    autoComplete="current-password"
                    value={confirmPassword}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                        setConfirmPassword(event.target.value);
                    }}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="start">
                                <IconButton
                                    onClick={() => { setShowConfirm(false) }}
                                    onMouseDown={() => { setShowConfirm(true) }}
                                >
                                    <VisibilityIcon />
                                </IconButton>
                            </InputAdornment>
                        ),
                    }}
                />     
                {error === 1?
                    <Alert severity='error'>Les mots de passe sont différents</Alert>
                    :
                 error === 2 ?
                    <Alert severity='error'>Erreur lors de l'authentification</Alert>
                    :
                    null
                }     
                {
                    valid?
                    <Alert severity='info'>Mot de passe mis à jour</Alert>
                    :
                    null
                }                     
                {
                    wait ?
                    <Alert severity="info">{"Mise à jour du mot de passe en cours"}</Alert>
                    :
                    null
                }
            </DialogContent>
            <DialogActions>
                {
                    valid?
                    <Button onClick={()=>props.handleCancel()}>OK</Button>
                    :
                    <div>
                        <Button disabled={wait} onClick={()=>handleSubmit()}>Valider</Button>
                        <Button disabled={wait} onClick={()=>props.handleCancel()}>Annuler</Button>
                    </div>        
                }
                
            </DialogActions>
        </Dialog>        
    );
}