import * as React from 'react';

import Checkbox from '@mui/material/Checkbox';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';



export default function CharismaStudentTrainingEvaluationFormItems(props){
        
    const [items, setItems] = React.useState(props.items);
    
        
    
    function handleChange(pItemId : number, pEvent : React.ChangeEvent<HTMLInputElement>){
        const lChecked = pEvent.target.checked;
        let lValue = lChecked ? 1 : 0;
        let lItems = items.slice();
        for(let lIndex = 0; lIndex < lItems.length; lIndex++){
            if(lItems[lIndex].id === pItemId){
                lItems[lIndex].note = lValue;
                break;
            }
        }
        props.handleChangeNote(lValue, pItemId);
        setItems(lItems);
    }
  

                                              
    return(
        <Table>
            <TableBody>
                {items.map((item)=>(
                    <TableRow>
                        <TableCell padding='checkbox'>
                            <Checkbox
                                checked={item.note===1}
                                onChange={(event)=>handleChange(item.id, event)}
                                disabled={props.readOnly}
                            />
                        </TableCell>
                        <TableCell>
                            {item.title}
                        </TableCell>
                    </TableRow>  
                ))
                
                }
            </TableBody>
        </Table>          
    );
}