import * as React from 'react';

import Alert from '@mui/material/Alert'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import TextField from '@mui/material/TextField';

import { ValidateDate, ValidateDateRange, ValidateString } from '../utils/Validator.tsx';

export default function CharismaCourseForm(props){
    
    const [name, setName] = React.useState(props.name);
    const [nError, setNError] = React.useState(0);
    const [startDate, setStartDate] = React.useState(props.startDate);
    const [sError, setSError] = React.useState(0);
    const [dateError, setDateError] = React.useState(0);
    const [endDate, setEndDate] = React.useState(props.endDate); 
    const [eError, setEError] = React.useState(0);
    const [wait, setWait] = React.useState(false);
    
    function handleSubmit(){
        const lValidName: boolean = ValidateString(name, 32);
        if(!lValidName){
            setNError(1);
        }
        
        let lValidMin: number = ValidateDate(startDate);
        if(lValidMin === 0 && props.minDate !== undefined){
            lValidMin = ValidateDateRange(props.minDate, startDate);
            if(lValidMin === 1){
                setSError(2);
                setDateError(0);
            }
            else{
                setSError(0);
            }
            
        }
        else{
            setSError(lValidMin);
        }
        
        let lValidMax : number = ValidateDate(endDate);
        if(lValidMax === 0 && props.maxDate !== undefined){
            lValidMax = ValidateDateRange(endDate, props.maxDate);
            if(lValidMax === 1){
                setEError(2);
            }
            else{
                setEError(0);
            }
        }
        else{
            setEError(lValidMax);
        }
        
        if(lValidMax === 0 && lValidMin === 0){
            const lValidRange : number = ValidateDateRange(startDate, endDate);
            if(lValidRange !== 0){
                setDateError(1);   
            }
            else if(lValidName && props.id === undefined){
                setWait(true);
                props.handleSubmit(name, startDate, endDate);
            }
            else if(lValidName){
                setWait(true);
                props.handleSubmit(name, startDate, endDate, props.id);
            }
        }
    }
    
    return(
        <Dialog
            open={true}
        >
            <DialogTitle>
                {props.title === undefined ?
                    'Modification du parcours'
                    :
                    props.title
                }
            </DialogTitle>
            <DialogContent>
                <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="name"
                    label="Nom"
                    name="fName"
                    autoFocus
                    error={nError > 0}
                    helperText={nError > 0 ? 'Nom invalide' : null}
                    value={name}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                        setName(event.target.value);
                        setNError(0);
                    }}
                />
                <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="startDate"
                    label="Début"
                    name="startDate"
                    InputLabelProps={{ shrink: true, required: true }}
                    type={'date'}
                    error={sError > 0}
                    helperText={sError === 1 ? 'Date invalide' : sError === 2 ? 'Date de début antérieure à la date de début de la formation' : null}
                    value={startDate}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                        setStartDate(event.target.value);
                    }}
                />
                <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="endDate"
                    label="Fin"
                    name="endDate"
                    InputLabelProps={{ shrink: true, required: true }}
                    type={'date'}
                    error={eError > 0}
                    helperText={eError === 1 ? 'Date invalide' : eError === 2 ? 'Date de fin postérieure à la date de fin de la formation' : null}               
                    value={endDate}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                        setEndDate(event.target.value);
                    }}
                />
                {dateError === 1 ?
                    <Alert severity="error">{'La date de début est supérieure à la date de fin'}</Alert>
                    :
                    null
                }
                {wait ?
                    <Alert severity='info'>{props.id === undefined ? "Création du parcours en cours" : "Modification en cours"}</Alert>
                    :
                    null
                }
            </DialogContent>
            <DialogActions>
                <Button disabled={wait} onClick={()=>handleSubmit()}>Valider</Button>
                <Button disabled={wait} onClick={()=>props.handleCancel()}>Annuler</Button>
            </DialogActions>
        </Dialog>        
    );
}