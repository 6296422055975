import * as React from 'react';


import Box from '@mui/material/Box'

import AddIcon from '@mui/icons-material/Add'
import EditIcon from '@mui/icons-material/Edit'
import Alert from '@mui/material/Alert'
import Button from '@mui/material/Button'
import Card from'@mui/material/Card'
import CardContent from'@mui/material/CardContent'
import CardHeader from'@mui/material/CardHeader'
import { CharismaEvaluationTemplateApi } from '../api/evaluations/CharismaEvaluationTemplateApi.tsx';

import CharismaEvaluationTemplateSectionForm from './evaluationTemplateSectionForm.tsx';
import CharismaEvaluationTemplateItems from './evaluationTemplateItems.tsx';
import CharismaEvaluationTemplateQcmQuestion from './evaluationTemplateQcmQuestion.tsx'
import CharismaEvaluationTemplatePeriodicSection from './evaluationTemplatePeriodicSection.tsx';

import DeleteIcon from '@mui/icons-material/Delete'
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';


import Typography from '@mui/material/Typography';





export default function CharismaEvaluationTemplateSection(props){
    
    const [title, setTitle] = React.useState("");
    const [comment, setComment] = React.useState("");
    const [items, setItems] = React.useState([]);
    const [deleteSection, setDeleteSection] = React.useState(false);
    const [modify, setModify] = React.useState(false);
    const [createQuestion, setCreateQuestion] = React.useState(false);    

    
    const lSectionId = props.sectionId;
    const lEvaluationTemplateId = props.evaluationTemplateId;
    
    React.useEffect(()=>{
        const lResponse = CharismaEvaluationTemplateApi.GetEvaluationTemplateSection(lSectionId);
        lResponse.then((value)=>{
            if(value.error === 0){ 
                if(value.section !== null){
                    setTitle(value.section.title)
                    setComment(value.section.comment)
                    setItems(value.section.items);
                    setModify(false);             
                }
            }
            else{
                
            }
        }).catch((error)=>{
                console.log("catch error")
        })

    }, [lSectionId])    
    
    
    function handleCancel(){
        setModify(false);
    }
    
    function handleDelete(){
        props.handleDeleteSection(lSectionId);
    }
               
    function handleUpdate(pTitle : string, pComment: string, pId : number){
        const lResponse = CharismaEvaluationTemplateApi.UpdateItem(pId, pTitle,  pComment, 0, 0);
        lResponse.then((value)=>{
            if(value.error === 0){ 
                if(value.section !== null){
                    setTitle(value.section.title)
                    setComment(value.section.comment)
                    setModify(false);             
                }
            }
            else{
                
            }
        }).catch((error)=>{
                console.log("catch error")
        })
    }
    
    function handleCreateQuestion(pTitle : string, pComment: string, pId : number){
        const lResponse = CharismaEvaluationTemplateApi.AddItem(pId, pTitle, pComment, 3, lSectionId, 0)
        lResponse.then((value)=>{
            if(value.error === 0){                
                if(value.items !== null){
                    setItems(value.items);
                    setCreateQuestion(false);             
                }
            }
            else{
                
            }
        }).catch((error)=>{
                console.log("catch error")
        })
    }
    
    
    function handleDeleteQuestion(pQuestionId : number){
        const lResponse = CharismaEvaluationTemplateApi.DeleteItems([pQuestionId])
        lResponse.then((value)=>{
            if(value.error === 0){ 
                if(value.ids !== null){
                    let lItems = items.filter((item)=>(value.ids.indexOf(item.id) === -1));
                    setItems(lItems);             
                }
            }
            else{
                
            }
        }).catch((error)=>{
                console.log("catch error")
        })        
    }
    
    function handleCancelQuestion(){
        setCreateQuestion(false);
    }   
                                  
    return(
        
        <Box sx={{margin:'5px'}}>
            <Card>
                <CardHeader
                    title={title}
                    subheader={comment}
                    action={
                        <div>
                            {props.templateType === 1 || props.templateType === 2?
                                <IconButton onClick={()=>setCreateQuestion(true)}>
                                    <AddIcon/>
                                </IconButton>
                                :
                                null
                            }
                            <IconButton>
                                <EditIcon
                                    onClick={()=>setModify(true)}
                                />
                            </IconButton>
                            <IconButton onClick={()=>setDeleteSection(true)}>
                                <DeleteIcon/>
                            </IconButton>
                        </div>
                    } 
                />
                <CardContent>
                    {props.templateType === 0 ?
                        <CharismaEvaluationTemplateItems
                            sectionId = {lSectionId}
                            evaluationTemplateId={lEvaluationTemplateId}
                            templateType={props.templateType}
                            items={items}
                        />
                        :
                        props.templateType === 1 || props.templateType === 2 ?
                            <div>
                            {
                                items.map((item)=>(
                                    <CharismaEvaluationTemplateQcmQuestion
                                        sectionId = {lSectionId}
                                        evaluationTemplateId={lEvaluationTemplateId}
                                        templateType={props.templateType}
                                        question={item}
                                        handleDeleteQuestion={handleDeleteQuestion}
                                />
                                ))
                            }
                            </div>
                            :
                            props.templateType === 3?
                            <div>
                            {
                                items.map((item)=>(
                                    <CharismaEvaluationTemplatePeriodicSection
                                        sectionId = {lSectionId}
                                        evaluationTemplateId={lEvaluationTemplateId}
                                        templateType={props.templateType}
                                        section={item}
                                        handleDeleteSection={handleDeleteQuestion}
                                />
                                ))
                            }
                            </div>
                        :
                        null
                    }
                    
                </CardContent>
            </Card>  
            {modify ?
                <CharismaEvaluationTemplateSectionForm
                    title={"Modification"}
                    id={lSectionId}
                    name={title}
                    comment={comment}
                    handleSubmit={handleUpdate}
                    handleCancel={handleCancel}
                />
                :
                null
            }
            {createQuestion ?
                <CharismaEvaluationTemplateSectionForm
                    title={"Ajout d'une question"}
                    id={lEvaluationTemplateId}
                    handleSubmit={handleCreateQuestion}
                    handleCancel={handleCancelQuestion}
                />
                :
                null
            }

         
            <Dialog
                open={deleteSection}
            >
                <DialogTitle>Suppression d'items</DialogTitle>
                <DialogContent>
                    <div>
                        <Typography>Etes-vous sûr de vouloir supprimer définitivement la section?</Typography>
                        <Alert severity='warning'>Cette action sera définitive</Alert>
                    </div>
                    
                </DialogContent>
                <DialogActions>
                    <Button autoFocus onClick={()=>setDeleteSection(false)}>
                        Annuler
                    </Button>
                    <Button onClick={()=>{handleDelete();setDeleteSection(false)}}>
                        Valider
                    </Button>
                </DialogActions>
            </Dialog> 
        </Box>      
    );
    
    
}