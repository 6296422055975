import * as React from 'react';

import AppBar from '@mui/material/AppBar'
import Box from '@mui/material/Box'
import CharismaUserMenu from '../user/userMenu.tsx';
import HomeIcon from '@mui/icons-material/Home';
import { Link } from 'react-router-dom';
import MenuItem from '@mui/material/MenuItem'
import Toolbar from '@mui/material/Toolbar'
import Typography from '@mui/material/Typography';

import { CharismaUserContext } from '../context.tsx';

export default function CharismaTeacherMainMenu(props){
            
    const lCurrentUser = React.useContext(CharismaUserContext);

    
    return(
        <AppBar position="static" style={{marginBottom:'10px'}}>
            <Toolbar>
                <CharismaUserMenu
                    handleDisconnect={props.handleDisconnect}
                />
                <Typography>{lCurrentUser.firstName + ' ' + lCurrentUser.lastName}</Typography>
                 <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
                    <MenuItem
                        component={Link}
                        to={'/'}
                    >
                        <HomeIcon/>
                    </MenuItem>
                    <MenuItem
                        component={Link}
                        to={'/trainings'}
                    >
                        Formations
                    </MenuItem>
                    <MenuItem
                        component={Link}
                        to={'/documents'}
                    >
                        Documents
                    </MenuItem>
                 </Box>
            </Toolbar>
        </AppBar>  
    );
}