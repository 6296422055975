import * as React from 'react';

import AppBar from '@mui/material/AppBar'
import Box from '@mui/material/Box'
import { Link } from 'react-router-dom';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import IconButton from '@mui/material/IconButton';
import MenuItem from '@mui/material/MenuItem'
import Toolbar from '@mui/material/Toolbar'
import { CharismaUserContext } from '../context.tsx';

export default function CharismaTutorSpaceMenu(props){
    
    
    const [anchorReportMenu, setAnchorReportMenu] = React.useState(null);
    const openReportMenu: boolean = Boolean(anchorReportMenu);
    
    const [anchorMobileMenu, setAnchorMobileMenu] = React.useState(null);
    const openMobileMenu: boolean = Boolean(anchorMobileMenu);        

    
    const lCurrentUser = React.useContext(CharismaUserContext);
    
    

    function handleOpenReportMenu(event: React.MouseEvent<HTMLElement>){
        setAnchorReportMenu(event.currentTarget);
    };
    
    function handleCloseReportMenu(){
        setAnchorReportMenu(null);
    };
    
    function handleOpenMobileMenu(event: React.MouseEvent<HTMLElement>){
        setAnchorMobileMenu(event.currentTarget);
    };
    
    function handleCloseMobileMenu(){
        setAnchorMobileMenu(null);
    };    
    

    return(
        <AppBar position="static" style={{marginBottom:'10px', background:'gray'}}>
            <Toolbar>
                 <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>

                    <MenuItem
                        component={Link}
                        to={'/studentTraining/' + props.studentTrainingId + '/book'}
                    >
                        Livret de Formation
                    </MenuItem>
                    <MenuItem
                        component={Link}
                        to={'/studentTraining/' + props.studentTrainingId + '/teaching-docs'}
                    >
                        Cours
                    </MenuItem>
                    <MenuItem
                        onClick={(event)=>handleOpenReportMenu(event)}
                    >
                        Bilans
                    </MenuItem>                    
                    <MenuItem
                        component={Link}
                        to={'/studentTraining/' + props.studentTrainingId + '/board'}
                    >
                        Carnet de Bord
                    </MenuItem>
                    <MenuItem
                        component={Link}
                        to={'/studentTraining/' + props.studentTrainingId + '/tutors-doc'}
                    >
                        Documents Tuteurs
                    </MenuItem>                    
                 </Box>    
                 <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
                    <IconButton color='inherit'
                        onClick={(event)=>handleOpenMobileMenu(event)}
                    >
                        <MenuIcon/>
                    </IconButton>
                 </Box>
            
            </Toolbar>
            <Menu
                open={openReportMenu}
                anchorEl={anchorReportMenu}
                onClose={handleCloseReportMenu}
                onClick={handleCloseReportMenu}
            >
                <MenuItem
                    component={Link}
                    to={'/studentTraining/' + props.studentTrainingId + '/daily-assessments'}
                >
                    Bilans Quotidiens
                </MenuItem>
                <MenuItem
                    component={Link}
                    to={'/studentTraining/' + props.studentTrainingId + '/monthly-assessments'}
                >
                    Bilans Mensuels
                </MenuItem>
            </Menu>      
            <Menu
                open={openMobileMenu}
                anchorEl={anchorMobileMenu}
                onClose={handleCloseMobileMenu}
                onClick={handleCloseMobileMenu}
            >
                    <MenuItem
                        component={Link}
                        to={'/studentTraining/' + props.studentTrainingId + '/book'}
                    >
                        Livret de Formation
                    </MenuItem>
                    <MenuItem
                        component={Link}
                        to={'/studentTraining/' + props.studentTrainingId + '/teaching-docs'}
                    >
                        Cours
                    </MenuItem>
                    <MenuItem
                        component={Link}
                        to={'/studentTraining/' + props.studentTrainingId + '/daily-assessments'}
                    >
                        Bilans Quotidiens
                    </MenuItem>
                    <MenuItem
                        component={Link}
                        to={'/studentTraining/' + props.studentTrainingId + '/monthly-assessments'}
                    >
                        Bilans Mensuels
                    </MenuItem>
                    <MenuItem
                        component={Link}
                        to={'/studentTraining/' + props.studentTrainingId + '/board'}
                    >
                        Carnet de Bord
                    </MenuItem>        
                    <MenuItem
                        component={Link}
                        to={'/studentTraining/' + props.studentTrainingId + '/tutors-doc'}
                    >
                        Documents Tuteurs
                    </MenuItem>                           
            </Menu>                             
        </AppBar>  
    );
}