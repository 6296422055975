import * as React from 'react';

import Alert from "@mui/material/Alert"
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'


export default function CharismaMessage(props){
    
    const [open, setOpen] = React.useState(true);
    

    
    return(
        <Dialog
            open={open}
        >
            <DialogTitle>
                {props.title === undefined ?
                    'Message'
                    :
                    props.title
                }
            </DialogTitle>
            <DialogContent>
                {props.level === 'info' || props.level===undefined?
                    <Alert severity="info">{props.message}</Alert>
                    :
                    null
                }
            
                {props.level === 'error'?
                    <Alert severity="error">{props.message}</Alert>
                    :
                    null
                }

                
            </DialogContent>
            <DialogActions>
                <Button onClick={()=>props.handleClose(false)}>OK</Button>
            </DialogActions>
        </Dialog>        
    );
}