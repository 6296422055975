import * as React from 'react';

import Alert from '@mui/material/Alert'
import Button from '@mui/material/Button'
import Box from '@mui/material/Box'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import {extractDay} from '../utils.tsx'
import Rating from '@mui/material/Rating'
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TextField from '@mui/material/TextField';


export default function CharismaStudentTrainingShuttleForm(props){
    
    interface item{
        id:number,
        type: number,
        title: string,
        note:number
    }
    
    
    const [studentPartId, setStudentPartId] = React.useState(0);
    const [otherPartId, setOtherPartId] = React.useState(0);
    const [studentItems, setStudentItems] = React.useState([]);
    const [otherItems, setOtherItems] = React.useState([]);
    const [id, setId] = React.useState(0);
    const [date, setDate] = React.useState('');
    const [readOnly, setReadOnly] = React.useState(props.readOnly)
    const [wait, setWait] = React.useState(false);
    
    React.useEffect(()=>{
        const lParts = props.assessment.parts; 
        if(lParts.length === 2){
            setStudentItems(lParts[0].items);
            setStudentPartId(lParts[0].id);
            setOtherItems(lParts[1].items);
            setOtherPartId(lParts[1].id);
            setReadOnly(lParts[0].isSigned || props.readOnly)
        }        
        setDate(props.assessment.date);
        setId(props.assessment.id);
    }, [props.assessment, props.profile, props.readOnly])   

    
    const lUserProfile: number = props.userProfile;
    
    function handleSubmit(){
        if((lUserProfile === 0 || lUserProfile === 1) && props.type === 2){
            setWait(true);
            handleSubmitOther(otherItems);
        }
        else if(lUserProfile === 2 && props.type === 3){
            setWait(true);
            handleSubmitOther(otherItems);
        }
        else if(lUserProfile === 3){
            setWait(true);
            handleSubmitStudent(studentItems)
        }
    }

    
    function handleSubmitStudent(){
        props.handleSubmit(studentItems, studentPartId, id);
    }
    
    function handleSubmitOther(){
        props.handleSubmit(otherItems, otherPartId, id);
    }    

    function createTitle(){
        let lTitle = "Fiche Navette du " + extractDay(date);

        return( 
            <DialogTitle>
                {lTitle}
            </DialogTitle>
        );
    }
       
    function setStudentValue(pItemId  :number, pValue : string){
        let lItems = studentItems.slice();
        for(let lIndex = 0; lIndex < lItems.length; lIndex++){
            if(lItems[lIndex].id === pItemId){
                lItems[lIndex].comment = pValue;
            }
        }
        setStudentItems(lItems);
    }

    function setOtherValue(pItemId  :number, pValue : string){
        let lItems = otherItems.slice();
        for(let lIndex = 0; lIndex < lItems.length; lIndex++){
            if(lItems[lIndex].id === pItemId){
                lItems[lIndex].comment = pValue;
            }
        }
        setOtherItems(lItems);
    }
    
    function createItem(pItem : item, pReadOnly : boolean){
        if(pItem.type === 0){
            return(
                <Box sx={{m:'10px'}}>
                    <Rating
                        value={pItem.value}
                        onChange={(event, value)=>handleChange(pItem.id, value)}
                        max={4}
                        disabled = {readOnly}
                    />
                </Box>  
            );
        }
        else if(pItem.type === 1){
            return(
                <Box sx={{m:'10px'}}>
                    <TextField
                        id={pItem.id}
                        name={pItem.title}
                        fullWidth
                        multiline
                        disabled = {pReadOnly}
                        rows={3}
                        value={pItem.comment}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                lUserProfile === 3?
                                setStudentValue(pItem.id, event.target.value):
                                setOtherValue(pItem.id, event.target.value)
                            }}
                    />
                </Box>  
            );
        }
    }
    
    function createItems(){
        let lItems = [];
        for(let lIndex = 0; lIndex < studentItems.length; lIndex++){
            let lObject ={
                "title":studentItems[lIndex].title,
                "student":studentItems[lIndex],
                "other":otherItems[lIndex]
            }
            lItems.push(lObject);
        }

        return(        
            lItems.map((lItem)=>(
                <TableRow id={lItem.student.id}>
                    <TableCell>
                        {lItem.title}
                    </TableCell>
                    <TableCell>
                        {createItem(lItem.student, lUserProfile !== 3)}
                    </TableCell>
                    <TableCell>
                        {createItem(lItem.other, 
                            !((lUserProfile === 0 || lUserProfile === 1) && props.type===2 || (lUserProfile === 2 && props.type === 3) ) )}
                    </TableCell>
                </TableRow> 
            ))
        )
    }
    
    function createItemsTable(){
        return(
            <TableContainer overflowX='auto'>
            <Table>
                <TableHead>
                    <TableRow id={props.assessment.id}>
                        <TableCell>                            
                        </TableCell>
                        <TableCell align='center' sx={{minWidth:200}}>
                            Stagiaire
                        </TableCell>
                        <TableCell align='center' sx={{minWidth:200}}>
                            {props.type === 2 ? "Formateur" : "Tuteur"}
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {createItems()}
                </TableBody>
            </Table>
            </TableContainer>
        )
    }    
    
    return(
        <Dialog
            open={true}
            fullWidth
            maxWidth={'xl'}
        >
            
            {createTitle()}     
            <DialogContent>         
                {
                    createItemsTable()
                }
                {wait ?
                    <Alert severity="info">Validation en cours</Alert>
                    :
                    null
                }
            </DialogContent>
            <DialogActions>
                {props.readOnly ?
                    <Button onClick={()=>props.handleCancel()}>OK</Button>
                    :
                    <div>
                    <Button disabled={wait} onClick={()=>handleSubmit()}>Valider</Button>                
                    <Button disabled={wait} onClick={()=>props.handleCancel()}>Annuler</Button>
                    </div>
                }
            </DialogActions>
        </Dialog>        
    );
}