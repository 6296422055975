import * as React from 'react';

import Alert from "@mui/material/Alert"
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import FormControl from '@mui/material/FormControl';
import { ValidatePicture, ValidateFormat } from '../utils/Validator.tsx';

export default function CharismaFileForm(props){
    
    const [file, setFile] = React.useState(null);
    const [error, setError] = React.useState(0);
    const [message, setMessage] = React.useState('');
    const [wait, setWait] = React.useState(false);
    
    function handleSubmit(){
        let lValidFile: boolean = true;

        if(props.type === 'img'){
            lValidFile = ValidatePicture(file);
            setMessage("Seules les images sont autorisées");
        }
        else if(props.type !== undefined){
            lValidFile = ValidateFormat(file, props.type);
            setMessage("Seules les fichiers au format" + props.type + " sont autoridées");
        }
        if(!lValidFile){
            setError(1);
        }
        else{
            setWait(true);
            props.handleSubmit(file)
        }
    }
    
    return(
        <Dialog
            open={true}
        >
            <DialogTitle>
                {props.title === undefined ?
                    'Photo de profil'
                    :
                    props.title
                }
            </DialogTitle>
            <DialogContent>
                <FormControl>
                    <input
                        type='file'
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                            setFile(event.target.files[0]);
                            setError(0);
                    }}
                    />
                </FormControl>               
                {error > 0 ?
                    <Alert severity="error">
                        {"Format de fichier invalide : " + message}
                    </Alert>                    
                    :
                    null
                }
                {wait ?
                    <Alert severity="info">{"Transfert du fichier en cours..."}</Alert>
                    :
                    null
                }
            </DialogContent>
            <DialogActions>
                <Button disabled={wait} onClick={()=>handleSubmit()}>Valider</Button>
                <Button disabled={wait} onClick={()=>props.handleCancel()}>Annuler</Button>
            </DialogActions>
        </Dialog>        
    );
}