import * as React from 'react';


import Box from '@mui/material/Box'


import { CharismaStudentTrainingApi } from '../api/studentTrainings/CharismaStudentTrainingApi.tsx'
import CharismaTutorStudentsListItem from './tutorStudentsListItem.tsx';
import Divider from '@mui/material/Divider';
import List from '@mui/material/List';


import Typography from '@mui/material/Typography';





export default function CharismaTutorStudentsList(props){
    
    
    
    const [trainingStudents, setTrainingStudents] = React.useState([]);
    

   // const lUsersProfile : number = props.profile === undefined ? -1 : props.profile;
   // const lTrash : number = props.trash === undefined ? 0 : 1;    

    React.useEffect(()=>{

        
        const lResponseStudentTraining = CharismaStudentTrainingApi.ListStudentsForTutor(props.tutorId);
        lResponseStudentTraining.then((value)=>{
            if(value.error === 0){
                setTrainingStudents(value.studentTrainings);                
            }
        }).catch((error)=>{
            
        })              
        
    }, [props.tutorId])


    
 
    
    return(
        
        <Box>
            <Typography variant="h5">{props.title}</Typography>
            <List sx={{ display:{xs:'flex', md:'block'}, overflow: 'auto', width: '100%', maxWidth: 360, bgcolor: 'background.paper'}}>
            {trainingStudents.map((student)=>(    
                <div>          
                <CharismaTutorStudentsListItem
                    id={student.id}
                    student={student.student}
                    training={student.training}
                    financal={student.financal}
                    course={student.course}
                    startDate={student.startDate}
                    endDate={student.endDate}
                    running={student.running}
                    reason={student.reason}
                    subscribe={student.subscribe}
                    selected={props.studentTrainingId === student.id}
                />    
                <Divider variant="inset" component="li" />
                </div>                    
                ))}
            </List>                                
        </Box>      
    );
    
    
}