import * as React from 'react';

import Card from '@mui/material/Card'
import CardActions from '@mui/material/CardActions'
import CardContent from '@mui/material/CardContent'
import CardHeader from '@mui/material/CardHeader';
import EditIcon from '@mui/icons-material/Edit';
import InfoIcon from '@mui/icons-material/Info';
import IconButton from '@mui/material/IconButton';
import {formatTime} from '../utils.tsx'
import { Typography } from '@mui/material';

export default function CharismaEvent(props){
    
    const lEvent = props.event;
    
    return(
        <Card sx={{mt:'10px'}}>
            <CardHeader
                subheader={lEvent.place + " / " + lEvent.teacher}
                title={formatTime(lEvent.startHour) + '-' + formatTime(lEvent.endHour)}
                action={
                    <IconButton onClick={()=>props.onEdit(lEvent.id)}>
                        {props.readWrite ?
                            <EditIcon/>
                            :
                            <InfoIcon/>
                        }
                    </IconButton>
                }
            />
            <CardContent>
                {lEvent.courses.map((lCourse)=>(
                    <Typography>{lCourse.name}</Typography>
                ))}
            </CardContent>
        </Card>        
    );
}