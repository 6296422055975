export function formatDate(pDate : string){
    if(pDate === undefined || pDate === null || pDate.length === 0){
        return("--");
    }
    const date = new Date(pDate);
    return date.toLocaleDateString('fr-fr');
}

export function extractMonth(pDate : string){
    const lMonthes = ["Janvier", "Février", "Mars", "Avril", "Mai", "Juin", "Juillet", "Aout", "Septembre", "Octobre", "Novembre", "Décembre"];
    const date = new Date(pDate);
    return lMonthes[date.getMonth()] + " " + date.getFullYear();
}

export function extractDay(pDate : string){
    const lDays = ["Dimanche", "Lundi", "Mardi", "Mercredi", "Jeudi", "Vendredi", "Samedi"];
    const date = new Date(pDate);
    return lDays[date.getDay()] + " " + formatDate(pDate);
}

export function formatTime(pTime : string){
    const lDate = new Date("01-01-2010 " + pTime)
    let lHour = lDate.getHours();
    if(lHour < 10){
        lHour = "0" + lHour;
    }
    let lMinutes = lDate.getMinutes();
    if(lMinutes < 10){
        lMinutes = "0" + lMinutes;
    }
   
    return lHour+ ":" + lMinutes;
}