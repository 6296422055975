import * as React from 'react';

import AppBar from '@mui/material/AppBar'
import Box from '@mui/material/Box'
import CharismaUserMenu from '../user/userMenu.tsx';
import HomeIcon from '@mui/icons-material/Home';
import { Link } from 'react-router-dom';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem'
import Toolbar from '@mui/material/Toolbar'
import Typography from '@mui/material/Typography';

import { CharismaUserContext } from '../context.tsx';

export default function CharismaTutorMainMenu(props){
        
    const [anchorAssessmentsMenu, setAnchorAssessmentsMenu] = React.useState(null);
    const openAssessmentsMenu: boolean = Boolean(anchorAssessmentsMenu)
    
    
    const lCurrentUser = React.useContext(CharismaUserContext);
    
    
    function handleCloseAssessmentsMenu(){
        setAnchorAssessmentsMenu(null);
    };
    


    return(
        <AppBar position="static" style={{marginBottom:'10px'}}>
            <Toolbar>
                <CharismaUserMenu
                    handleDisconnect={props.handleDisconnect}
                />
                <Typography>{lCurrentUser.firstName + ' ' + lCurrentUser.lastName}</Typography>
                 <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
                    <MenuItem
                        component={Link}
                        to={'/'}
                    >
                        <HomeIcon/>
                    </MenuItem>
                    <MenuItem
                        component={Link}
                        to={'/teachers'}
                    >
                        Formateurs FAE
                    </MenuItem>
                    <img src="/fae.png" width={172} alt="fae" />
                 </Box>                 
                 <img src="/qualiopi.png" width={195} alt="qualipi" />
                 <img src="/Charisma64.png" width={64} alt="charisma" />
            
            </Toolbar>
            <Menu
                open={openAssessmentsMenu}
                anchorEl={anchorAssessmentsMenu}
                onClose={handleCloseAssessmentsMenu}
                onClick={handleCloseAssessmentsMenu}
            >
                <MenuItem
                    component={Link}
                    to={'/daily-assessments'}
                >
                    Bilans Quotidien
                </MenuItem>
                <MenuItem
                    component={Link}
                    to={'/monthly-assessments'}
                >
                    Bilans Mensuels  
                </MenuItem>                                                           
            </Menu>       
        </AppBar>  
    );
}