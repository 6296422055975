import * as React from 'react';


import Box from '@mui/material/Box'


import AddIcon from '@mui/icons-material/Add'
import Alert from '@mui/material/Alert'
import AttachFileIcon from '@mui/icons-material/AttachFile';
import Button from '@mui/material/Button'
import Card from'@mui/material/Card'
import CardContent from'@mui/material/CardContent'
import CardHeader from'@mui/material/CardHeader'
import { CharismaStudentTrainingApi } from '../api/studentTrainings/CharismaStudentTrainingApi.tsx';
import CharismaAbsenceForm from './absenceForm.tsx';
import { CharismaUserContext } from '../context.tsx';
import Checkbox from '@mui/material/Checkbox';
import DeleteIcon from '@mui/icons-material/Delete'
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import EditIcon from '@mui/icons-material/Edit'
import {formatDate} from '../utils.tsx'
import Grid from '@mui/material/Grid'
import IconButton from '@mui/material/IconButton';
import { Link } from 'react-router-dom';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';




export default function CharismaAbsencesList(props){
    
    interface absence{
        id: number,
        subject: string,
        fromtDate: string,
        toDate: string,
        attach: string
    }    
   
    const [create, setCreate] = React.useState(false);
    const [absencesList, setAbsencesList] = React.useState([]);
    const [deleteAbsences, setDeleteAbsences] = React.useState(false);
    const [selectedAbsences, setSelectedAbsences] = React.useState([]);   
   
    const lCurrentUser = React.useContext(CharismaUserContext);
    const lReadWrite = lCurrentUser !== null && lCurrentUser !== undefined && (lCurrentUser.profile === 0 || lCurrentUser.profile === 3);
    const lStudentTrainingId = props.studentTrainingId;    
    const lStudentId = props.studentId;    


    React.useEffect(()=>{
        const lResponsePeriods = CharismaStudentTrainingApi.ListAbsences(lStudentTrainingId);
        lResponsePeriods.then((value)=>{
            if(value.error === 0){
                setAbsencesList(value.absences);
            }
        }).catch((error)=>{
            
        })
    }, [lStudentTrainingId])
    
    
          
    function handleCreateAbsence(pFromDate : string, pToDate : string, pSubject:string, pFile : File){
        const lResponse = CharismaStudentTrainingApi.CreateAbsence(pSubject, pFromDate, pToDate, lStudentId, lStudentTrainingId, pFile);
        lResponse.then((value)=>{
            if(value.error === 0){
                if(value.absence !== null){
                    let lAbsences = absencesList.concat(value.absence);
                    setAbsencesList(lAbsences);
                    setCreate(false);
                }
                
            }
            else{
                
            }
        }).catch((error)=>{
                console.log("catch error")
        })
    } 
    function handleCancelCreate(){
        setCreate(false);
    }
                    
    function handleDeleteAbsences(){
        console.log(selectedAbsences)
        const lResponse = CharismaStudentTrainingApi.DeleteAbsences(selectedAbsences);
        lResponse.then((value)=>{
            if(value.error === 0){
                if(value.ids !== null){
                    const lIds = value.ids;
                    let lAbsences = absencesList.filter((lAbsence)=>lIds.indexOf(lAbsence.id) === -1);
                    setAbsencesList(lAbsences);
                    setDeleteAbsences(false);                  
                }
;            }
            else{
            
            }
        }).catch((error)=>{
            console.log("catch error")
        })           
    }            
    
    function handleSelectedAbsence(pEvent : React.ChangeEvent<HTMLInputElement>, pId : number){
        let lNewSelected : number[] = [];
        let lIndex = selectedAbsences.indexOf(pId);
        if(pEvent.target.checked && lIndex === -1){
            lNewSelected = lNewSelected.concat(selectedAbsences, pId);
        }
        else if(lIndex !== -1){
            if(lIndex === 0){
                lNewSelected = lNewSelected.concat(selectedAbsences.slice(1));
            }
            else if(lIndex === selectedAbsences.length - 1){
                lNewSelected = lNewSelected.concat(selectedAbsences.slice(0, -1));
            }
            else{
                lNewSelected = lNewSelected.concat(selectedAbsences.slice(0, lIndex), selectedAbsences.slice(lIndex + 1),);
            }
        }
        setSelectedAbsences(lNewSelected);
    }
            
    
    function handleCloseEdit(){
        setCurrentAbsence(null);
    }
    
    function handleEdit(pId : number){
          for(let lIndex = 0; lIndex < absencesList.length; lIndex++){
              const lAbsence = absencesList[lIndex];
              if(lAbsence.id == pId){
                  setCurrentAbsence(lAbsence);
                  break;
              }
          } 
    }
    
    
    function createAbsencesTable(pAbsences : absence[], pSelected : number[]){
        return(
            pAbsences.map((absence)=>(
                <TableRow key={absence.id}>
                    {lReadWrite ?
                        <TableCell padding="checkbox">
                            <Checkbox
                                checked={pSelected.indexOf(absence.id) >= 0}
                                onClick={                                    
                                    (event)=>handleSelectedAbsence(event, absence.id)
                                    }
                            />
                        </TableCell>
                        :
                        null
                    }
                    <TableCell>
                        {formatDate(absence.fromDate)}
                    </TableCell>
                    <TableCell>
                        {formatDate(absence.toDate)}
                    </TableCell>
                    <TableCell>
                        <Link to={absence.link} target="_blank" rel="noopener noreferrer" >
                            <AttachFileIcon/>
                        </Link>
                    </TableCell>
                    {lReadWrite  ?
                        <TableCell padding="checkbox">
                            <IconButton
                                onClick={()=>handleEdit(absence.id)}
                            >
                                <EditIcon/>
                            </IconButton>
                        </TableCell>
                        :
                        null
                    }
                </TableRow>
                 
            ))
        )
    }
    
    
    return(
        
        <Box>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={12}>
                    <Card>
                        <CardHeader
                            title="Absences"
                            action={
                                <div>
                                {lReadWrite?
                                <div>
                                <IconButton onClick={()=>setCreate(true)}>
                                    <AddIcon/>
                                </IconButton>
                                {selectedAbsences.length > 0?
                                    <IconButton onClick={()=>setDeleteAbsences(true)}>
                                        <DeleteIcon/>
                                    </IconButton>  
                                    :
                                    null
                                } 
                                </div>
                                :
                                null
                                }                                
                                </div>
                            }
                        />
                        <CardContent>
                            <Table>                               
                                <TableHead>
                                    <TableRow>
                                        {lReadWrite ?
                                            <TableCell padding="checkbox">
                                                </TableCell>
                                              :
                                              null
                                        }
                                        <TableCell>DU</TableCell>
                                        <TableCell>Au</TableCell>
                                        <TableCell>Justificatif</TableCell>
                                        <TableCell padding="checkbox">
                                        </TableCell>                                        
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                {
                                    createAbsencesTable(absencesList, selectedAbsences)
                                }
                                </TableBody>
                            </Table>                            
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
            {create ?
                <CharismaAbsenceForm
                    handleSubmit={handleCreateAbsence}
                    handleCancel={handleCancelCreate}

                />
                :
                null    
            }    
            
            <Dialog
                open={deleteAbsences}
            >
                <DialogTitle>Suppression des absences</DialogTitle>
                <DialogContent>
                    <Typography>Etes-vous sûr de vouloir supprimer définitivement les absences sélectionnés?</Typography>
                    {
                        false?
                            <Alert severity='info'>Suppression en cours...</Alert>
                        :
                        null
                    }
                </DialogContent>
                <DialogActions>
                    <Button autoFocus onClick={()=>setDeleteAbsences(false)}>
                        Annuler
                    </Button>
                    <Button onClick={handleDeleteAbsences}>
                        Valider
                    </Button>
                </DialogActions>
            </Dialog>                     
        </Box>      
    );
    
    
}