import * as React from 'react';


import Box from '@mui/material/Box'


import AddIcon from '@mui/icons-material/Add'
import { CharismaUserApi } from '../api/users/CharismaUserApi.tsx';
import { CharismaTrainingApi } from '../api/trainings/CharismaTrainingApi.tsx';
import { CharismaFinancalApi } from '../api/financals/CharismaFinancalApi.tsx';
import { CharismaStudentTrainingApi } from '../api/studentTrainings/CharismaStudentTrainingApi.tsx'
import { CharismaUserContext } from '../context.tsx';
import CharismaStudentTrainingForm  from './studentTrainingForm.tsx';
import CharismaStudentsListItem from './studentsListItem.tsx';
import CharismaStudentTrainingSpace from './studentTrainingSpace.tsx';
import CharismaCreateEvaluation from './createEvaluation.tsx'
import CharismaImportFileForm from './importFileForm.tsx'
import CharismaMessage from '../components/message.tsx';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import Grid from '@mui/material/Grid'
import IconButton from '@mui/material/IconButton';
import InputBase from '@mui/material/InputBase'
import List from '@mui/material/List';

import SearchIcon from '@mui/icons-material/Search';
import { styled, alpha } from '@mui/material/styles';

import Toolbar from '@mui/material/Toolbar'
import Typography from '@mui/material/Typography';


const Search = styled('div')(({ theme }) => ({
  position: 'relative',
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  '&:hover': {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginLeft: 0,
  width: '100%',
  [theme.breakpoints.up('sm')]: {
    marginLeft: theme.spacing(1),
    width: 'auto',
  },
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: '100%',
  position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: 'inherit',
  width: '100%',
  '& .MuiInputBase-input': {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create('width'),
    [theme.breakpoints.up('sm')]: {
      width: '12ch',
      '&:focus': {
        width: '20ch',
      },
    },
  },
}));



export default function CharismaStudentsList(props){
    
    
    const [create, setCreate] = React.useState(false);
    const [error, setError] = React.useState(0);
    const [students, setStudents] = React.useState([]);
    const [financals, setFinancals] = React.useState([]);
    const [trainingStudents, setTrainingStudents] = React.useState([]);
    const [displayedStudents, setDisplayedStudents] = React.useState([]);
    const [tutors, setTutors] = React.useState([]);
    const [courses, setCourses] = React.useState([]);
    const [search, setSearch] = React.useState('');
    const [studentId, setStudentId] = React.useState(0);
    const [studentTrainingId, setStudentTrainingId] = React.useState(0);
    const [importFile, setImportFile] = React.useState(false);
    const [assessmentsGenerated, setAssessmentsGenerated] = React.useState(false);
    const [evaluationsGenerated, setEvaluationsGenerated] = React.useState(false);


    //const lUsersProfile : number = props.profile === undefined ? -1 : props.profile;
    const lCurrentUser = React.useContext(CharismaUserContext);
    const lReadWrite : boolean = lCurrentUser !== null && lCurrentUser !== undefined && lCurrentUser.profile === 0;
    const lTrash : number = props.trash === undefined ? 0 : 1;    
    const lTrainingType = props.trainingType;

    React.useEffect(()=>{
        const lResponseTutor = CharismaUserApi.List(2, 0);
        lResponseTutor.then((value)=>{
            if(value.error === 0){
                setTutors(value.users);
            }
        }).catch((error)=>{
            
        })

        const lResponseStudent = CharismaUserApi.List(3, 0);
        lResponseStudent.then((value)=>{
            if(value.error === 0){
                setStudents(value.users);
            }
        }).catch((error)=>{
            
        })
        
        if(lReadWrite){
            const lResponseFinancals = CharismaFinancalApi.ListFinancals();
            lResponseFinancals.then((value)=>{
                if(value.error === 0){
                    setFinancals(value.financals);
                }
            }).catch((error)=>{
                
            })
                    
            const lResponseCourses = CharismaTrainingApi.ListCourses(props.trainingId, 0);
            lResponseCourses.then((value)=>{
                if(value.error === 0){
                    setCourses(value.courses);
                }
            }).catch((error)=>{
                
            }) 
        }
        const lResponseStudentTraining = CharismaStudentTrainingApi.ListStudents(props.trainingId);
        lResponseStudentTraining.then((value)=>{
            if(value.error === 0){
                setTrainingStudents(value.studentTrainings);
                setDisplayedStudents(value.studentTrainings);
                if(value.studentTrainings.length > 0){
                    setStudentTrainingId(value.studentTrainings[0].id);
                    setStudentId(value.studentTrainings[0].student.id);
                }
            }
        }).catch((error)=>{
            
        })    
              
        
    }, [props.trainingId, lReadWrite, lCurrentUser])

    React.useEffect(()=>{
        if(search.length > 0){
            let lStudents = trainingStudents.filter((student)=>(
                student.student.firstName.toLowerCase().includes(search.toLowerCase()) || student.student.lastName.toLowerCase().includes(search.toLowerCase()))
             );
             setDisplayedStudents(lStudents);
        }   
        else{
            setDisplayedStudents(trainingStudents);
        } 
        
    }, [search, trainingStudents])    
    
    function handleCancel(){
        setCreate(false);
    }
      
    function handleSubmit(pStudentId : number, pTutorId: number, pCourseId: number, pFinancalId : number,  pStartDate : string, pEndDate : string){
        const lResponse = CharismaStudentTrainingApi.CreateStudentTraining(pStudentId, pCourseId, pTutorId, props.trainingId, pFinancalId, pStartDate, pEndDate);
        lResponse.then((value)=>{
            if(value.error === 0){
                let lTrainingStudents = trainingStudents.concat(value.studentTraining);
                setTrainingStudents(lTrainingStudents);
                setCreate(false);
            }
        }).catch((error)=>{
            
        })
    }
    
    function handleSelectStudent(pStudentId : number, pStudentTrainingId : number){
        setStudentId(pStudentId);
        setStudentTrainingId(pStudentTrainingId);
    }
    
    function handleCreateEvaluation(pType:number, pEvaluationType:number, pEvaluationProfile: number, pTemplateId : number, pDate:string, pCourses:number[]){
        console.log(pCourses)
        if(pType === 1){
            const lResponse = CharismaTrainingApi.GenerateEvaluation(pTemplateId, pEvaluationType, pDate, props.trainingId, pEvaluationProfile, pCourses)
            lResponse.then((value)=>{
                if(value.error === 0){
                    setEvaluationsGenerated(true);
                }
                else{
                    
                }
            }).catch((error)=>{
                    console.log("catch error")
            })  
        }
        else if(pType === 0){
            const lResponse = CharismaTrainingApi.GenerateAssessment(pDate, props.trainingId, pCourses, pEvaluationType)
            lResponse.then((value)=>{
                if(value.error === 0){
                    setAssessmentsGenerated(true);
                }
                else{
                    
                }
            }).catch((error)=>{
                    console.log("catch error")
            })              
        }
    }
    
    function handleUpdate(pStudentTraining){

        let lTrainingStudents = trainingStudents.slice();
        for (let lIndex = 0; lIndex < lTrainingStudents.length; lIndex++){
            const lId = lTrainingStudents[lIndex].id;
            if(lId === pStudentTraining.id){
                lTrainingStudents[lIndex] = pStudentTraining;
                break;
            }
        }
        setTrainingStudents(lTrainingStudents);
        
        let lDisplayedTrainingStudents = displayedStudents.slice();
        for (let lIndex = 0; lIndex < lTrainingStudents.length; lIndex++){
            const lId = lDisplayedTrainingStudents[lIndex].id;
            if(lId === pStudentTraining.id){
                lDisplayedTrainingStudents[lIndex] = pStudentTraining;
                break;
            }
        }
        setDisplayedStudents(lDisplayedTrainingStudents);
        
    }
    
    function handleRemove(pId : number){
        let lTrainingStudents = trainingStudents.filter((training)=>training.id !== pId);
        
        setTrainingStudents(lTrainingStudents);
        
        let lDisplayedTrainingStudents = displayedStudents.filter((training)=>training.id !== pId);
        
        setDisplayedStudents(lDisplayedTrainingStudents);
        
        if(lDisplayedTrainingStudents.length > 0){
            setStudentTrainingId(lDisplayedTrainingStudents[0].id);
            setStudentId(lDisplayedTrainingStudents[0].student.id);
        }
    }
    
    function handleCancelImport(){
        setImportFile(false);
    }
    
    function handleImport(pFile : File){
        const lResponse = CharismaStudentTrainingApi.ImportStudents(pFile, props.trainingId);
        lResponse.then((value)=>{
            if(value.error === 0){
                setImportFile(false);
            }
            else{
                
            }
        }).catch((error)=>{
                console.log("catch error")
        })
    }
    
    
    return(
        
        <Box>
            <Typography variant="h5">{props.title}</Typography>
            {!lTrash?
                <Toolbar>
                    <IconButton onClick={()=>setImportFile(true)}>
                        <FileUploadIcon/>
                    </IconButton>                    
                    <IconButton onClick={()=>setCreate(true)}>
                        <AddIcon/>
                    </IconButton>
                    <Search>
                        <SearchIconWrapper>
                            <SearchIcon />
                        </SearchIconWrapper>
                        <StyledInputBase
                            placeholder="Rechercher…"
                            inputProps={{ 'aria-label': 'search' }}
                            onChange={(event)=>setSearch(event.target.value)}
                            value={search}
                        />
                    </Search>
                    <CharismaCreateEvaluation
                        trainingType = {lTrainingType}
                        courses={courses}
                        handleSubmit={handleCreateEvaluation}
                    />
                    
                </Toolbar>
                :
                null               
            }
            <Grid container spacing={4}>
                <Grid item xs={12} sm={2}>
                    <List sx={{display:{xs:'flex', md:'block'}, maxHeight:'700px', overflow: 'auto', bgcolor: 'background.paper'}}>
                    {displayedStudents.map((student)=>(    
                        <div>          
                        <CharismaStudentsListItem
                            id={student.id}
                            student={student.student}
                            tutor={student.tutor}
                            financal={student.financal}
                            course={student.course}
                            startDate={student.startDate}
                            endDate={student.endDate}
                            reason={student.reason}
                            subscribe={student.subscribe}
                            trash={props.trash}
                            handleSelect={handleSelectStudent}
                            selected={studentTrainingId === student.id}
                        />    
                        </div>                    
                        ))}
                    </List>      
                             
                </Grid>
                <Grid item xs={12} sm={10}>
                    {
                        studentId > 0 && studentTrainingId > 0 ?
                        <CharismaStudentTrainingSpace
                            studentId = {studentId}
                            studentTrainingId ={studentTrainingId}
                            courses={courses}
                            tutors={tutors}
                            financals={financals}
                            update={handleUpdate}
                            remove={handleRemove}
                        />
                        :
                        null
                    }
                </Grid>
            </Grid>
            {create ?
                <CharismaStudentTrainingForm
                    title={"Ajout d'un stagiaire"}
                    courses={courses}
                    tutors={tutors}
                    students={students}
                    financals={financals}
                    handleSubmit={handleSubmit}
                    handleCancel={handleCancel}                    
                />
                :
                null    
            }   
            {
                error === 2 ?
                    <CharismaMessage
                        level='error'
                        message="Ce stagiaire est déjà lié à cette formation"
                    />
                    :
                    null
            }    
            {importFile ?
                <CharismaImportFileForm
                    title="Import"
                    handleSubmit={handleImport}
                    handleCancel={handleCancelImport}                    
                />
                :
                null
            }       
            {
                evaluationsGenerated ?                   
                <CharismaMessage
                    message="Evaluations générées"
                    handleClose={setEvaluationsGenerated}
                />
                :
                null
            }  
            {
                assessmentsGenerated ?                   
                <CharismaMessage
                    message="Fiches navettes générées"
                    handleClose={setAssessmentsGenerated}
                />
                :
                null
            }                              
        </Box>      
    );
    
    
}