import * as React from 'react';


import Box from '@mui/material/Box'

import AddIcon from "@mui/icons-material/Add"
import EditIcon from '@mui/icons-material/Edit'

import Card from'@mui/material/Card'
import CardContent from'@mui/material/CardContent'
import CardHeader from'@mui/material/CardHeader'
import { CharismaEvaluationTemplateApi } from '../api/evaluations/CharismaEvaluationTemplateApi.tsx';
import CharismaEvaluationTemplateForm from './evaluationTemplateForm.tsx';
import CharismaEvaluationTemplateItemForm from './evaluationTemplateItemForm.tsx';
import CharismaEvaluationTemplateSectionForm from './evaluationTemplateSectionForm.tsx';
import CharismaEvaluationTemplateSection from './evaluationTemplateSection.tsx';
import CharismaEvaluationTemplateQcmQuestion from './evaluationTemplateQcmQuestion.tsx';
import CharismaEvaluationTemplateItems from './evaluationTemplateItems.tsx';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem'





export default function CharismaEvaluationTemplate(props){
    
    const [title, setTitle] = React.useState("");
    const [items, setItems] = React.useState([]);
    const [sections, setSections] = React.useState([]);
    const [createItem, setCreateItem] = React.useState(false);
    const [newSection, setNewSection] = React.useState(false);
   
    const [modify, setModify] = React.useState(false);
    
    const [anchorMenu, setAnchorMenu] = React.useState(null);
    const openMenu: boolean = Boolean(anchorMenu);
    
    const lEvaluationTemplateId = props.evaluationTemplateId
    const lTemplateType = props.templateType;
    
    React.useEffect(()=>{
        const lResponse = CharismaEvaluationTemplateApi.GetEvaluationTemplate(lEvaluationTemplateId);
        lResponse.then((value)=>{
            if(value.error === 0){
                if(value.evaluationTemplate !== null){                   
                    setTitle(value.evaluationTemplate["title"]);
                    setItems(value.evaluationTemplate["items"]);
                    setSections(value.evaluationTemplate["sections"]);
                }
                
            }
        }).catch((error)=>{            
        })

    }, [lEvaluationTemplateId])
    
    
    function handleCancel(){
        setModify(false);
    }
               
    function handleUpdate(pTitle : string, pId : number){
        const lResponse = CharismaEvaluationTemplateApi.UpdateEvaluationTemplate(pTitle, pId);
        lResponse.then((value)=>{
            if(value.error === 0){ 
                if(value.evaluationTemplate !== null){
                    setTitle(value.evaluationTemplate.title)
                    setModify(false);
                    props.handleUpdate(pTitle, pId);                 
                }
            }
            else{
                
            }
        }).catch((error)=>{
                console.log("catch error")
        })
    }
    
    function handleDeleteSection(pId : number){
        const lResponse = CharismaEvaluationTemplateApi.DeleteItems([pId]);
        lResponse.then((value)=>{
            if(value.error === 0){ 
                if(value.ids !== null){  
                    let lSections = sections.filter((section)=>(value.ids.indexOf(section.id) === -1));
                    setSections(lSections);
                }
            }
            else{                
            }
        }).catch((error)=>{
            console.log("catch error");
        })        
    }
    
    function handleAddSection(pItem : string, pComment : string){
        const lResponse = CharismaEvaluationTemplateApi.AddItem(lEvaluationTemplateId, pItem, pComment, 0, 0, 0);
        lResponse.then((value)=>{
            if(value.error === 0){ 
                if(value.sections !== null){  
                    setSections(value.sections);
                    setNewSection(false);                        
                }
            }
            else{                
            }
        }).catch((error)=>{
            console.log("catch error");
        })
    }
    
    function handleCreateQuestion(pTitle : string, pComment: string){
        const lResponse = CharismaEvaluationTemplateApi.AddItem(lEvaluationTemplateId, pTitle, pComment, 3, 0, 0)
        lResponse.then((value)=>{
            if(value.error === 0){ 
                if(value.items !== null){
                    setItems(value.items);
                    setCreateItem(false);             
                }
            }
            else{
                
            }
        }).catch((error)=>{
                console.log("catch error")
        })
    }
    
    function handleDeleteQuestion(pQuestionId : number){
        const lResponse = CharismaEvaluationTemplateApi.DeleteItems([pQuestionId])
        lResponse.then((value)=>{
            if(value.error === 0){ 
                if(value.ids !== null){
                    let lItems = items.filter((item)=>(value.ids.indexOf(item.id) === -1));
                    setItems(lItems);             
                }
            }
            else{
                
            }
        }).catch((error)=>{
                console.log("catch error")
        })        
    }
    
    function handleAddItem(pItem : string, pComment : string, pType: number, pParentId : number, pMax : number){
        console.log(pParentId);
        const lResponse = CharismaEvaluationTemplateApi.AddItem(lEvaluationTemplateId, pItem, pComment, pType, pParentId, pMax);
        lResponse.then((value)=>{
            if(value.error === 0){ 
                if(value.items !== null){  
                    setItems(value.items);                    
                    setCreateItem(false);                    
                }
            }
            else{                
            }
        }).catch((error)=>{
            console.log("catch error");
        })
    }
    
    function handleCancelCreateItem(){
        setCreateItem(false);
    }
    
    function handleCancelNewSection(){
        setNewSection(false);
    }
        
    function handleOpenMenu(event: React.MouseEvent<HTMLElement>){
        setAnchorMenu(event.currentTarget);
    };
    
    function handleCloseMenu(){
        setAnchorMenu(null);
    };        
    return(
        
        <Box>
            <Card>
                <CardHeader
                    title={title}
                    action={
                        <div>
                        <IconButton onClick={()=>setModify(true)}>
                            <EditIcon/>
                        </IconButton>
                        <IconButton onClick={(event)=>handleOpenMenu(event)}>
                            <AddIcon/>
                        </IconButton>
                        </div>
                    } 
                />
                <CardContent>
                    {sections.map((section)=>(
                        <CharismaEvaluationTemplateSection
                            sectionId={section.id}
                            evaluationTemplateId={lEvaluationTemplateId}
                            templateType={props.templateType}
                            handleDeleteSection={handleDeleteSection}
                        />
                    ))}
                    {
                        lTemplateType === 0 ?
                        <CharismaEvaluationTemplateItems
                            sectionId = {0}
                            evaluationTemplateId={lEvaluationTemplateId}
                            templateType={props.templateType}
                            items={items}
                        />
                        :
                        lTemplateType === 1 || lTemplateType === 2?
                        <div>
                        {items.map((item)=>(
                            <CharismaEvaluationTemplateQcmQuestion
                                sectionId={0}
                                evaluationTemplateId={lEvaluationTemplateId}
                                templateType={props.templateType}
                                question={item}
                                handleDeleteQuestion={handleDeleteQuestion}
                            />
                            ))
                        }
                        </div>
                        :
                        null                        
                    }
                </CardContent>
            </Card>  
            {modify ?
                <CharismaEvaluationTemplateForm
                    title={"Modification"}
                    id={lEvaluationTemplateId}
                    name={title}
                    handleSubmit={handleUpdate}
                    handleCancel={handleCancel}
                />
                :
                null
            }
            {newSection ?
                <CharismaEvaluationTemplateSectionForm
                    title="Ajout d'une section"
                    parentId={0}
                    handleSubmit={handleAddSection}
                    handleCancel={handleCancelNewSection}
                />
                :
                null
            }
            {createItem && lTemplateType === 0?
                <CharismaEvaluationTemplateItemForm
                    title={"Ajout d'un item"}                    
                    handleSubmit={handleAddItem}
                    templateType={props.templateType}                  
                    parentId={0}
                    handleCancel={handleCancelCreateItem}
                />
                :
            createItem && (lTemplateType === 1 || lTemplateType ===2)?
                <CharismaEvaluationTemplateSectionForm
                    title={"Ajout d'une question"}                    
                    handleSubmit={handleCreateQuestion}
                    templateType={props.templateType}                  
                    parentId={0}
                    handleCancel={handleCancelCreateItem}
                />
                :
                null
            }
           
            <Menu
                open={openMenu}
                anchorEl={anchorMenu}
                onClose={handleCloseMenu}
                onClick={handleCloseMenu}
            >
                <MenuItem
                    onClick={()=>setNewSection(true)}
                >
                    Ajouter une section
                </MenuItem>
                <MenuItem
                    onClick={()=>setCreateItem(true)}
                >
                    {lTemplateType === 0 ?
                        "Ajouter un item"
                        :
                     lTemplateType === 1 || lTemplateType === 2 ?
                        "Ajouter une question"
                    :
                    null}
                </MenuItem>
            </Menu>                       
        </Box>      
    );
    
    
}