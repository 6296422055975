import * as React from 'react';

import Alert from '@mui/material/Alert'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import FormControl from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select'
import TextField from '@mui/material/TextField';

import { ValidateDate, ValidateDateRange, ValidateString } from '../utils/Validator.tsx';

export default function CharismaTrainingForm(props){
    
    const [name, setName] = React.useState(props.name);
    const [nError, setNError] = React.useState(0);
    const [startDate, setStartDate] = React.useState(props.startDate);
    const [sError, setSError] = React.useState(0);
    const [dateError, setDateError] = React.useState(0);
    const [endDate, setEndDate] = React.useState(props.endDate); 
    const [eError, setEError] = React.useState(0);
    const [type, setType] = React.useState(props.type);
    const [wait, setWait] = React.useState(false);
    const [responsible, setResponsible] = React.useState(props.responsible !== undefined ? 
                                                            props.responsible
                                                            :
                                                            props.responsibles.length > 0 ?
                                                            props.responsibles[0].id
                                                            :
                                                            null
                                                        );
    
    function handleSubmit(){
        const lValidName: boolean = ValidateString(name, 32);
        if(!lValidName){
            setNError(1);
        }
        
        let lValidMin: number = ValidateDate(startDate);
        setSError(lValidMin);
        
        let lValidMax : number = ValidateDate(endDate);
        setEError(lValidMax);
        
        if(lValidMax === 0 && lValidMin === 0){
            const lValidRange : number = ValidateDateRange(startDate, endDate);
            if(lValidRange !== 0){
                setDateError(1);   
            }
            else if(lValidName && props.id === undefined){
                setWait(true);
                props.handleSubmit(name, type, startDate, endDate, responsible);
            }
            else if(lValidName){
                setWait(true);
                props.handleSubmit(name, type, startDate, endDate, responsible, props.id);
            }
        }
    }
    
    return(
        <Dialog
            open={true}
        >
            <DialogTitle>
                {props.title === undefined ?
                    'Modification de la formation'
                    :
                    props.title
                }
            </DialogTitle>
            <DialogContent>
                <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="name"
                    label="Nom"
                    name="fName"
                    autoFocus
                    error={nError > 0}
                    helperText={nError > 0 ? 'Nom invalide' : null}
                    value={name}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                        setName(event.target.value);
                        setNError(0);
                    }}
                />
                <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="startDate"
                    label="Début"
                    name="startDate"
                    autoFocus
                    type={'date'}
                    InputLabelProps={{ shrink: true, required: true }}
                    error={sError > 0}
                    helperText={sError > 0 ? 'Date invalide' : null}
                    value={startDate}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                        setStartDate(event.target.value);
                    }}
                />
                <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="endDate"
                    label="Fin"
                    name="endDate"
                    autoFocus
                    type={'date'}
                    InputLabelProps={{ shrink: true, required: true }}
                    error={eError > 0}
                    helperText={eError > 0 ? 'Date invalide' : null}                   
                    value={endDate}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                        setEndDate(event.target.value);
                    }}
                />
                {dateError === 1 ?
                    <Alert severity="error">{'La date de début est supérieure à la date de fin'}</Alert>
                    :
                    null
                }
                <FormControl fullWidth sx={{mt:'20px'}}>
                <InputLabel id="label">Responsable Pédagogique</InputLabel>
                    <Select
                        labelId="label"
                        id="type-select"                        
                        value={responsible}
                        label="Responsable Pédagogique"
                        onChange={(event)=>setResponsible(event.target.value)}
                    >
                    {
                        props.responsibles.map((lResponsible)=>(
                            <MenuItem value={lResponsible.id}>{lResponsible.firstName + " " + lResponsible.lastName}</MenuItem>        
                        ))
                    }

                  </Select>
                </FormControl>  
                <FormControl fullWidth sx={{mt:'20px'}}>
                <InputLabel id="label">Type de Formation</InputLabel>
                    <Select
                        labelId="label"
                        id="type-select"                        
                        value={type}
                        label="Type de Formation"
                        onChange={(event)=>setType(event.target.value)}
                    >
                    <MenuItem value={0}>BPJEPS</MenuItem>
                    <MenuItem value={1}>CQP</MenuItem>
                    <MenuItem value={2}>AE</MenuItem>
                  </Select>
                </FormControl>     
                {wait ?
                    <Alert severity="info">{props.id === undefined ?"Ajout de la formation en cours":"Modification de la formations en cours"}</Alert>
                    :
                    null
                }           
            </DialogContent>
            <DialogActions>
                <Button disabled={wait} onClick={()=>handleSubmit()}>Valider</Button>
                <Button disabled={wait} onClick={()=>props.handleCancel()}>Annuler</Button>
            </DialogActions>
        </Dialog>        
    );
}