import * as React from 'react';

import Alert from '@mui/material/Alert'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import TextField from '@mui/material/TextField';

import { ValidateEmail, ValidateString } from '../utils/Validator.tsx';

export default function CharismaUserForm(props){
    
    const [firstName, setFirstName] = React.useState(props.firstName);
    const [fError, setFError] = React.useState(0);
    const [lastName, setLastName] = React.useState(props.lastName);
    const [lError, setLError] = React.useState(0);
    const [mail, setMail] = React.useState(props.mail);
    const [mError, setMError] = React.useState(0);    
    const [phone, setPhone] = React.useState(props.phone);
    const [wait, setWait] = React.useState(false);
    
    function handleSubmit(){
        const lValidFname: boolean = ValidateString(firstName, 100);
        const lValidLname: boolean = ValidateString(lastName, 100);
        const lValidMail: boolean = ValidateEmail(mail);
        if(!lValidFname){
            setFError(1);
        }
        if(!lValidLname){
            setLError(1);
        }
        if(!lValidMail){
            setMError(1);
        }
        if(lValidFname && lValidLname && lValidMail){
            setWait(true);
            props.handleSubmit(firstName, lastName, mail, phone)
        }
    }
    
    return(
        <Dialog
            open={true}
        >
            <DialogTitle>
                {props.title === undefined ?
                    'Modification des coordonnées'
                    :
                    props.title
                }
            </DialogTitle>
            <DialogContent>
                <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="fName"
                    label="Prénom"
                    name="fName"
                    autoFocus
                    error={fError > 0}
                    helperText={fError > 0 ? 'Prénom invalide' : null}
                    value={firstName}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                        setFirstName(event.target.value);
                        setFError(0);
                    }}
                />
                <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="lName"
                    label="Nom"
                    name="lName"
                    autoFocus
                    value={lastName}
                    error={lError > 0}
                    helperText={lError > 0 ? 'Nom invalide' : null}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                        setLastName(event.target.value);
                        setLError(0);
                    }}
                />
                <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="mail"
                    label="Mail"
                    name="mail"
                    autoFocus
                    value={mail}
                    error={mError > 0}
                    helperText={mError > 0 ? 'Mail invalide' : null}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                        setMail(event.target.value);
                        setMError(0)
                    }}
                />
                <TextField
                    margin="normal"
                    fullWidth
                    id="phone"
                    label="Téléphone"
                    name="phone"
                    autoFocus
                    value={phone}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                        setPhone(event.target.value);
                    }}
                />
                {
                    wait ?
                    <Alert severity="info">{"Créaton en cours"}</Alert>
                    :
                    null
                }
            </DialogContent>
            <DialogActions>
                <Button disabled={wait} onClick={()=>handleSubmit()}>Valider</Button>
                <Button disabled={wait} onClick={()=>props.handleCancel()}>Annuler</Button>
            </DialogActions>
        </Dialog>        
    );
}