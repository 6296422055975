
import { CharismaApi } from "../CharismaApi.js";

export const CharismaEvaluationApi = {
    CreateEvaluation: async function(pTemplateId : number,  pType : number, pDate : string, 
                                    pStudentTrainingId : number, pStudentId : number, pProfile : number){
        const lDatas = new FormData();
        lDatas.append('type', pType);
        lDatas.append('date', pDate);
        lDatas.append('studentTrainingId', pStudentTrainingId);
        lDatas.append('studentId', pStudentId);
        lDatas.append('templateId', pTemplateId);
        lDatas.append('profile', pProfile);
        const lResponse = await CharismaApi.request({
            url:`/evaluations/createEvaluation.php`,
            method:"POST",
            data:lDatas
        })
        return lResponse.data;
    },  
    ListEvaluations: async function(pType : number, pStudentTrainingId : number){

        const lResponse = await CharismaApi.request({
            url:`/evaluations/listEvaluations.php`,
            method:"GET",
            params:{
                "type":pType,
                "studentTrainingId":pStudentTrainingId
            }
        })
        return lResponse.data;
    },    
    
    UpdateEvaluationDate: async function(pDate : string, pEvaluationId: number){
        const lDatas = new FormData();
        lDatas.append('date', pDate);
        lDatas.append('id', pEvaluationId);
        const lResponse = await CharismaApi.request({
            url:`/evaluations/updateEvaluationDate.php`,
            method:"POST",
            data:lDatas
        })
        return lResponse.data;
    },
    
    
    DeleteEvaluations: async function(pIds : number[]){
        const lDatas = new FormData();
        pIds.forEach((lId)=>{
            lDatas.append("ids[]", lId);    
        });
        const lResponse = await CharismaApi.request({
            url:`/evaluations/deleteEvaluations.php`,
            method:"POST",
            data:lDatas
        })
        return lResponse.data;
    },
    GetEvaluation: async function(pId : number){
   
        const lResponse = await CharismaApi.request({
            url:`/evaluations/getEvaluation.php`,
            method:"GET",
            params:{
                "id" : pId
            }
        })
        return lResponse.data;
    },
    UpdateEvaluationNotes: async function(pItems, pComment:string, pPartId:number, pAssessmentId : number){
        const lDatas = new FormData();
        pItems.forEach((lItem)=>{
            lDatas.append("notes[]", lItem.id + "," + lItem.value);
            lDatas.append("comments[]", lItem.id + "," + lItem.comment); 
        });
        
       
        const lResponse = await CharismaApi.request({
            url:`/evaluations/updateEvaluationItem.php`,
            method:"POST",
            data:lDatas
        })
        return lResponse.data;
    },
    UpdateEvaluationSingleItem: async function(pItemId : number, pNote : number){
        const lDatas = new FormData();
        
        lDatas.append("itemId", pItemId);
        lDatas.append("note", pNote); 
        
       
        const lResponse = await CharismaApi.request({
            url:`/evaluations/updateEvaluationSingleItem.php`,
            method:"POST",
            data:lDatas
        })
        return lResponse.data;
    },  
    UpdateEvaluationSingleCommentItem: async function(pItemId : number, pComment : string, pField : string){
        const lDatas = new FormData();
        
        lDatas.append("itemId", pItemId);
        lDatas.append("comment", pComment);
        lDatas.append("field", pField); 
        
       
        const lResponse = await CharismaApi.request({
            url:`/evaluations/updateEvaluationSingleCommentItem.php`,
            method:"POST",
            data:lDatas
        })
        return lResponse.data;
    },  
    ValidEvaluation: async function(pEvaluationId : number, pGlobalNote : number, pGlobalComment: string){
        const lDatas = new FormData();
        
        lDatas.append("evaluationId", pEvaluationId);
        lDatas.append("globalNote", pGlobalNote);
        lDatas.append("globalComment", pGlobalComment); 
        
       
        const lResponse = await CharismaApi.request({
            url:`/evaluations/validEvaluation.php`,
            method:"POST",
            data:lDatas
        })
        return lResponse.data;
    },    
}