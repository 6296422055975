import * as React from 'react';

import Avatar from '@mui/material/Avatar'
import Box from '@mui/material/Box'
import ListItem from '@mui/material/ListItem';
import {Link} from "react-router-dom"
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Typography from '@mui/material/Typography';



import { CharismaUserContext } from '../context.tsx';

export default function CharismaTutorStudentsListItem(props){
    

    const [course, setCourse] = React.useState(props.course);
    const [running, setRunning] = React.useState(props.running);
    const [reason, setReason] = React.useState(props.reason);
    const [student, setStudent] = React.useState(props.student);
    const [training, setTranining] = React.useState(props.training);

    

   // const lCurrentUser = React.useContext(CharismaUserContext);

    
    React.useEffect(()=>{

        setReason(props.reason);
        setRunning(props.running)
        setStudent(props.student);       
        setTranining(props.training);    
        setCourse(props.course);
    }, [props.reason, props.running, props.student, props.training, props.course])
    

    
    const lReasons=["Certification", "Abandon", "Echec"]

    return(               
        <ListItem 
            alignItems='flex-start'
            button

            component={props.onSelect === undefined ? Link : null}
            to={props.onSelect === undefined ? "/studentTraining/" + props.id + '/book' : null}            
            onClick={()=>props.onSelect(props.training.id,props.id)}
            sx={{minWidth:300,background:props.selected ? "lightgray" : null}}
        >
            <ListItemAvatar>
                {
                    student.avatar !== null ?
                    <Avatar
                        src={student.avatar}
                    />
                    :
                    <Avatar>
                        {student.firstName[0] + student.lastName[0]}
                    </Avatar>
                }                    
            </ListItemAvatar>
            <ListItemText
                primary={student.firstName + ' ' + student.lastName}
                secondary={
                    <Box>                    
                    <Typography>{"Formation : " + training.training}</Typography>
                    <Typography>{"Parcours : " + course.course}</Typography>
                        {running ?
                            <Typography>Statut : En Cours</Typography>
                            :
                            <Typography>{"Statut : " + lReasons[reason]}</Typography>
                        }
                                             
                    </Box>
                }
            />            
        </ListItem>                                
    );
    
    
}