import * as React from 'react'
import CharismaTrainingsList from '../training/trainingsList.tsx';


export default function CharismaWelcomeTeacher(props){
    
    return(
       <CharismaTrainingsList
            title={'Formations en Cours'}
        />
    );
}