import * as React from 'react';


import Box from '@mui/material/Box'

import EditIcon from '@mui/icons-material/Edit'
import Alert from '@mui/material/Alert'
import Button from '@mui/material/Button'

import { CharismaEvaluationTemplateApi } from '../api/evaluations/CharismaEvaluationTemplateApi.tsx';
import CharismaEvaluationTemplateSectionForm from './evaluationTemplateSectionForm.tsx';
import CharismaEvaluationTemplatePeriodicItems from './evaluationTemplatePeriodicItems.tsx';
import DeleteIcon from '@mui/icons-material/Delete'
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';

import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';






export default function CharismaEvaluationTemplatePeriodicSection(props){
    
    const [title, setTitle] = React.useState(props.section !== undefined ? props.section.title : "");
    const [comment, setComment] = React.useState(props.section.comment !== undefined ? props.section.comment : "");
    const [items, setItems] = React.useState(props.section !== undefined ? props.section.items : []);
    const [deleteSection, setDeleteSection] = React.useState(false);
    const [modify, setModify] = React.useState(false);
    

    
    const lSectionId = props.section.id;    
    const lEvaluationTemplateId = props.evaluationTemplateId;
    
    React.useEffect(()=>{
        const lResponse = CharismaEvaluationTemplateApi.GetEvaluationTemplateSection(lSectionId);
        lResponse.then((value)=>{
            if(value.error === 0){ 
                if(value.section !== null){
                    setTitle(value.section.title)
                    setComment(value.section.comment)
                    setItems(value.section.items);
                    setModify(false);             
                }
            }
            else{
                
            }
        }).catch((error)=>{
                console.log("catch error")
        })

    }, [lSectionId])        
    
    
    function handleCancel(){
        setModify(false);
    }
    
    function handleDelete(){
        props.handleDeleteSection(lSectionId);
    }
               
    function handleUpdate(pTitle : string, pComment: string, pId : number){
        const lResponse = CharismaEvaluationTemplateApi.UpdateItem(pId, pTitle,  pComment, 0, 0);
        lResponse.then((value)=>{
            if(value.error === 0){ 
                if(value.section !== null){
                    setTitle(value.section.title)
                    setComment(value.section.comment)
                    setModify(false);             
                }
            }
            else{
                
            }
        }).catch((error)=>{
                console.log("catch error")
        })
    }
        
                                   
    return(
        
        <Box sx={{border:'1px solid gray', borderRadius:'8px', margin:'10px'}}>
            <Toolbar>
                <div>
                    <Typography variant="h6" display="block">
                        {title}
                    </Typography>
                    <Typography variant='caption'>
                        {comment}
                    </Typography>
                </div>
                
                    <IconButton>
                        <EditIcon
                                onClick={()=>setModify(true)}
                            />
                    </IconButton>
                    <IconButton onClick={()=>setDeleteSection(true)}>
                            <DeleteIcon/>
                    </IconButton>
                
            </Toolbar>
                    
            <CharismaEvaluationTemplatePeriodicItems
                sectionId = {lSectionId}
                evaluationTemplateId={lEvaluationTemplateId}
                templateType={props.templateType}
                items={items}
            />
            

            {modify ?
                <CharismaEvaluationTemplateSectionForm
                    title={"Modification"}
                    id={lSectionId}
                    name={title}
                    comment={comment}
                    handleSubmit={handleUpdate}
                    handleCancel={handleCancel}
                />
                :
                null
            }

         
            <Dialog
                open={deleteSection}
            >
                <DialogTitle>Suppression de la section</DialogTitle>
                <DialogContent>
                    <div>
                        <Typography>Etes-vous sûr de vouloir supprimer définitivement la question?</Typography>
                        <Alert severity='warning'>Cette action sera définitive</Alert>
                    </div>
                    
                </DialogContent>
                <DialogActions>
                    <Button autoFocus onClick={()=>setDeleteSection(false)}>
                        Annuler
                    </Button>
                    <Button onClick={()=>{handleDelete();setDeleteSection(false)}}>
                        Valider
                    </Button>
                </DialogActions>
            </Dialog> 
        </Box>      
    );      
}