import * as React from 'react';


import Alert from "@mui/material/Alert"
import Box from '@mui/material/Box'
import AddIcon from "@mui/icons-material/Add"
import Button from '@mui/material/Button'
import CharismaFileForm from './fileForm.tsx';
import Checkbox from '@mui/material/Checkbox';
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import DeleteIcon from "@mui/icons-material/Delete"
import Grid from '@mui/material/Grid'

import IconButton from '@mui/material/IconButton'
import Toolbar from '@mui/material/Toolbar'
import Typography from '@mui/material/Typography';

export default function CharismaImagesList(props){
    
    const [add, setAdd] = React.useState(false);
    const [deleteImages, setDeleteImages] = React.useState(false);
    const [selected, setSelected] = React.useState([]);
    
    function cancelAdd(){
        setAdd(false);
    }
    
    function submit(pFile : File){
        props.handleAdd(pFile);
        setAdd(false);
    }
    

    function handleDelete(){
        props.handleDelete(selected);
    }
    
    function handleSelected(pEvent : React.ChangeEvent<HTMLInputElement>, pName : string){
        let lNewSelected : number[] = [];
        let lIndex = selected.indexOf(pName);
        if(pEvent.target.checked && lIndex === -1){
            lNewSelected = lNewSelected.concat(selected, pName);
        }
        else if(lIndex !== -1){
            if(lIndex === 0){
                lNewSelected = lNewSelected.concat(selected.slice(1));
            }
            else if(lIndex === selected.length - 1){
                lNewSelected = lNewSelected.concat(selected.slice(0, -1));
            }
            else{
                lNewSelected = lNewSelected.concat(selected.slice(0, lIndex), selected.slice(lIndex + 1),);
            }
        }
        setSelected(lNewSelected);
    }
    
    return( 
        <Box>
            {props.add === undefined || props.add ?
            <Toolbar>
                <IconButton onClick={()=>setAdd(true)}>
                    <AddIcon/>
                </IconButton>
                {selected.length > 0 ?
                    <IconButton onClick={()=>setDeleteImages(true)}>
                        <DeleteIcon/>
                    </IconButton>
                :
                null
                }
            </Toolbar>
            :
            null
            }
            {add ?
                <CharismaFileForm
                    type="img"
                    title="Ajouter une image"
                    handleCancel={cancelAdd}
                    handleSubmit={submit}
                />
                :
                null
            }
            <Grid container spacing={4}>
            {
                props.pictures.map((lPicture)=>(
                    <Grid item sx={12} sm={props.grid !== undefined ? props.grid : 4}>
                    <Box display="flex">
                    {props.add === undefined || props.add?
                        <Checkbox
                            checked={selected.indexOf(lPicture.name) !== -1}
                            onClick={(event)=>handleSelected(event, lPicture.name)}
                        />
                        :
                        null
                    }
                    <img alt="" src={lPicture.link} width={props.width === undefined ? '100%' : props.width} />
                    </Box>  
                    </Grid>
                ))
            }
            </Grid>
            <Dialog
                open={deleteImages}
            >
                <DialogTitle>Suppression des image</DialogTitle>
                <DialogContent>
                    <div>
                        <Typography>Etes-vous sûr de vouloir supprimer définitivement les images sélectionnés?</Typography>
                        <Alert severity='warning'>Cette action sera définitive</Alert>
                    </div>
                    
                </DialogContent>
                <DialogActions>
                    <Button autoFocus onClick={()=>setDeleteImages(false)}>
                        Annuler
                    </Button>
                    <Button onClick={()=>{handleDelete();setDeleteImages(false)}}>
                        Valider
                    </Button>
                </DialogActions>
            </Dialog>             
        </Box>
    );
}