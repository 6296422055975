import * as React from 'react';

import Alert from "@mui/material/Alert"
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem'
import Select from '@mui/material/Select'
import TextField from '@mui/material/TextField'
import { ValidatePicture } from '../utils/Validator.tsx';

export default function CharismaUserFileForm(props){
    
    const [file, setFile] = React.useState(null);
    const [wait, setWait] = React.useState(false);
    const [type, setType] = React.useState(props.documentTypes[0].id);
    const [error, setError] = React.useState(0);
    
    function handleSubmit(){
        let lValidFile: boolean = true;

        if(props.type === 'img'){
            lValidFile = ValidatePicture(file);
        }
        if(!lValidFile){
            setError(1);
        }
        else{
            setWait(true);
            props.handleSubmit(file, type)
        }
    }
    
    return(
        <Dialog
            open={true}
        >
            <DialogTitle>
                {props.title === undefined ?
                    'Photo de profil'
                    :
                    props.title
                }
            </DialogTitle>
            <DialogContent>
                <TextField
                    type='file'
                    required
                    fullWidth
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                        setFile(event.target.files[0]);
                        setError(0);
                    }}
                />
                <FormControl fullWidth sx={{mt:'10px'}}>
                <InputLabel id="type">Type de Document</InputLabel>
                    <Select
                        labelId="type"
                        id="select"
                        value={type}
                        label="Type de Document"
                        onChange={(event)=>setType(event.target.value)}
                    >
                    {
                        props.documentTypes.map((document)=>(
                            <MenuItem value={document.id}>{document.type}</MenuItem>   
                        ))   
                    }
                  </Select>
                </FormControl>                
                {error > 0 ?
                    <Alert severity="error">{"Format de fichier invalide"}</Alert>
                    :
                    null
                }
                {wait ?
                    <Alert severity="info">{"Transfert du fichier en cours..."}</Alert>
                    :
                    null
                }
            </DialogContent>
            <DialogActions>
                <Button disabled={wait} onClick={()=>handleSubmit()}>Valider</Button>
                <Button disabled={wait} onClick={()=>props.handleCancel()}>Annuler</Button>
            </DialogActions>
        </Dialog>        
    );
}