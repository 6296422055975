
import { CharismaApi } from "../CharismaApi.js";

export const CharismaFinancalApi = {
    CreateFinancal: async function(pName : string){
        const lDatas = new FormData();
        lDatas.append('name', pName);
        const lResponse = await CharismaApi.request({
            url:`/financals/create.php`,
            method:"POST",
            data:lDatas
        })
        return lResponse.data;
    },
    ListFinancals: async function(){

        const lResponse = await CharismaApi.request({
            url:`/financals/list.php`,
            method:"GET",
            params:{
            }
        })
        return lResponse.data;
    },    
    
    UpdateFinancal: async function(pName : string, pFinancalId: number){
        const lDatas = new FormData();
        lDatas.append('name', pName);
        lDatas.append('id', pFinancalId);
        const lResponse = await CharismaApi.request({
            url:`/financals/update.php`,
            method:"POST",
            data:lDatas
        })
        return lResponse.data;
    },
    
    DeleteFinancals: async function(pIds : number[]){
        const lDatas = new FormData();
        pIds.forEach((lId)=>{
            lDatas.append("ids[]", lId);    
        });
        const lResponse = await CharismaApi.request({
            url:`/financals/delete.php`,
            method:"POST",
            data:lDatas
        })
        return lResponse.data;
    },
    GetFinancal: async function(pId : number[]){
   
        const lResponse = await CharismaApi.request({
            url:`/financals/get.php`,
            method:"GET",
            params:{
                "financalId" : pId
            }
        })
        return lResponse.data;
    },
    AddPicture: async function(pId : number, pPicture : File){
        const lDatas = new FormData();
        lDatas.append('id', pId);
        lDatas.append('picture', pPicture);
        const lResponse = await CharismaApi.request({
            url:`/financals/addPicture.php`,
            method:"POST",
            data:lDatas
        })
        return lResponse.data;
    },
    ListPictures: async function(pId : number){
   
        const lResponse = await CharismaApi.request({
            url:`/financals/listPictures.php`,
            method:"GET",
            params:{
                "financalId" : pId
            }
        })
        return lResponse.data;
    },
    DeletePictures: async function(pPictures : string[], pId : number){
        const lDatas = new FormData();
        lDatas.append('id', pId);        
        pPictures.forEach((lPicture)=>{
            lDatas.append("pictures[]", lPicture);    
        });
        const lResponse = await CharismaApi.request({
            url:`/financals/deletePictures.php`,
            method:"POST",
            data:lDatas
        })
        return lResponse.data;
    },
}