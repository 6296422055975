
import { CharismaApi } from "../CharismaApi.js";

export const CharismaAssessmentTemplateApi = {
    CreateAssessmentTemplate: async function(pTitle : string, pType : number){
        const lDatas = new FormData();
        lDatas.append('title', pTitle);
        lDatas.append('type', pType);
        const lResponse = await CharismaApi.request({
            url:`/assessmentTemplates/createAssessmentTemplate.php`,
            method:"POST",
            data:lDatas
        })
        return lResponse.data;
    },
    ListAssessmentTemplates: async function(pType : number){

        const lResponse = await CharismaApi.request({
            url:`/assessmentTemplates/listAssessmentTemplates.php`,
            method:"GET",
            params:{
                "type":pType
            }
        })
        return lResponse.data;
    },    
    
    UpdateAssessmentTemplate: async function(pTitle : string, pAssessmentId: number){
        const lDatas = new FormData();
        lDatas.append('title', pTitle);
        lDatas.append('id', pAssessmentId);
        const lResponse = await CharismaApi.request({
            url:`/assessmentTemplates/updateAssessmentTemplate.php`,
            method:"POST",
            data:lDatas
        })
        return lResponse.data;
    },
    
    DeleteAssessmentTemplates: async function(pIds : number[]){
        const lDatas = new FormData();
        pIds.forEach((lId)=>{
            lDatas.append("ids[]", lId);    
        });
        const lResponse = await CharismaApi.request({
            url:`/assessmentTemplates/deleteAssessmentTemplates.php`,
            method:"POST",
            data:lDatas
        })
        return lResponse.data;
    },
    GetAssessmentTemplate: async function(pId : number){
   
        const lResponse = await CharismaApi.request({
            url:`/assessmentTemplates/getAssessmentTemplate.php`,
            method:"GET",
            params:{
                "id" : pId
            }
        })
        return lResponse.data;
    },
    AddAssessmentTemplateItem: async function(pId : number, pTitle : string, pType : number, pStat:number){
        const lDatas = new FormData();
        lDatas.append('id', pId);
        lDatas.append('title', pTitle);
        lDatas.append('type', pType);
        lDatas.append('stat', pStat);
        const lResponse = await CharismaApi.request({
            url:`/assessmentTemplates/createAssessmentTemplateItem.php`,
            method:"POST",
            data:lDatas
        })
        return lResponse.data;
    },
    UpdateAssessmentTemplateItem: async function(pId : number, pTitle : string, pType:number, pStat:number){
        const lDatas = new FormData();
        lDatas.append('id', pId);
        lDatas.append('title', pTitle);
        lDatas.append('type', pType);
        lDatas.append('stat', pStat);
        const lResponse = await CharismaApi.request({
            url:`/assessmentTemplates/updateAssessmentTemplateItem.php`,
            method:"POST",
            data:lDatas
        })
        return lResponse.data;
    },
    DeleteAssessmentTemplateItems: async function(pIds : number[]){
        const lDatas = new FormData();
        pIds.forEach((lId)=>{
            lDatas.append("ids[]", lId);    
        });
        const lResponse = await CharismaApi.request({
            url:`/assessmentTemplates/deleteAssessmentTemplateItems.php`,
            method:"POST",
            data:lDatas
        })
        return lResponse.data;
    },
}