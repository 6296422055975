import * as React from 'react';


import Box from '@mui/material/Box'


import AddIcon from '@mui/icons-material/Add'
import Alert from '@mui/material/Alert'
import Button from '@mui/material/Button'
import Card from'@mui/material/Card'
import CardContent from'@mui/material/CardContent'
import CardHeader from'@mui/material/CardHeader'
import { CharismaTrainingApi } from '../api/trainings/CharismaTrainingApi.tsx';
import CharismaCourseForm from './courseForm.tsx';
import { CharismaUserContext } from '../context.tsx';
import Checkbox from '@mui/material/Checkbox';
import DeleteIcon from '@mui/icons-material/Delete'
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import EditIcon from '@mui/icons-material/Edit'
import {formatDate} from '../utils.tsx'
import Grid from '@mui/material/Grid'
import IconButton from '@mui/material/IconButton';
import RestoreFromTrashIcon from '@mui/icons-material/RestoreFromTrash';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs'
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';




export default function CharismaCoursesList(props){
    
    interface course{
        id: number,
        name: string,
        startDate: string,
        endDate: string
    }
    const [currentCourse, setCurrentCourse] = React.useState(null);
    const [create, setCreate] = React.useState(false);
    const [coursesList, setCoursesList] = React.useState([]);
    const [trashCoursesList, setTrashCoursesList] = React.useState([]);
    const [deleteCourses, setDeleteCourses] = React.useState(false);
    const [restoreCourses, setRestoreCourses] = React.useState(false);
    const [selectedCourses, setSelectedCourses] = React.useState([]);
    const [selectedTrashCourses, setSelectedTrashCourses] = React.useState([]);
    const [trashCourses, setTrashCourses] = React.useState(false);
    const [tabIndex, setTabIndex] = React.useState(0);
    const lCurrentUser = React.useContext(CharismaUserContext);
    const lReadWrite = lCurrentUser !== null && lCurrentUser !== undefined && lCurrentUser.profile === 0;
    


    React.useEffect(()=>{
        const lResponseCourses = CharismaTrainingApi.ListCourses(props.trainingId, 0);
        lResponseCourses.then((value)=>{
            if(value.error === 0){
                setCoursesList(value.courses);
                setTrashCoursesList(value.trashCourses);
            }
        }).catch((error)=>{
            
        })

    }, [props.trainingId])
    
    function handleCancel(){
        setCreate(false);
    }
    
    function handleTrashCourses(){
        const lResponse = CharismaTrainingApi.TrashCourses(selectedCourses);
        lResponse.then((value)=>{
            if(value.error === 0){
                if(value.ids !== null){
                    const lIds : number[] = value.ids;
                    const lTrashes = trashCoursesList.concat(coursesList.filter((lCourse)=>lIds.indexOf(lCourse.id) !== -1));
                    setTrashCoursesList(lTrashes);
                    const lCoursesList = coursesList.filter((lCourse)=>lIds.indexOf(lCourse.id) === -1);
                    setCoursesList(lCoursesList);
                    setTrashCourses(false);
                    setSelectedTrashCourses([]);
                    setSelectedCourses([]);
                }
            }
            else{
            
            }
        }).catch((error)=>{
            console.log("catch error")
        })
    }
    
    function handleDeleteCourses(){
        const lResponse = CharismaTrainingApi.DeleteCourses(selectedTrashCourses);
        lResponse.then((value)=>{
            if(value.error === 0){
                if(value.ids !== null){
                    const lIds = value.ids;
                    let lNewTrashCourses = trashCoursesList.filter((lCourse)=>lIds.indexOf(lCourse.id) === -1);
                    setTrashCoursesList(lNewTrashCourses);   
                    setDeleteCourses(false);
                    setSelectedTrashCourses([]);
                    setSelectedCourses([]);                      
                }
;            }
            else{
            
            }
        }).catch((error)=>{
            console.log("catch error")
        })           
    }    
    
    function handleRestoreCourses(){
        const lResponse = CharismaTrainingApi.RestoreCourses(selectedTrashCourses);
        lResponse.then((value)=>{
            if(value.error === 0){
                if(value.ids !== null){
                    const lIds = value.ids;
                    let lNewCourses = coursesList.concat(trashCoursesList.filter((lCourse)=>lIds.indexOf(lCourse.id) !== -1));
                    let lNewTrashCourses = trashCoursesList.filter((lCourse)=>lIds.indexOf(lCourse.id) === -1);
                    setTrashCoursesList(lNewTrashCourses);
                    setCoursesList(lNewCourses);
                    setRestoreCourses(false);
                    setSelectedTrashCourses([]);
                    setSelectedCourses([]);                     
                }
            }
            else{
            
            }
        }).catch((error)=>{
            console.log("catch error")
        })           
    }    
    function handleCreateCourse(pName : string, pStartDate : string, pEndDate: string){
        const lResponse = CharismaTrainingApi.CreateCourse(pName, pStartDate, pEndDate, props.trainingId);
        lResponse.then((value)=>{
            if(value.error === 0){
                if(value.course !== null){
                    let lCourses = coursesList.concat(value.course);
                    setCoursesList(lCourses);
                    setCreate(false);
                }
                
            }
            else{
                
            }
        }).catch((error)=>{
                console.log("catch error")
        })
    }    
    function handleUpdateCourse(pName : string, pStartDate : string, pEndDate: string, pId : number){
        const lResponse = CharismaTrainingApi.UpdateCourse(pName, pStartDate, pEndDate, pId);
        lResponse.then((value)=>{
            if(value.error === 0){
                if(value.course !== null){
                    const lCourses = coursesList.map((lCourse)=>lCourse.id !== value.course.id ? lCourse : value.course);
                    setCoursesList(lCourses);
                    setCurrentCourse(null);
                }
                
            }
            else{
                
            }
        }).catch((error)=>{
                console.log("catch error")
        })
    }       
    
    
    function handleSelectedCourse(pEvent : React.ChangeEvent<HTMLInputElement>, pId : number){
        let lNewSelected : number[] = [];
        let lIndex = selectedCourses.indexOf(pId);
        if(pEvent.target.checked && lIndex === -1){
            lNewSelected = lNewSelected.concat(selectedCourses, pId);
        }
        else if(lIndex !== -1){
            if(lIndex === 0){
                lNewSelected = lNewSelected.concat(selectedCourses.slice(1));
            }
            else if(lIndex === selectedCourses.length - 1){
                lNewSelected = lNewSelected.concat(selectedCourses.slice(0, -1));
            }
            else{
                lNewSelected = lNewSelected.concat(selectedCourses.slice(0, lIndex), selectedCourses.slice(lIndex + 1),);
            }
        }
        setSelectedCourses(lNewSelected);
    }
    
    function handleSelectedTrashCourse(pEvent : React.ChangeEvent<HTMLInputElement>, pId : number){
        let lNewSelected : number[] = [];
        let lIndex = selectedTrashCourses.indexOf(pId);
        if(pEvent.target.checked && lIndex === -1){
            lNewSelected = lNewSelected.concat(selectedTrashCourses, pId);
        }
        else if(lIndex !== -1){
            if(lIndex === 0){
                lNewSelected = lNewSelected.concat(selectedTrashCourses.slice(1));
            }
            else if(lIndex === selectedTrashCourses.length - 1){
                lNewSelected = lNewSelected.concat(selectedTrashCourses.slice(0, -1));
            }
            else{
                lNewSelected = lNewSelected.concat(selectedTrashCourses.slice(0, lIndex), selectedTrashCourses.slice(lIndex + 1),);
            }
        }
        setSelectedTrashCourses(lNewSelected);
    }
    
    
    function handleSubmit(pName : string, pStartDate : string, pEndDate: string){
        handleCreateCourse(pName, pStartDate, pEndDate);

    }
    
    function handleUpdate(pName : string, pStartDate : string, pEndDate: string, pId : number){
        handleUpdateCourse(pName, pStartDate, pEndDate, pId);        
    }
    
    function handleCancelUpdate(){
        setCurrentCourse(null);
    }
    
    function handleEdit(pId : number){
        const lResponse = CharismaTrainingApi.GetCourse(pId);
        lResponse.then((value)=>{
            if(value.error === 0){
                setCurrentCourse(value.course);
                
            }
            else{
                
            }
        }).catch((error)=>{
                console.log("catch error")
        })  
    }
    
    function CustomTabPanel(props: TabPanelProps) {
        const { children, value, index, ...other } = props;
    
        return (
            <div
              role="tabpanel"
              hidden={value !== index}
              id={`simple-tabpanel-${index}`}
              aria-labelledby={`simple-tab-${index}`}
              {...other}
            >
              {value === index && (
                <Box sx={{ p: 3 }}>
                  <Typography component={"div"}>{children}</Typography>
                </Box>
              )}
            </div>
        );
    }
    
    function createCoursesTable(pCourses : course[], pSelected : number[], pTrash : boolean){
        return(
            pCourses.map((course)=>(
                <TableRow key={course.id}>
                    {lReadWrite ?
                        <TableCell padding="checkbox">
                            <Checkbox
                                checked={pSelected.indexOf(course.id) >= 0}
                                onClick={
                                    pTrash?
                                    (event)=>handleSelectedTrashCourse(event, course.id)
                                    :
                                    (event)=>handleSelectedCourse(event, course.id)
                                    }
                            />
                        </TableCell>
                        :
                        null
                    }
                    <TableCell>
                        {course.name}
                    </TableCell>
                    <TableCell sx={{display:{xs:'none', md:'table-cell'}}}>
                        {formatDate(course.startDate)}
                    </TableCell>
                    <TableCell sx={{display:{xs:'none', md:'table-cell'}}}>
                        {formatDate(course.endDate)}
                    </TableCell>
                    <TableCell sx={{display:{xs:'none', md:'table-cell'}}}>
                        {course.nbStudents === undefined || course.nbStudents === null ? 0 : course.nbStudents}
                    </TableCell>
                    {lReadWrite && !pTrash ?
                        <TableCell padding="checkbox">
                            <IconButton
                                onClick={()=>handleEdit(course.id)}
                            >
                                <EditIcon/>
                            </IconButton>
                        </TableCell>
                        :
                        null
                    }
                </TableRow>
                 
            ))
        )
    }
    
    
    return(
        
        <Box>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={12}>
                    <Card>
                        <CardHeader
                            title="Parcours de formation"
                            action={
                                <div>
                                {lReadWrite && tabIndex === 0?
                                <div>
                                <IconButton onClick={()=>setCreate(true)}>
                                    <AddIcon/>
                                </IconButton>
                                {selectedCourses.length > 0?
                                    <IconButton onClick={()=>setTrashCourses(true)}>
                                        <DeleteIcon/>
                                    </IconButton>  
                                    :
                                    null
                                } 
                                </div>
                                :
                                null
                                }
                                {
                                lReadWrite && tabIndex === 1 && selectedTrashCourses.length > 0 ?
                                <div>
                                    <IconButton>
                                        <RestoreFromTrashIcon
                                            onClick={()=>setRestoreCourses(true)}
                                        />
                                    </IconButton>
                                    <IconButton onClick={()=>setDeleteCourses(true)}>
                                        <DeleteIcon/>
                                    </IconButton>  
                                    </div>
                                    :
                                    null
                                }
                                </div>
                            }
                        />
                        <CardContent>
                            <Tabs value={tabIndex} onChange={(event: React.SyntheticEvent, newValue: number)=>setTabIndex(newValue)}>
                                <Tab label={'En Cours (' + coursesList.length + ')'}/>
                                <Tab label={'Corbeille (' + trashCoursesList.length + ')'}/>
                            </Tabs>
                            <CustomTabPanel value={tabIndex} index={0}>
                                <Table>                               
                                    <TableHead>
                                        <TableRow>
                                            {lReadWrite ?
                                                <TableCell padding="checkbox">
                                                    </TableCell>
                                                  :
                                                  null
                                            }
                                            <TableCell>Nom</TableCell>
                                            <TableCell sx={{display:{xs:'none', md:'table-cell'}}}>Début</TableCell>
                                            <TableCell sx={{display:{xs:'none', md:'table-cell'}}}>Fin</TableCell>
                                            <TableCell sx={{display:{xs:'none', md:'table-cell'}}}>Nombre de Stagiaires</TableCell>
                                            {lReadWrite ?
                                                <TableCell padding="checkbox">
                                                </TableCell>
                                                  :
                                                  null
                                            }
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                    {
                                        createCoursesTable(coursesList, selectedCourses, false)
                                    }
                                    </TableBody>
                                </Table>
                            </CustomTabPanel>
                            <CustomTabPanel value={tabIndex} index={1}>
                                <Table>                               
                                    <TableHead>
                                        <TableRow>
                                            {lReadWrite ?
                                                <TableCell padding="checkbox">
                                                    </TableCell>
                                                  :
                                                  null
                                            }
                                            <TableCell>Nom</TableCell>
                                            <TableCell>Début</TableCell>
                                            <TableCell>Fin</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                    {
                                        createCoursesTable(trashCoursesList, selectedTrashCourses, true)
                                    }
                                    </TableBody>
                                </Table>
                            </CustomTabPanel>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
            {create ?
                <CharismaCourseForm
                    title={"Ajout d'un parcours"}
                    handleSubmit={handleCreateCourse}
                    handleCancel={handleCancel}
                    minDate={props.startDate}
                    maxDate={props.endDate}
                />
                :
                null    
            }    
            {
                currentCourse !== null ?
                <CharismaCourseForm
                    title={currentCourse.name}
                    name = {currentCourse.name}
                    startDate={currentCourse.startDate}
                    endDate={currentCourse.endDate}
                    id={currentCourse.id}
                    minDate={props.startDate}
                    maxDate={props.endDate}
                    handleSubmit={handleUpdateCourse}
                    handleCancel={handleCancelUpdate}
                />
                :
                null
            }
            <Dialog
                open={trashCourses}
            >
                <DialogTitle>Suppression de Parcours</DialogTitle>
                <DialogContent>
                    <Typography>Etes-vous sûr de vouloir mettre à la corbeille les parcours sélectionnés?</Typography>
                    {
                        false?
                            <Alert severity='info'>Suppression en cours...</Alert>
                        :
                        null
                    }
                </DialogContent>
                <DialogActions>
                    <Button autoFocus onClick={()=>setTrashCourses(false)}>
                        Annuler
                    </Button>
                    <Button onClick={handleTrashCourses}>
                        Valider
                    </Button>
                </DialogActions>
            </Dialog> 
            <Dialog
                open={deleteCourses}
            >
                <DialogTitle>Suppression de Parcours</DialogTitle>
                <DialogContent>
                    <Typography>Etes-vous sûr de vouloir supprimer définitivement les parcours sélectionnés?</Typography>
                    {
                        false?
                            <Alert severity='info'>Suppression en cours...</Alert>
                        :
                        null
                    }
                </DialogContent>
                <DialogActions>
                    <Button autoFocus onClick={()=>setDeleteCourses(false)}>
                        Annuler
                    </Button>
                    <Button onClick={handleDeleteCourses}>
                        Valider
                    </Button>
                </DialogActions>
            </Dialog> 
            <Dialog
                open={restoreCourses}
            >
                <DialogTitle>Restauration de Parcours</DialogTitle>
                <DialogContent>
                    <Typography>Etes-vous sûr de vouloir restaurer les parcours sélectionnés?</Typography>
                    {
                        false?
                            <Alert severity='info'>Restauration en cours...</Alert>
                        :
                        null
                    }
                </DialogContent>
                <DialogActions>
                    <Button autoFocus onClick={()=>setRestoreCourses(false)}>
                        Annuler
                    </Button>
                    <Button onClick={handleRestoreCourses}>
                        Valider
                    </Button>
                </DialogActions>
            </Dialog>             
        </Box>      
    );
    
    
}