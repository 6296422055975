
import { CharismaApi } from "../CharismaApi.js";

export const CharismaUserApi = {
    ConnectUser: async function(pMail: string, pPassword:string, pProfile : number){
        const lDatas = new FormData();
        lDatas.append('mail', pMail);
        lDatas.append('password', pPassword);
        lDatas.append('profile', pProfile);
        const lResponse = await CharismaApi.request({
            url: `/users/connect.php`,
            method: "POST",
            data:lDatas
        })
        return lResponse.data;
    },
    
    DisconnectUser: async function(){
        const lResponse = await CharismaApi.request({
            url: `/users/disconnect.php`,
            method: "POST"
        })
        return lResponse.data;
    },
    
    UpdateUser: async function(pFirstName : string, pLastName : string, pMail : string, pPhone : string, pUserId : number){
        const lDatas = new FormData();
        lDatas.append('firstName', pFirstName);
        lDatas.append('lastName', pLastName);
        lDatas.append('mail', pMail);
        lDatas.append('phone', pPhone);
        lDatas.append('userId', pUserId);
        const lResponse = await CharismaApi.request({
            url:`/users/update.php`,
            method:"POST",
            data:lDatas
        })
        return lResponse.data;
    },
    CreateUser: async function(pFirstName : string, pLastName : string, pMail : string, pPhone : string, pProfile: number){
        const lDatas = new FormData();
        lDatas.append('firstName', pFirstName);
        lDatas.append('lastName', pLastName);
        lDatas.append('mail', pMail);
        lDatas.append('phone', pPhone);
        lDatas.append('profile', pProfile);
        const lResponse = await CharismaApi.request({
            url:`/users/create.php`,
            method:"POST",
            data:lDatas
        })
        return lResponse.data;
    },
    CreateStudent: async function(pFirstName : string, pLastName : string, 
        pMail : string, pPhone : string, pProfile: number, pTrainingId:number, pCourseId:number,
        pTutorId:number, pFinancalId:number, pStartDate:string, pEndDate:string){
        const lDatas = new FormData();
        lDatas.append('firstName', pFirstName);
        lDatas.append('lastName', pLastName);
        lDatas.append('mail', pMail);
        lDatas.append('phone', pPhone);
        lDatas.append('profile', pProfile);
        lDatas.append('trainingId', pTrainingId);
        lDatas.append('courseId', pCourseId);
        lDatas.append('tutorId', pTutorId);
        lDatas.append('financalId', pFinancalId);
        lDatas.append('startDate', pStartDate);
        lDatas.append('endDate', pEndDate);
        const lResponse = await CharismaApi.request({
            url:`/users/createStudent.php`,
            method:"POST",
            data:lDatas
        })
        return lResponse.data;
    },
    ForgotPassword: async function(pMail : string){
        const lDatas = new FormData();
        lDatas.append('mail', pMail);
        const lResponse = await CharismaApi.request({
            url:`/users/forgotPassword.php`,
            method:"POST",
            data:lDatas
        })
        return lResponse.data;
    },
    UpdatePassword: async function(pId : number, pPassword : string, pNew : string){
        const lDatas = new FormData();
        lDatas.append('id', pId);
        lDatas.append('password', pPassword);
        lDatas.append('newPassword', pNew);
        const lResponse = await CharismaApi.request({
            url:`/users/updatePassword.php`,
            method:"POST",
            data:lDatas
        })
        return lResponse.data;
    },
    UpdateAvatar: async function(pAvatar : File,  pUserId : number){
        const lDatas = new FormData();
        lDatas.append('avatar', pAvatar);
        lDatas.append('userId', pUserId);
        const lResponse = await CharismaApi.request({
            url:`/users/updateAvatar.php`,
            method:"POST",
            data:lDatas
        })
        return lResponse.data;
    },
    UpdateSignature: async function(pSignature: File ,  pUserId : number){
        const lDatas = new FormData();
        lDatas.append('signature', pSignature);
        lDatas.append('userId', pUserId);
        const lResponse = await CharismaApi.request({
            url:`/users/updateSignature.php`,
            method:"POST",
            data:lDatas
        })
        return lResponse.data;
    },
    AddFile: async function(pFile : File, pDocumentType: number, pUserId : number, pUserProfile : number){
        const lDatas = new FormData();
        lDatas.append('file', pFile);
        lDatas.append('userId', pUserId);
        lDatas.append('documentType', pDocumentType);
        lDatas.append('userProfile', pUserProfile);
        const lResponse = await CharismaApi.request({
            url:`/users/addFile.php`,
            method:"POST",
            data:lDatas
        })
        return lResponse.data;
    },
    
    ListFiles: async function(pUserId : number, pUserProfile : number){
        const lResponse = await CharismaApi.request({
            url:`/users/listUserFiles.php`,
           method:"GET",
            params:{
                profile:pUserProfile,
                userId: pUserId
            }
        })
        return lResponse.data;
    },
    
    DeleteFiles: async function(pFiles : string[]){
        const lDatas = new FormData();
        pFiles.forEach((lFile)=>{
            lDatas.append("files[]", lFile);    
        });
        const lResponse = await CharismaApi.request({
            url:`/users/deleteFiles.php`,
            method:"POST",
            data:lDatas
        })
        return lResponse.data;
    },
    
    CheckConnected: async function(){
        const lResponse = await CharismaApi.request({
            url:`/users/checkConnected.php`,
            method:"POST",
        })
        return lResponse.data;
    },
    List: async function(pProfile : number, trash : number){

        const lResponse = await CharismaApi.request({
            url:`/users/list.php`,
            method:"GET",
            params:{
                profile:pProfile,
                trash: trash
            }
        })
        return lResponse.data;
    },
    Get: async function(pId : number){

        const lResponse = await CharismaApi.request({
            url:`/users/get.php`,
            method:"GET",
            params:{
                id:pId
            }
        })
        return lResponse.data;
    },    
    DeleteUser: async function(pId : number, pProfile : number){
        const lDatas = new FormData();
        lDatas.append("userId", pId);
        lDatas.append("profile", pProfile);
        const lResponse = await CharismaApi.request({
            url:`/users/delete.php`,
            method:"POST",
            data:lDatas
        })
        return lResponse.data;
    },
    TrashUser: async function(pId : number, pProfile : number){
        const lDatas = new FormData();
        lDatas.append("userId", pId);
        lDatas.append("profile", pProfile);
        const lResponse = await CharismaApi.request({
            url:`/users/trash.php`,
            method:"POST",
            data:lDatas
        })
        return lResponse.data;
    },
    RestoreUser: async function(pId : number, pProfile : number){
        const lDatas = new FormData();
        lDatas.append("userId", pId);
        lDatas.append("profile", pProfile);
        const lResponse = await CharismaApi.request({
            url:`/users/untrash.php`,
            method:"POST",
            data:lDatas
        })
        return lResponse.data;
    },
    UpdateProfile: async function(pId : number, pProfile : number, pAdd : boolean){
        const lDatas = new FormData();
        lDatas.append("userId", pId);
        lDatas.append("profile", pProfile);
        if(pAdd){
            lDatas.append("add", 1);
        }
        else{
            lDatas.append("add", 0);
        }
        const lResponse = await CharismaApi.request({
            url:`/users/updateProfile.php`,
            method:"POST",
            data:lDatas
        })
        return lResponse.data;
    },    
    ListUserDocument: async function(pProfile:number){
        const lResponse = await CharismaApi.request({
            url:`/users/listUserDocuments.php`,
            method:"GET",
            params:{
                profile:pProfile
            }
        })
        return lResponse.data;
    },
    CreateUserDocument: async function(pProfile:number, pType:string){
        const lDatas = new FormData();
        lDatas.append('profile', pProfile);
        lDatas.append('type', pType);
        const lResponse = await CharismaApi.request({
            url:`/users/createUserDocument.php`,
            method:"POST",
            data:lDatas
        })
        return lResponse.data;
    },
    UpdateUserDocument: async function(pType:string, pId:number){
        const lDatas = new FormData();
        lDatas.append('id', pId);
        lDatas.append('type', pType);
        const lResponse = await CharismaApi.request({
            url:`/users/updateUserDocument.php`,
            method:"POST",
            data:lDatas
        })
        return lResponse.data;
    },
    DeleteUserDocument: async function(pIds : number[]){
        const lDatas = new FormData();
        pIds.forEach((lId)=>{
            lDatas.append("ids[]", lId);    
        });
        const lResponse = await CharismaApi.request({
            url:`/users/deleteUserDocuments.php`,
            method:"POST",
            data:lDatas
        })
        return lResponse.data;
    },
    ImportUsers: async function(pFile : File,  pUserProfile : number){
        const lDatas = new FormData();
        lDatas.append('file', pFile);
        lDatas.append('userProfile', pUserProfile);
        const lResponse = await CharismaApi.request({
            url:`/users/import.php`,
            method:"POST",
            data:lDatas
        })
        return lResponse.data;
    },        
}