import * as React from 'react';

import AddIcon from '@mui/icons-material/Add'
import Avatar from '@mui/material/Avatar'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import CardHeader from '@mui/material/CardHeader';
import CharismaMessage from '../components/message.tsx';
import { CharismaUserApi } from '../api/users/CharismaUserApi.tsx';
import CharismaSignatureFileForm from '../components/signatureFileForm.tsx';
import CharismaUserForm  from './userForm.tsx';
import Checkbox  from '@mui/material/Checkbox';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import DeleteIcon from '@mui/icons-material/Delete'
import EditIcon from '@mui/icons-material/Edit'
import FormControlLabel from '@mui/material/FormControlLabel';
import Grid from '@mui/material/Grid'
import IconButton from '@mui/material/IconButton';
import { Link } from 'react-router-dom';
import RestoreFromTrashIcon from '@mui/icons-material/RestoreFromTrash';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';



import { CharismaUserContext } from '../context.tsx';
import { formatDate } from '../utils.tsx';

export default function CharismaUsersListItem(props){
    
    const lUser = props.user;
    const [update, setUpdate] = React.useState(false);
    const [addSignature, setAddSignature] = React.useState(false);
    const [error, setError] = React.useState(0);
    const [deleteUser, setDeleteUser] = React.useState(props.deleting);
    const [restoreUser, setRestoreUser] = React.useState(props.deleting);
    const [mail, setMail] = React.useState(lUser.mail);
    const [phone, setPhone] = React.useState(lUser.phone);
    const [signature, setSignature] = React.useState(lUser.signature);
    const [firstName, setFirstName] = React.useState(lUser.firstName);
    const [lastName, setLastName] = React.useState(lUser.lastName);
    const [lastConnection, setLastConnection] = React.useState(lUser.lastConnection);
    const [profiles, setProfiles] = React.useState(lUser.profiles !== undefined ? lUser.profiles : []);
    

    const lCurrentUser = React.useContext(CharismaUserContext);
    const avatar = lUser.avatar !== undefined && lUser.avatar? lUser.avatar : null
    
    React.useEffect(()=>{
        setMail(lUser.mail);
        setFirstName(lUser.firstName)
        setPhone(lUser.phone);
        setLastName(lUser.lastName);
        setProfiles(lUser.profiles);
        setSignature(lUser.signature);
        setLastConnection(lUser.lastConnection);
        setDeleteUser(false);
        setRestoreUser(false);
    }, [lUser])
    
    const lTrash : boolean = props.trash === undefined ? false : true; 
    
    function handleCancel(){
        setUpdate(false);
    }
    
    function handleUpdateProfile(pProfile : number, event: React.ChangeEvent<HTMLInputElement>){
        const lAdd : boolean = event.target.checked;
        const lResponse = CharismaUserApi.UpdateProfile(lUser.id, pProfile, lAdd);
        lResponse.then((value)=>{
            if(value.error === 0){
                if(value.add !== null && value.add){
                    let lProfiles = profiles.concat(value.profile);   
                    setProfiles(lProfiles);
                }
                else{
                    let lProfiles = profiles.filter((lProfile)=>(lProfile !== value.profile));
                    setProfiles(lProfiles);
                }                
            }
            else{
                
            }
        }).catch((error)=>{
                console.log("catch error")
        })
    }
    
    
    function handleSubmit(pFName : string, pLName : string, pMail: string, pPhone : string){
        const lResponse = CharismaUserApi.UpdateUser(pFName, pLName, pMail, pPhone, lUser.id);
        lResponse.then((value)=>{
            setError(value.error);
            if(value.error === 0){
                setUpdate(false);
                if(value.user !== undefined && value.user !== null){
                    setFirstName(value.user.firstName);
                    setLastName(value.user.lastName);
                    setMail(value.user.mail);
                    setPhone(value.user.phone);
                }
            }
            else{
                
            }
        }).catch((error)=>{
                console.log("catch error")
        })
    }
    
    function handleCancelSignature(){
        setAddSignature(false);
    }
    
    function updateSignature(pSignature : string)
    {
        setSignature(pSignature);
    }

    return(
        
        <Box>
            <Card>
                <CardHeader
                    avatar={
                        avatar !== null ?
                        <Avatar
                            src={avatar}
                        />
                        :
                        <Avatar>
                            {firstName[0]+lastName[0]}
                        </Avatar>
                    }
                    action={
                        lCurrentUser.id !== lUser.id && lCurrentUser.profile === 0 ?
                        <div>
                            {lTrash?
                                <IconButton onClick={()=>setRestoreUser(true)}>
                                    <RestoreFromTrashIcon/>
                                </IconButton>
                                :
                                <IconButton onClick={()=>setUpdate(true)}>
                                    <EditIcon/>
                                </IconButton>
                            }
                            <IconButton onClick={()=>setDeleteUser(true)}>
                                <DeleteIcon/>
                            </IconButton>
                        </div>
                        :
                        null
                    }  
                    title={
                        <Box>
                            <Typography>{firstName + ' ' + lastName}</Typography>
                            <Typography>{mail}</Typography>
                            <Typography>{phone}</Typography>  
                            <Typography>{"Dernière connexion : " + formatDate(lastConnection)}</Typography>  
                        </Box>
                    } 
                />
                
                {lCurrentUser.profile === 0 ?
                <CardContent>
                    <Typography>Profiles</Typography>
                    <FormControlLabel
                        control={
                            <Checkbox  
                                checked={profiles.indexOf(0) !== -1}
                                name="admin" 
                                onChange={(event)=>handleUpdateProfile(0, event)}
                            />
                        }
                        label="Administrateur"
                    />
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={profiles.indexOf(1) !== -1}  
                                name="teacher"
                                onChange={(event)=>handleUpdateProfile(1, event)}
                            />
                        }
                        label="Formateur"
                    />
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={profiles.indexOf(2) !== -1}
                                name="tutor"
                                onChange={(event)=>handleUpdateProfile(2, event)}
                            />
                        }
                        label="Tuteur"
                    />
                    <FormControlLabel
                        control={
                            <Checkbox  
                                checked={profiles.indexOf(3) !== -1}
                                name="student"
                                onChange={(event)=>handleUpdateProfile(3, event)}
                            />
                        }
                        label="Stagiaire"
                    />  
                    <FormControlLabel
                        control={
                            <Checkbox  
                                checked={profiles.indexOf(4) !== -1}
                                name="exam"
                                onChange={(event)=>handleUpdateProfile(4, event)}
                            />
                        }
                        label="Examinateur"
                    />
                    <Grid container>
                        <Grid item xs={12} sm={6}>
                            <Button
                            component={Link}
                            to = {'/user/' + props.profile +'/' + props.user.id}
                            >
                                Infos
                            </Button>                        
                        </Grid>
                        {
                            lCurrentUser.profile === 0 ?
                            <Grid item xs={12} sm={6}>
                                <Toolbar>
                                    <Typography>Signature</Typography>
                                    <IconButton onClick={()=>setAddSignature(true)}>
                                        <AddIcon/>
                                    </IconButton>
                                </Toolbar>
                                <div>
                                <img
                                    src={signature}
                                    width="150"
                                />
                                </div>
                            </Grid>
                            :
                            null
                        }
                    </Grid>                       
                    </CardContent>                    
                    :
                    null  
                }                                    
                
            </Card>
            {
                addSignature ?
                <CharismaSignatureFileForm
                    userId={props.user.id}
                    handleCancel={handleCancelSignature}
                    updated={updateSignature}
                />
                :
                null
            }
            {update ?
                <CharismaUserForm
                    firstName = {firstName}
                    lastName = {lastName}
                    mail = {mail}
                    phone = {phone}
                    handleSubmit={handleSubmit}
                    handleCancel={handleCancel}
                />
                :
                null    
            }    
            <Dialog
                open={deleteUser}
            >
                <DialogTitle>{'Supprimer un utilisateur'}</DialogTitle>
                <DialogContent>
                    {lTrash ?
                        <Typography>{'Etes-vous sûr de vouloir supprimer définitivemenet l\'utlisateur'} </Typography>
                        :
                        <Typography>{'Etes-vous sûr de vouloir mette à la corbeille l\'utlisateur'} </Typography>
                    }
                    <Typography>{firstName + ' ' + lastName} </Typography>
                </DialogContent>
                <DialogActions>
                    <Button autoFocus onClick={()=>setDeleteUser(false)}>
                        Annuler
                    </Button>
                    <Button onClick={()=>props.handleDeleteUser(lUser.id)}>
                        Valider
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog
                open={restoreUser}
            >
                <DialogTitle>{'Réactiver un utilisateur'}</DialogTitle>
                <DialogContent>
                    <Typography>{'Etes-vous sûr de vouloir réactiver l\'utlisateur'} </Typography>
                    <Typography>{firstName + ' ' + lastName} </Typography>
                </DialogContent>
                <DialogActions>
                    <Button autoFocus onClick={()=>setRestoreUser(false)}>
                        Annuler
                    </Button>
                    <Button onClick={()=>props.handleRestoreUser(lUser.id)}>
                        Valider
                    </Button>
                </DialogActions>
            </Dialog>
            {
                error === 2 ?
                    <CharismaMessage
                        level='error'
                        message="Cette adresse mail est déjà utilisée"
                    />
                    :
                    null
            }   
        </Box>      
    );
    
    
}