
import axios from "axios";


export const CharismaApi = axios.create({
    withCredentials:true,
    baseURL:"https://charisma-formation.fr/api/charisma",
})

const errorHandler = (error)=>{
    if(error.code === "ERR_NETWORK"){
        console.log("Server error");
    }
    const statusCode = error.response ? error.response.status : null;
    console.log(error);
    if (statusCode === 403) {
        window.location.href = '/';
        
    }
    return Promise.reject(error);
}

CharismaApi.interceptors.request.use(function (config) {
    // Do something before request is sent
    return config;
  }, function (error) {
      
    // Do something with request error
    return error;
});
  
CharismaApi.interceptors.response.use(undefined, (error) => {
    return errorHandler(error)
});
