import * as React from 'react';

import Alert from '@mui/material/Alert'
import Button from '@mui/material/Button'
import { CharismaUserApi } from '../api/users/CharismaUserApi.tsx';
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import EmailIcon from '@mui/icons-material/Email';
import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';


import { ValidateEmail } from '../utils/Validator.tsx';

export default function CharismaUserForgotPasswordForm(props){
    
    const [mail, setMail] = React.useState('');
    const [sent, setSent] = React.useState(false);
    const [wait, setWait] = React.useState(false);
    const [error, setError] = React.useState(0);
    const [mailValid, setMailValid] = React.useState(true);

    
    function handleSubmit() {
        let lValidMail: boolean = ValidateEmail(mail);
        setMailValid(lValidMail);
        if(lValidMail){
            let lUserData = CharismaUserApi.ForgotPassword(mail)
            lUserData.then((value)=>{
                if(value.error === 0){
                    setSent(true);
                    setError(0);
                }
                else{
                    setError(value.error);
                }
            }).catch((error)=>{
                console.log("catch error")
            })
        }
    }
    
    return(
        <Dialog
            open={true}
        >
            <DialogTitle>
                {props.title === undefined ?
                    'Mot de passe oublié'
                    :
                    props.title
                }
            </DialogTitle>
            <DialogContent>
                <TextField
                    margin="normal"
                    required
                    error={!mailValid}
                    fullWidth
                    id="email"
                    label="Adresse Mail"
                    name="email"
                    autoComplete="email"
                    autoFocus
                    value={mail}
                    helperText={mailValid ? '' : 'Mail invalide'}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                        setMail(event.target.value);
                        setMailValid(true);
                    }}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <EmailIcon />
                            </InputAdornment>
                        ),
                    }}
                />          
                {sent ?
                    <Alert>Mot de passe envoyé</Alert>
                    :
                    null
                } 
                {
                    error ?
                    <Alert severity='error'>Votre mail ne correspond à aucun utilisateur</Alert>
                    :
                    null
                }
                {
                    wait ?
                    <Alert severity='info'>{"Envoi du mot de passe en cours"}</Alert>
                    :
                    false
                }
            </DialogContent>
            <DialogActions>
                {sent ?
                    <Button onClick={()=>props.onOk()}>OK</Button>
                    :
                    <div>    
                        <Button disabled={wait} onClick={()=>handleSubmit()}>Valider</Button>
                        <Button disabled={wait} onClick={()=>props.onOk()}>Annuler</Button>
                    </div>
                }
            </DialogActions>
        </Dialog>        
    );
}