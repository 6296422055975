import * as React from 'react';


import Box from '@mui/material/Box'



import { CharismaStudentTrainingApi } from '../api/studentTrainings/CharismaStudentTrainingApi.tsx'

import CharismaTrainingListItem from './studentTrainingListItem.tsx';

import List from '@mui/material/List';


import Typography from '@mui/material/Typography';



export default function CharismaStudentTrainingsList(props){
    


    const [studentTrainings, setStudentTrainings] = React.useState([]);
    


    const lStudentId = props.studentId;    

    React.useEffect(()=>{

        
        const lResponseStudentTraining = CharismaStudentTrainingApi.ListTrainings(lStudentId);
        lResponseStudentTraining.then((value)=>{
            if(value.error === 0){
                setStudentTrainings(value.studentTrainings);                
            }
        }).catch((error)=>{
            
        })              
        
    }, [lStudentId])

 
    return(
        
        <Box>
            <Typography variant="h5">{props.title}</Typography>
            <List sx={{display:{xs:'flex', md:'block'}, overflow: 'auto', width:{xs:"300px", md:"100%"}, bgcolor: 'background.paper'}}>
            {studentTrainings.map((training)=>(    
                <div>          
                <CharismaTrainingListItem
                    id={training.id}
                    training={training.training}
                    tutor={training.tutor}
                    financal={training.financal}
                    course={training.course}
                    startDate={training.startDate}
                    endDate={training.endDate}
                    running={training.running}
                    reason={training.reason}
                    subscribe={training.subscribe}
                    selected={props.studentTrainingId === training.id}
                    onSelect={props.onSelect}
                />    
                </div>                    
                ))}
            </List>                       
        </Box>      
    );
    
    
}