import * as React from 'react';


import Box from '@mui/material/Box'


import EditIcon from '@mui/icons-material/Edit'
import Card from'@mui/material/Card'
import CardContent from'@mui/material/CardContent'
import CardHeader from'@mui/material/CardHeader'
import { CharismaFinancalApi } from '../api/financals/CharismaFinancalApi.tsx';
import CharismaImagesList from '../components/imagesList.tsx';
import CharismaFinancalForm from './financalForm.tsx';
import IconButton from '@mui/material/IconButton';





export default function CharismaFinancal(props){
    
    const [name, setName] = React.useState("");
    const [pictures, setPictures] = React.useState([]);
    const [modify, setModify] = React.useState(false);
    const lFinancalId = props.financalId
    
    React.useEffect(()=>{
        const lResponse = CharismaFinancalApi.GetFinancal(lFinancalId);
        lResponse.then((value)=>{
            if(value.error === 0){
                if(value.financal !== null){                   
                    setName(value.financal["name"]);
                }
                
            }
        }).catch((error)=>{
            
        })
        const lResponsePictures = CharismaFinancalApi.ListPictures(lFinancalId);
        lResponsePictures.then((value)=>{
            if(value.error === 0){
                if(value.pictures !== null){                   
                    setPictures(value.pictures);
                }
                
            }
        }).catch((error)=>{
            
        })
    }, [lFinancalId])
    
    
    function handleCancel(){
        setModify(false);
    }
               
  
    function handleUpdate(pName : string, pId : number){
        const lResponse = CharismaFinancalApi.UpdateFinancal(pName, pId);
        lResponse.then((value)=>{
            if(value.error === 0){ 
                if(value.financal !== null){
                    setName(value.financal.name)
                    setModify(false);
                    props.handleUpdate(pName, pId);                 
                }
            }
            else{
                
            }
        }).catch((error)=>{
                console.log("catch error")
        })
    }
        
    function handleAddPicture(pFile : File){
        const lResponse = CharismaFinancalApi.AddPicture(lFinancalId, pFile);
        lResponse.then((value)=>{
            if(value.error === 0){ 
                if(value.pictures !== null){                   
                    setPictures(value.pictures);
                }
            }
            else{                
            }
        }).catch((error)=>{
            console.log("catch error");
        })
    }
    
    function handleDeletePictures(pSelected : string[]){
        const lResponse = CharismaFinancalApi.DeletePictures(pSelected, lFinancalId);
        lResponse.then((value)=>{
            if(value.error === 0){ 
                if(value.pictures !== null){                   
                    setPictures(value.pictures);
                }
            }
            else{                
            }
        }).catch((error)=>{
            console.log("catch error");
        })
    }
        
    return(
        
        <Box>
            <Card>
                <CardHeader
                    title={name}
                    action={
                        <IconButton>
                            <EditIcon
                                onClick={()=>setModify(true)}
                            />
                        </IconButton>
                    } 
                />
                <CardContent>
                    <CharismaImagesList
                        handleAdd={handleAddPicture}
                        handleDelete={handleDeletePictures}
                        pictures={pictures}
                        width={'100px'}
                    />
                </CardContent>
            </Card>  
            {modify ?
                <CharismaFinancalForm
                    title={"Modification"}
                    id={lFinancalId}
                    name={name}
                    handleSubmit={handleUpdate}
                    handleCancel={handleCancel}
                />
                :
                null
            }            
        </Box>      
    );
    
    
}