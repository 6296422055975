import * as React from 'react';


import Box from '@mui/material/Box'
import { CharismaTrainingApi } from '../api/trainings/CharismaTrainingApi.tsx';
import CharismaCoursesList from './coursesList.tsx';
import CharismaTrainingPeriodsList from './trainingPeriodsList.tsx';
import CharismaImagesList from '../components/imagesList.tsx';
import CharismaScheduler from './scheduler.tsx'
import CharismaStudentsList from '../studentTrainings/studentsList.tsx'
import CharismaStudentDocumentsList from './studentDocumentsList.tsx'
import CharismaTrainingDocs from './trainingDocs.tsx'
import CharismaTrainingAssessmentTemplate from './trainingAssessmentTemplate.tsx'
import CharismaTrainigTrashedStudentsList from './trainingTrashedStudentList.tsx'
import { CharismaUserContext } from '../context.tsx';
import {formatDate} from '../utils.tsx'
import Grid from '@mui/material/Grid'
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import { useParams} from 'react-router-dom';



export default function CharismaTraining(props){
    
    let params = useParams();
    const lTrainingId: number =  parseInt(params['trainingId']);
    
    const [tabIndex, setTabIndex] = React.useState(0);
    const [training, setTraining] = React.useState(null);
    const [coursesList, setCoursesList] = React.useState([]);
    const [pictures, setPictures] = React.useState([]);
    const [trashCoursesList, setTrashCoursesList] = React.useState([]);

    

   // const lUsersProfile : number = props.profile === undefined ? -1 : props.profile;
    const lCurrentUser = React.useContext(CharismaUserContext);
    const lReadWrite : boolean = lCurrentUser !== null && lCurrentUser !== undefined && lCurrentUser.profile === 0;
    const lEdit : boolean = lCurrentUser !== null && lCurrentUser !== undefined && lCurrentUser.profile === 1;

    React.useEffect(()=>{
        const lResponse = CharismaTrainingApi.GetTraining(lTrainingId);
        lResponse.then((value)=>{
            if(value.error === 0 && value.training !== null){
                setTraining(value.training);
            }
        }).catch((error)=>{
            
        })
        const lResponseCourses = CharismaTrainingApi.ListCourses(lTrainingId, 0);
        lResponseCourses.then((value)=>{
            if(value.error === 0){
                setCoursesList(value.courses);
                setTrashCoursesList(value.trashCourses);
            }
        }).catch((error)=>{
            
        })
        const lResponsePictures = CharismaTrainingApi.ListPictures(lTrainingId);
        lResponsePictures.then((value)=>{
            if(value.error === 0){
                if(value.pictures !== null){                   
                    setPictures(value.pictures);
                }
                
            }
        }).catch((error)=>{
            
        })

    }, [lTrainingId])    
       
    function handleTrashCourses(pSelectedCourse){
        const lResponse = CharismaTrainingApi.TrashCourses(pSelectedCourse);
        lResponse.then((value)=>{
            if(value.error === 0){
                if(value.ids !== null){
                    const lIds : number[] = value.ids;
                    const lTrashes = trashCoursesList.concat(coursesList.filter((lCourse)=>lIds.indexOf(lCourse.id) !== -1));
                    setTrashCoursesList(lTrashes);
                    const lCoursesList = coursesList.filter((lCourse)=>lIds.indexOf(lCourse.id) === -1);
                    setCoursesList(lCoursesList);
                }
            }
            else{
            
            }
        }).catch((error)=>{
            console.log("catch error")
        })
    }
    
    function handleDeleteCourses(pSelectedCourses){
        const lResponse = CharismaTrainingApi.DeleteCourses(pSelectedCourses);
        lResponse.then((value)=>{
            if(value.error === 0){
                if(value.ids !== null){
                    const lIds = value.ids;
                    let lNewTrashCourses = trashCoursesList.filter((lCourse)=>lIds.indexOf(lCourse.id) === -1);
                    setTrashCoursesList(lNewTrashCourses);                    
                }
;            }
            else{
            
            }
        }).catch((error)=>{
            console.log("catch error")
        })           
    }    
    
    function handleRestoreCourses(pSelecteCourses){
        const lResponse = CharismaTrainingApi.RestoreCourses(pSelecteCourses);
        lResponse.then((value)=>{
            if(value.error === 0){
                if(value.ids !== null){
                    const lIds = value.ids;
                    let lNewCourses = coursesList.concat(trashCoursesList.filter((lCourse)=>lIds.indexOf(lCourse.id) !== -1));
                    let lNewTrashCourses = trashCoursesList.filter((lCourse)=>lIds.indexOf(lCourse.id) === -1);
                    setTrashCoursesList(lNewTrashCourses);
                    setCoursesList(lNewCourses);
                }
            }
            else{
            
            }
        }).catch((error)=>{
            console.log("catch error")
        })           
    }    
    function handleCreateCourse(pName : string, pStartDate : string, pEndDate: string){
        const lResponse = CharismaTrainingApi.CreateCourse(pName, pStartDate, pEndDate, lTrainingId);
        lResponse.then((value)=>{
            if(value.error === 0){
                if(value.course !== null){
                    let lCourses = coursesList.concat(value.course);
                    setCoursesList(lCourses);
                }
                
            }
            else{
                
            }
        }).catch((error)=>{
                console.log("catch error")
        })
    }    
    function handleUpdateCourse(pName : string, pStartDate : string, pEndDate: string, pId : number){
        const lResponse = CharismaTrainingApi.UpdateCourse(pName, pStartDate, pEndDate, pId);
        lResponse.then((value)=>{
            if(value.error === 0){
                if(value.course !== null){
                    const lCourses = coursesList.map((lCourse)=>lCourse.id !== value.course.id ? lCourse : value.course);
                    setCoursesList(lCourses);
                }
                
            }
            else{
                
            }
        }).catch((error)=>{
                console.log("catch error")
        })
    }   
   
    
    function handleAddPicture(pFile : File){
        const lResponse = CharismaTrainingApi.AddPicture(lTrainingId, pFile);
        lResponse.then((value)=>{
            if(value.error === 0){ 
                if(value.pictures !== null){                   
                    setPictures(value.pictures);
                }
            }
            else{                
            }
        }).catch((error)=>{
            console.log("catch error");
        })
    }
    
    function handleDeletePictures(pSelected : string[]){
        const lResponse = CharismaTrainingApi.DeletePictures(pSelected, lTrainingId);
        lResponse.then((value)=>{
            if(value.error === 0){ 
                if(value.pictures !== null){                   
                    setPictures(value.pictures);
                }
            }
            else{                
            }
        }).catch((error)=>{
            console.log("catch error");
        })
    }    
      
  
    function handleTabIndexChange(event: React.SyntheticEvent, newValue: number) {
        setTabIndex(newValue);
    };
    
    function CustomTabPanel(props: TabPanelProps) {
        const { children, value, index, ...other } = props;
    
        return (
            <div
              role="tabpanel"
              hidden={value !== index}
              id={`simple-tabpanel-${index}`}
              aria-labelledby={`simple-tab-${index}`}
              {...other}
            >
              {value === index && (
                <Box>
                  <Typography component={"div"}>{children}</Typography>
                </Box>
              )}
            </div>
        );
    }      
        
    return(
        
        <Box>
            {training !== null?
            <Box>
                <Typography>{training.name}</Typography>
                <Typography>{formatDate(training.startDate) + ' - ' + formatDate(training.endDate)}</Typography>                    
                <Tabs value={tabIndex} onChange={handleTabIndexChange} aria-label="basic tabs example">
                    {lReadWrite ?
                        <Tab label="Parcours" id="tab-index-0"/>
                        :
                        null
                    }
                    {lEdit ?
                        <Tab label="Emploi du temps" id="tab-index-0"/>
                        :
                     lReadWrite ?
                        <Tab label="Emploi du temps" id="tab-index-1"/>
                        :
                        null
                    }
                    {lReadWrite ?
                        <Tab sx={{display:{xs:'none', md:'table-cell'}}} label="Documents" id="tab-index-2"/>
                        :
                        null
                    }
                    {lEdit || lReadWrite ?
                        <Tab sx={{display:{xs:'none', md:'table-cell'}}} label="Documents de Cours" id="tab-index-3"/>
                        :
                        null
                    }
                    {lReadWrite ?
                        <Tab sx={{display:{xs:'none', md:'table-cell'}}} label="Logo" id="tab-index-4"/>
                        :
                        null
                    }
                    {lReadWrite ?
                        <Tab sx={{display:{xs:'none', md:'table-cell'}}} label="Documents d'inscription" id="tab-index-5"/>
                        :
                        null
                    }
                    {lEdit || lReadWrite?
                        <Tab label="Stagiaires" id="tab-index-6"/>
                        :
                        null
                    }
                    {lReadWrite ?
                        <Tab sx={{display:{xs:'none', md:'table-cell'}}} label="Modèles des Bilans" id="tab-index-7"/>
                        :
                        null
                    }
                    {lReadWrite ?
                        <Tab sx={{display:{xs:'none', md:'table-cell'}}} label="Périodes de Formation" id="tab-index-8"/>
                        :
                        null
                    }
                    {lReadWrite ?
                        <Tab sx={{display:{xs:'none', md:'table-cell'}}} label="Stagiaires en corbeilles" id="tab-index-9"/>
                        :
                        null
                    }
                </Tabs>
                
                <CustomTabPanel value={tabIndex} index={lReadWrite ? 0 : null}>
                    <CharismaCoursesList
                        trainingId={training.id}
                        startDate={training.startDate}
                        endDate={training.endDate}
                        handleTrashCourses={handleTrashCourses}
                        handleDeleteCourses={handleDeleteCourses}
                        handleRestoreCourses={handleRestoreCourses}
                        handleCreateCourse={handleCreateCourse}
                        handleUpdateCourse={handleUpdateCourse}              
                    />
                </CustomTabPanel>
                <CustomTabPanel value={tabIndex} index={lReadWrite ? 1 : lEdit ? 0 : null}>
                    <CharismaScheduler
                        courses={coursesList}
                        minDate={training.startDate}
                        maxDate={training.endDate}
                        trainingId={training.id}
                    />
                </CustomTabPanel>
                <CustomTabPanel value={tabIndex} index={lReadWrite ? 2 : null}>
                    <Grid container spacing={4}>
                        <Grid item xs={12} sm={6}>                 
                            <CharismaTrainingDocs
                                trainingId = {training.id}
                                type="book"
                                title="Documents du Livret"
                                accept={true}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>                 
                            <CharismaTrainingDocs
                                trainingId = {training.id}
                                type="tutor"
                                title="Documents des Tuteurs"
                                accept={true}
                            />
                        </Grid>
                    </Grid>
                </CustomTabPanel>
                <CustomTabPanel value={tabIndex} index={lReadWrite ? 3 : lEdit ? 1 : null}>
                    <CharismaTrainingDocs
                        trainingId = {training.id}
                        type="teaching"
                        title="Documents de Cours"
                    />
                </CustomTabPanel>
                <CustomTabPanel value={tabIndex} index={lReadWrite ? 4 : null}>
                <CharismaImagesList
                    handleAdd={handleAddPicture}
                    handleDelete={handleDeletePictures}
                    pictures={pictures}
                />
                </CustomTabPanel>
                <CustomTabPanel value={tabIndex} index={lReadWrite ? 5 : null}>
                <CharismaStudentDocumentsList
                    trainingId = {training.id}
                />
                </CustomTabPanel>
                <CustomTabPanel value={tabIndex} index={lReadWrite ? 6 : lEdit ? 2 : null}>
                <CharismaStudentsList
                    trainingId = {training.id}
                    trainingType = {training.type}
                />
                </CustomTabPanel>
                <CustomTabPanel value={tabIndex} index={lReadWrite ? 7  : null}>
                    <Grid container spacing={4}>
                        <Grid item xs={12} sm={6}>                 
                            <CharismaTrainingAssessmentTemplate
                                trainingId = {training.id}
                                type={0}
                                title="Bilans Quotidiens"
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>                 
                            <CharismaTrainingAssessmentTemplate
                                trainingId = {training.id}
                                type={1}
                                title="Bilans Mensuels"
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>                 
                            <CharismaTrainingAssessmentTemplate
                                trainingId = {training.id}
                                type={2}
                                title="Fiches Navette OF"
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>                 
                            <CharismaTrainingAssessmentTemplate
                                trainingId = {training.id}
                                type={3}
                                title="Fiches Navette Tutorat"
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>                 
                            <CharismaTrainingAssessmentTemplate
                                trainingId = {training.id}
                                type={4}
                                title="Visite Tuteurs"
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>                 
                            <CharismaTrainingAssessmentTemplate
                                trainingId = {training.id}
                                type={5}
                                title="Visite de Régulation"
                            />
                        </Grid>                                         
                    </Grid>
                </CustomTabPanel> 
                <CustomTabPanel value={tabIndex} index={lReadWrite ? 8 : null}>
                <CharismaTrainingPeriodsList
                    trainingId = {training.id}
                    minDate={training.startDate}
                    maxDate={training.endDate}
                />
                </CustomTabPanel>    
                <CustomTabPanel value={tabIndex} index={lReadWrite ? 9 : null}>
                <CharismaTrainigTrashedStudentsList
                    trainingId = {training.id}
                />
                </CustomTabPanel>            
            </Box>           
            :
            null
            }    
        </Box>      
    );
    
    
}