import * as React from 'react';

import Alert from '@mui/material/Alert'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import TextField from '@mui/material/TextField';

import { ValidateDate, ValidateDateRange, ValidateString } from '../utils/Validator.tsx';

export default function CharismaCourseForm(props){
    
    const [subject, setSubject] = React.useState("");
    const [nError, setNError] = React.useState(0);
    const [file, setFile] = React.useState(null);
    const [fError, setFError] = React.useState(0);
    const [fromDate, setFromDate] = React.useState(props.fromDate);
    const [toDate, setToDate] = React.useState(props.toDate);
    const [sError, setSError] = React.useState(0);
    const [dateError, setDateError] = React.useState(0);
    const [eError, setEError] = React.useState(0);
    const [wait, setWait] = React.useState(false);
    
    function handleSubmit(){
        /*
        const lValidName: boolean = ValidateString(subject, 32);
        if(!lValidName){
            setNError(1);
        }*/
        
        let lValidMin: number = ValidateDate(fromDate);
        setSError(lValidMin);

        
        let lValidMax : number = ValidateDate(toDate);
        setEError(lValidMax);
        
        let lValidFile = file !== null;
        if(!lValidFile){
            setFError(1);            
        }
        else{
            setFError(0);
        }
        
        if(lValidMax === 0 && lValidMin === 0 && lValidFile){
            props.handleSubmit(fromDate, toDate, subject, file);
        }

        
    }
    
    return(
        <Dialog
            open={true}
        >
            <DialogTitle>
                {props.title === undefined ?
                    'Modification du parcours'
                    :
                    props.title
                }
            </DialogTitle>
            <DialogContent>

                <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="fromDate"
                    label="Du"
                    name="fromDate"
                    InputLabelProps={{ shrink: true, required: true }}
                    type={'date'}
                    error={sError > 0}
                    helperText={sError === 1 ? 'Date invalide' : null }
                    value={fromDate}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                        setFromDate(event.target.value);
                    }}
                />
                <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="toDate"
                    label="Au"
                    name="toDate"
                    InputLabelProps={{ shrink: true, required: true }}
                    type={'date'}
                    error={eError > 0}
                    helperText={eError === 1 ? 'Date invalide' : null}               
                    value={toDate}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                        setToDate(event.target.value);
                    }}
                />
                <TextField
                    type='file'
                    required
                    fullWidth
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                        setFile(event.target.files[0]);
                        setFError(0);
                    }}
                />                
                {fError === 1 ?
                    <Alert severity="error">{'Aucun justificatif fourni'}</Alert>
                    :
                    null
                }
                {dateError === 1 ?
                    <Alert severity="error">{'La date de début est supérieure à la date de fin'}</Alert>
                    :
                    null
                }
                {wait ?
                    <Alert severity='info'>{props.id === undefined ? "Création en cours" : "Modification en cours"}</Alert>
                    :
                    null
                }
            </DialogContent>
            <DialogActions>
                <Button disabled={wait} onClick={()=>handleSubmit()}>Valider</Button>
                <Button disabled={wait} onClick={()=>props.handleCancel()}>Annuler</Button>
            </DialogActions>
        </Dialog>        
    );
}