import * as React from 'react';

import AppBar from '@mui/material/AppBar'
import Box from '@mui/material/Box'
import CharismaUserMenu from '../user/userMenu.tsx';
import HomeIcon from '@mui/icons-material/Home';
import { Link } from 'react-router-dom';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import IconButton from '@mui/material/IconButton';
import MenuItem from '@mui/material/MenuItem'
import Toolbar from '@mui/material/Toolbar'
import Typography from '@mui/material/Typography';

import { CharismaUserContext } from '../context.tsx';

export default function CharismaAdminMainMenu(props){
    
    const [anchorUserMenu, setAnchorUserMenu] = React.useState(null);
    const openUserMenu: boolean = Boolean(anchorUserMenu)
    
    const [anchorTrainingMenu, setAnchorTrainingMenu] = React.useState(null);
    const openTrainingMenu: boolean = Boolean(anchorTrainingMenu)
    
    const [anchorReportMenu, setAnchorReportMenu] = React.useState(null);
    const openReportMenu: boolean = Boolean(anchorReportMenu);

    const [anchorEvaluationTemplateMenu, setAnchorEvaluationTemplateMenu] = React.useState(null);
    const openEvaluationTemplateMenu: boolean = Boolean(anchorEvaluationTemplateMenu);
    
    const [anchorMobileMenu, setAnchorMobileMenu] = React.useState(null);
    const openMobileMenu: boolean = Boolean(anchorMobileMenu);
    
    const lCurrentUser = React.useContext(CharismaUserContext);
    
    
    function handleOpenUserMenu(event: React.MouseEvent<HTMLElement>){
        setAnchorUserMenu(event.currentTarget);
    };
    
    function handleCloseUserMenu(){
        setAnchorUserMenu(null);
    };
    
    function handleOpenTrainingMenu(event: React.MouseEvent<HTMLElement>){
        setAnchorTrainingMenu(event.currentTarget);
    };
    
    function handleCloseTrainingMenu(){
        setAnchorTrainingMenu(null);
    };

    function handleOpenReportMenu(event: React.MouseEvent<HTMLElement>){
        setAnchorReportMenu(event.currentTarget);
    };
    
    function handleCloseReportMenu(){
        setAnchorReportMenu(null);
    };
    
    function handleOpenEvaluationTemplateMenu(event: React.MouseEvent<HTMLElement>){
        setAnchorEvaluationTemplateMenu(event.currentTarget);
    };
    
    function handleCloseEvaluationTemplateMenu(){
        setAnchorEvaluationTemplateMenu(null);
    };

    function handleOpenMobileMenu(event: React.MouseEvent<HTMLElement>){
        setAnchorMobileMenu(event.currentTarget);
    };
    
    function handleCloseMobileMenu(){
        setAnchorMobileMenu(null);
    };

    return(
        <AppBar position="static" style={{marginBottom:'10px'}}>
            <Toolbar>
                <CharismaUserMenu
                    handleDisconnect={props.handleDisconnect}
                />
                <Typography sx={{ display: { xs: 'none', md: 'flex' } }}>{lCurrentUser.firstName + ' ' + lCurrentUser.lastName}</Typography>
                 <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
                    <MenuItem
                        component={Link}
                        to={'/'}
                    >
                        <HomeIcon/>
                    </MenuItem>
                    <MenuItem
                        onClick={(event)=>handleOpenUserMenu(event)}
                    >
                        Utilisateurs
                    </MenuItem>
                    <MenuItem
                        onClick={(event)=>handleOpenTrainingMenu(event)}
                    >
                        Formations
                    </MenuItem>
                    <MenuItem
                        onClick={(event)=>handleOpenReportMenu(event)}
                    >
                        Suivi
                    </MenuItem>
                    <MenuItem
                        onClick={(event)=>handleOpenEvaluationTemplateMenu(event)}
                    >
                        Evaluations
                    </MenuItem>
                    <MenuItem
                        component={Link}
                        to={'/documents'}
                    >
                        Documents
                    </MenuItem>
                    <img src="/fae.png" width={172} alt="fae" />
                 </Box>       
                 <Box sx={{ display: { xs: 'none', md: 'flex' } }}>          
                 <img src="/qualiopi.png" width={195} alt="qualiopi" />
                 <img src="/Charisma64.png" width={64} alt="charisma" />
                 </Box>
                 <Box sx={{color:'white', flexGrow:1, display: { xs: 'flex', md: 'none' } }}>
                    <img src="/fae.png" height={50} alt="fae" />
                    <img src="/qualiopi.png" height={50} alt="qualiopi" />
                 </Box>   
                 <Box sx={{color:'white', display: { xs: 'flex', md: 'none' } }}>
                    <IconButton color="inherit"
                        onClick={(event)=>handleOpenMobileMenu(event)}
                    >
                        <MenuIcon/>
                    </IconButton>
                 </Box>                  
            
            </Toolbar>
            <Menu
                open={openUserMenu}
                anchorEl={anchorUserMenu}
                onClose={handleCloseUserMenu}
                onClick={handleCloseUserMenu}
            >
                <MenuItem
                    component={Link}
                    to={'/user-documents'}
                >
                    Documents des utilisateurs
                </MenuItem>
                <MenuItem
                    component={Link}
                    to={'/users'}
                >
                    Tous les utlisateurs  
                </MenuItem>
                <MenuItem
                    component={Link}
                    to={'/teachers'}
                >
                    Formateurs  
                </MenuItem>
                <MenuItem
                    component={Link}
                    to={'/tutors'}
                >
                    Tuteurs  
                </MenuItem>  
                <MenuItem
                    component={Link}
                    to={'/students'}
                >
                    Stagiaires  
                </MenuItem>    
                 <MenuItem
                    component={Link}
                    to={'/juries'}
                >
                    Jury  
                </MenuItem>    
                <MenuItem
                    component={Link}
                    to={'/users-trash'}
                >
                    Corbeille  
                </MenuItem>                                            
            </Menu>
            <Menu
                open={openTrainingMenu}
                anchorEl={anchorTrainingMenu}
                onClose={handleCloseTrainingMenu}
                onClick={handleCloseTrainingMenu}
            >
                <MenuItem
                    component={Link}
                    to={'/trainings'}
                >
                    Formations
                </MenuItem>
                <MenuItem
                    component={Link}
                    to={'/trainings-ended'}
                >
                    Formations passées
                </MenuItem>
                <MenuItem
                    component={Link}
                    to={'/financals'}
                >
                    Financements  
                </MenuItem>   
                <MenuItem
                    component={Link}
                    to={'/trainings-trash'}
                >
                    Corbeille  
                </MenuItem>                                            
            </Menu>
            <Menu
                open={openReportMenu}
                anchorEl={anchorReportMenu}
                onClose={handleCloseReportMenu}
                onClick={handleCloseReportMenu}
            >
                <MenuItem
                    component={Link}
                    to={'/daily-assessments'}
                >
                    Bilans Quotidiens
                </MenuItem>
                <MenuItem
                    component={Link}
                    to={'/monthly-assessments'}
                >
                    Bilans Mensuels
                </MenuItem>
                <MenuItem
                    component={Link}
                    to={'/shuttles'}
                >
                    Fiches Navettes
                </MenuItem>
                <MenuItem
                    component={Link}
                    to={'/visits'}
                >
                    Visites Tuteurs / Régulations
                </MenuItem> 
            </Menu>            
            <Menu
                open={openEvaluationTemplateMenu}
                anchorEl={anchorEvaluationTemplateMenu}
                onClose={handleCloseEvaluationTemplateMenu}
                onClick={handleCloseEvaluationTemplateMenu}
            >
                <MenuItem
                    component={Link}
                    to={'/exam-templates'}
                >
                    Examens
                </MenuItem>
                <MenuItem
                    component={Link}
                    to={'/qcm-templates'}
                >
                    QCM
                </MenuItem>
                <MenuItem
                    component={Link}
                    to={'/homework-templates'}
                >
                    Devoirs écrits
                </MenuItem>
                <MenuItem
                    component={Link}
                    to={'/periodic-templates'}
                >
                    Bilans Périodiques AE
                </MenuItem>
            </Menu>      
            <Menu
                open={openMobileMenu}
                anchorEl={anchorMobileMenu}
                onClose={handleCloseMobileMenu}
                onClick={handleCloseMobileMenu}
            >      
                <MenuItem
                    component={Link}
                    to={'/trainings'}
                >
                    Formations
                </MenuItem>
            </Menu>
        </AppBar>  
    );
}