
//check if an email is valid
export function ValidateEmail(pEmail : string) : boolean{
    const expression: RegExp = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
    const result: boolean = expression.test(pEmail);
    return result;
}

export function ValidateString(pString: string, pMaxSize: number){
    const lValue = pString.trim();
    const lLength = lValue.length;
    return lLength > 0 && lLength < pMaxSize;
}

export function ValidatePicture(pPicture: File){
    return pPicture.type.includes('image');
}

export function ValidateFormat(pFile: File, pFormat : string){
    return pFile.type.includes(pFormat);
}

export function ValidateDate(pDate : string) : number{
    let lResult : number = 0;
    if(pDate === undefined || pDate === null){
        lResult = 1;
    }
    else{
        const lDate: Date = new Date(pDate);
        if(isNaN(lDate.getDate())){
            lResult = 1;
        }
    }
    return lResult;
}

export function ValidateDateRange(pStartDate : Date, pEndDate : Date) : number{
    let lResult : number = 0;
    if(pStartDate === null || pEndDate === null){
        return 1;
    }
    if(pStartDate > pEndDate){
        lResult = 1; 
    }   
    
    return lResult;
}