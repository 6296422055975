import * as React from 'react';

import Alert from "@mui/material/Alert"
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'

import TextField from '@mui/material/TextField'


export default function CharismaImportFileForm(props){
    
    const [file, setFile] = React.useState(null);
    const [wait, setWait] = React.useState(false);
    const [error, setError] = React.useState(0);
    
    function handleSubmit(){
        let lValidFile: boolean = file !== null;

        if(!lValidFile){
            setError(1);
        }
        else{
            setWait(true);
            props.handleSubmit(file)
        }
    }
    
    return(
        <Dialog
            open={true}
        >
            <DialogTitle>
                {props.title === undefined ?
                    'Import'
                    :
                    props.title
                }
            </DialogTitle>
            <DialogContent>
                <TextField
                    type='file'
                    required
                    fullWidth
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                        setFile(event.target.files[0]);
                        setError(0);
                    }}
                />
                                
                {error > 0 ?
                    <Alert severity="error">{"Format de fichier invalide"}</Alert>
                    :
                    null
                }
                {wait ?
                    <Alert severity="info">{"Import en cours..."}</Alert>
                    :
                    null
                }
            </DialogContent>
            <DialogActions>
                <Button disabled={wait} onClick={()=>handleSubmit()}>Valider</Button>
                <Button disabled={wait} onClick={()=>props.handleCancel()}>Annuler</Button>
            </DialogActions>
        </Dialog>        
    );
}