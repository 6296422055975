import * as React from 'react';

import Alert from '@mui/material/Alert'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Checkbox from '@mui/material/Checkbox'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import FormControl from '@mui/material/FormControl'
import FormControlLabel from '@mui/material/FormControlLabel'
import FormLabel from '@mui/material/FormLabel'
import FormGroup from '@mui/material/FormGroup'
import Grid from '@mui/material/Grid'
import InputLabel from '@mui/material/InputLabel'
import "dayjs/locale/fr";
import MenuItem from '@mui/material/MenuItem'
import Radio from '@mui/material/Radio'
import RadioGroup from '@mui/material/RadioGroup'
import Select from '@mui/material/Select'
import TextField from '@mui/material/TextField';

import { ValidateDate, ValidateString, ValidateDateRange } from '../utils/Validator.tsx';

export default function CharismaEventForm(props){
    
    const lEvent = props.event;
    const lCourses = [];
    if(lEvent !== null){
        lEvent.courses.forEach((lCourse)=>(
            lCourses.push(lCourse.id)  
        ))
    }
    
    const [place, setPlace] = React.useState(lEvent !== null ? lEvent.place : '');
    const [pError, setPError] = React.useState(0);
    const [description, setDescription] = React.useState(lEvent !== null ? lEvent.description : '');
    const [selectedCourses, setSelectedCourses] = React.useState(lCourses);
    const [cError, setCError] = React.useState(0);
    const [date, setDate] = React.useState(lEvent !== null ? lEvent.date : null);
    const [dError, setDError] = React.useState(0);
    const [frequency, setFrequency] = React.useState(0);
    const [toDate, setToDate] = React.useState(null);
    const [tdError, setTDError] = React.useState(0);
    const [fError, setFError] = React.useState(0);
    const [teacher, setTeacher] = React.useState(lEvent !== null ? lEvent.teacher : '');
    const [tError, setTError] = React.useState(0);
    const [startHour, setStartHour] = React.useState(lEvent !== null ? lEvent.startHour : null);
    const [shError, setSHError] = React.useState(0);
    const [endHour, setEndHour] = React.useState(lEvent !== null ? lEvent.endHour : null);
    const [ehError, setEHError] = React.useState(0);
    const [wait, setWait] = React.useState(false);
    
    function handleSubmit(){
        const lValidPlace: boolean = ValidateString(place, 100);
        let lValid = lValidPlace;
        if(!lValidPlace){
            setPError(1);
        }
        let lValidDate: number = ValidateDate(date);
        lValid = lValid && lValidDate === 0;
        setDError(lValidDate);
        
        if(selectedCourses.length === 0){
            setCError(1);
            lValid = false;
        }
        
        if(lValidDate === 0 && props.minDate !== undefined){
            let lValidMinRange = ValidateDateRange(props.minDate, date);
            lValid = lValid && lValidMinRange === 0;
            if(lValidMinRange !== 0){
                setDError(2);
            }
        }
        if(lValidDate === 0 && props.maxDate !== undefined){
            let lValidMinRange = ValidateDateRange(date, props.maxDate);
            lValid = lValid && lValidMinRange === 0;
            if(lValidMinRange !== 0){
                setDError(3);
            }
        }
        
        if(startHour === null){
            lValid = false;
            setSHError(1);
        }
        else{
            setSHError(0);
        }
        if(endHour === null){
            lValid = false;
            setEHError(1);
        }
        else{
            setEHError(0);
        }
        
        if(frequency !== 0)
        {
            if(frequency === 0){
                setFError(1);
                lValid = false;
            }   
            let lValidToDate = ValidateDate(toDate);
            lValid = lValid && lValidToDate===0;
            setTDError(lValidToDate);
            if(lValidToDate === 0 && props.maxDate !== undefined){
                let lValidMaxRange = ValidateDateRange(toDate, props.maxDate);
                lValid = lValid && lValidMaxRange === 0;
                if(lValidMaxRange !== 0){
                    setTDError(3);
                }
            }
            if(lValidToDate === 0 && lValidDate === 0){
                let lValidRange = ValidateDateRange(date, toDate);
                lValid = lValid &&lValidRange === 0;
                if(lValidRange !== 0){
                    setTDError(2);
                }
            }
            
        }
        if(lValid){
            if(lEvent === null){
                setWait(true);
                props.handleSubmit(place, startHour, endHour, selectedCourses, date, frequency, toDate, teacher, description);
            }
            else{        
                setWait(true);
                props.handleUpdate(place, startHour, endHour, selectedCourses, date, toDate, frequency, teacher, description, lEvent.id, lEvent.groupId);
            }    
        }
    }
    

    
    function handleChangeFrequency(event: React.ChangeEvent<HTMLInputElement>){
        const lValue = parseInt(event.target.value);
        console.log(lValue);
        setFrequency(lValue);
        if(lValue === 0){
            setTDError(0);
        }
    }
    
    function handleAddCourse(pId : number, pEvent: React.ChangeEvent<HTMLInputElement>){
        const lAdd : boolean = pEvent.target.checked;
        let lNewSelected : number[] = [];
        let lIndex = selectedCourses.indexOf(pId);
        if(pEvent.target.checked && lIndex === -1){
            lNewSelected = lNewSelected.concat(selectedCourses, pId);
        }
        else if(lIndex !== -1){
            if(lIndex === 0){
                lNewSelected = lNewSelected.concat(selectedCourses.slice(1));
            }
            else if(lIndex === selectedCourses.length - 1){
                lNewSelected = lNewSelected.concat(selectedCourses.slice(0, -1));
            }
            else{
                lNewSelected = lNewSelected.concat(selectedCourses.slice(0, lIndex), selectedCourses.slice(lIndex + 1),);
            }
        }
        setSelectedCourses(lNewSelected);    
        if(lNewSelected.length ==0){
            setCError(1);
        }    
        else{
            setCError(0);
        }
    }
    
    return(
        <Dialog
            open={true}
            fullWidth={true}
            maxWidth={'lg'}
        >
            <DialogTitle>
                {props.title === undefined ?
                    'Créer un événement'
                    :
                    props.title
                }
            </DialogTitle>
            <DialogContent>
                <Grid container spacing={4}>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            id="name"
                            label={"Lieu ("+ place.length + "/100)"}
                            name="place"
                            autoFocus
                            error={pError > 0}
                            helperText={pError > 0 ? 'Lieu invalide' : null}
                            value={place}
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {                                
                                setPlace(event.target.value);
                                setPError(0);
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <FormControl fullWidth sx={{marginTop:'10px'}}>
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            id="teacher"
                            label={"Intervenant ("+ teacher.length + "/100)"}
                            name="teacher"
                            autoFocus
                            error={tError > 0}
                            helperText={tError > 0 ? 'Intervenant invalide' : null}
                            value={teacher}
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {                                
                                setTeacher(event.target.value);
                            }}
                        />
                        </FormControl>
                    </Grid>
                </Grid>        
                <Grid container spacing={4}>    
                    <Grid item xs={12} sm={6}>      
                        <TextField
                            id="descriptionField"
                            label="Description"
                            multiline
                            fullWidth
                            rows={5}
                            value={description} 
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {                                
                                setDescription(event.target.value);
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Box component="fieldset">
                            <legend>Parcours</legend>
                            <FormControl>
                                <FormGroup>
                                    {props.courses.map((course)=>(
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={selectedCourses.indexOf(course.id) >= 0}
                                                    onClick={
                                                        (event)=>handleAddCourse(course.id, event)
                                                    }
                                                />
                                            }
                                            label={course.name}
                                        />
                                    ))
                                        
                                    }                    
                                </FormGroup>
                            </FormControl>
                            {
                                cError === 1?
                                <Alert severity="error">Aucun parcours n'est sélectionné</Alert>
                                :
                                null
                            }
                        </Box>      
                    </Grid>        
                </Grid>
                <Grid container spacing={4}>
                    <Grid item xs={12} sm={4}>
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            id="date"
                            label="Date"
                            name="date"
                            InputLabelProps={{ shrink: true, required: true }}
                            type={'date'}
                            error={dError > 0}
                            helperText={dError === 1 ? 'Date invalide' : 
                                            dError === 2 ? 'Antérieure à la date de début de formation':
                                                dError === 3 ? 'Postérieure à la date de fin de formation'
                                                :
                                                null
                                        }
                            value={date}
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                setDate(event.target.value);
                            }}
                        />
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            id="startHour"
                            label="Début"
                            name="startHour"
                            InputLabelProps={{ shrink: true, required: true }}
                            type={'time'}
                            error={shError > 0}
                            helperText={shError === 1 ? 'Heure invalide' 
                                        :
                                        null
                                        }
                            value={startHour}
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                setStartHour(event.target.value);
                            }}
                        />
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            id="endHour"
                            label="Fin"
                            name="endHour"
                            InputLabelProps={{ shrink: true, required: true }}
                            type={'time'}
                            error={ehError > 0}
                            helperText={ehError === 1 ? 'Heure invalide' 
                                        :
                                        null
                                        }
                            value={endHour}
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                setEndHour(event.target.value);
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={8}>
                        <FormControl>
                            <FormLabel id="demo-radio-buttons-group-label">{props.modify ? "Modifier" : "Répéter"}</FormLabel>
                            <RadioGroup
                                aria-labelledby="demo-radio-buttons-group-label"
                                defaultValue="female"
                                name="radio-buttons-group"
                                onChange={handleChangeFrequency}
                                value={frequency}
                            >
                                <FormControlLabel value={0} control={<Radio />} label={props.modify?"Cet événement": "Une seule fois"} />
                                <FormControlLabel value={1} control={<Radio />} label={props.modify?"Toutes les occurrences": "Toutes les semaines"} />
                                {
                                    props.modify === undefined || props.modify === false?
                                        <FormControlLabel value={2} control={<Radio />} label="Tous les jours" />
                                        :
                                        null
                                }
                            </RadioGroup>
                        </FormControl>  
                        
                        
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            id="toDate"
                            label="Jusqu'au"
                            InputLabelProps={{ shrink: true}}
                            name="to"
                            type={'date'}
                            disabled={frequency === 0}
                            error={tdError > 0}
                            helperText={tdError === 1  ? 'Date invalide' :
                                            tdError === 2 ? 'Date antérieure à la date de début': 
                                                tdError === 3 ? 'Date postérieure à la date de fin de formation': 
                                            null
                                        }
                            value={toDate}
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                setToDate(event.target.value);
                            }}
                        />                  
                    </Grid>
                </Grid>
                {
                    wait ?
                    <Alert severity="info">{props.id === undefined ? "Création des événements en cours" : "Modifications en cours"}</Alert>
                    :
                    null
                }
            </DialogContent>
            <DialogActions>
                <Button disabled={wait} onClick={()=>handleSubmit()}>Valider</Button>
                <Button disabled={wait} onClick={()=>props.handleCancel()}>Annuler</Button>
            </DialogActions>
        </Dialog>        
    );
}