import * as React from 'react';

import Alert from '@mui/material/Alert'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Checkbox from '@mui/material/Checkbox'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import FormControl from '@mui/material/FormControl'
import FormControlLabel from '@mui/material/FormControlLabel'
import FormLabel from '@mui/material/FormLabel'
import FormGroup from '@mui/material/FormGroup'
import Grid from '@mui/material/Grid'
import InputLabel from '@mui/material/InputLabel'
import "dayjs/locale/fr";
import MenuItem from '@mui/material/MenuItem'
import Radio from '@mui/material/Radio'
import RadioGroup from '@mui/material/RadioGroup'
import Select from '@mui/material/Select'
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

import { ValidateDate, ValidateString, ValidateDateRange } from '../utils/Validator.tsx';
import { formatDate, formatTime } from '../utils.tsx';

export default function CharismaEventFormReadOnly(props){
    
    const lEvent = props.event;
    const lCourses = [];
    if(lEvent !== null){
        lEvent.courses.forEach((lCourse)=>(
            lCourses.push(lCourse.id)  
        ))
    }
    
    const [place, setPlace] = React.useState(lEvent !== null ? lEvent.place : '');
    const [description, setDescription] = React.useState(lEvent !== null ? lEvent.description : '');
    const [date, setDate] = React.useState(lEvent !== null ? lEvent.date : null);

    const [teacher, setTeacher] = React.useState(lEvent !== null ? lEvent.teacher : '');
    const [startHour, setStartHour] = React.useState(lEvent !== null ? lEvent.startHour : null);
    const [endHour, setEndHour] = React.useState(lEvent !== null ? lEvent.endHour : null);
   
    return(
        <Dialog
            open={true}
            fullWidth={true}
            maxWidth={'lg'}
        >
            <DialogTitle>
                <Typography variant='h5'>{place + " le " + formatDate(date)}</Typography>
                <Typography variant='h6'>{"de  " + formatTime(startHour) +  " à " + formatTime(endHour)}</Typography>
                <Typography variant='h6'>{teacher}</Typography>
            </DialogTitle>
            <DialogContent sx={{'padding':6}}>                
                <TextField
                    id="descriptionField"
                    multiline
                    fullWidth
                    disabled
                    rows={5}
                    value={description} 
                />                         
            </DialogContent>
            <DialogActions>
                <Button onClick={()=>props.handleCancel()}>OK</Button>
            </DialogActions>
        </Dialog>        
    );
}