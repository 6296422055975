import * as React from 'react';


import Box from '@mui/material/Box'

import AddIcom from "@mui/icons-material/Add"
import EditIcon from '@mui/icons-material/Edit'
import Alert from '@mui/material/Alert'
import Button from '@mui/material/Button'
import Card from'@mui/material/Card'
import CardContent from'@mui/material/CardContent'
import CardHeader from'@mui/material/CardHeader'
import { CharismaAssessmentTemplateApi } from '../api/assessments/CharismaAssessmentTemplateApi.tsx';
import CharismaAssessmentTemplateForm from './assessmentTemplateForm.tsx';
import CharismaAssessmentTemplateItemForm from './assessmentTemplateItemForm.tsx';
import Checkbox from '@mui/material/Checkbox';
import DeleteIcon from '@mui/icons-material/Delete'
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Toolbar from "@mui/material/Toolbar"
import Typography from '@mui/material/Typography';





export default function CharismaAssessmentTemplate(props){
    
    const [title, setTitle] = React.useState("");
    const [items, setItems] = React.useState([]);
    const [createItem, setCreateItem] = React.useState(false);
    const [currentItem, setCurrentItem] = React.useState(0);
    const [currentItemTitle, setCurrentItemTitle] = React.useState("");
    const [currentItemType, setCurrentItemType] = React.useState(0);
    const [currentItemStat, setCurrentItemStat] = React.useState(0);
    const [deleteItems, setDeleteItems] = React.useState(false);
    const [modify, setModify] = React.useState(false);
    const [selected, setSelected] = React.useState([]);
    const lAssessmentId = props.assessmentId
    
    React.useEffect(()=>{
        const lResponse = CharismaAssessmentTemplateApi.GetAssessmentTemplate(lAssessmentId);
        lResponse.then((value)=>{
            if(value.error === 0){
                if(value.assessmentTemplate !== null){                   
                    setTitle(value.assessmentTemplate["title"]);
                    setItems(value.assessmentTemplate["items"]);
                }
                
            }
        }).catch((error)=>{            
        })

    }, [lAssessmentId])
    
    
    function handleCancel(){
        setModify(false);
    }
               
    function handleUpdate(pTitle : string, pId : number){
        const lResponse = CharismaAssessmentTemplateApi.UpdateAssessmentTemplate(pTitle, pId);
        lResponse.then((value)=>{
            if(value.error === 0){ 
                if(value.assessmentTemplate !== null){
                    setTitle(value.assessmentTemplate.title)
                    setModify(false);
                    props.handleUpdate(pTitle, pId);                 
                }
            }
            else{
                
            }
        }).catch((error)=>{
                console.log("catch error")
        })
    }
        
    function handleAddItem(pItem : string, pType: number, pStat: number){
        const lResponse = CharismaAssessmentTemplateApi.AddAssessmentTemplateItem(lAssessmentId, pItem, pType, pStat);
        lResponse.then((value)=>{
            if(value.error === 0){ 
                if(value.item !== null){
                    let lItems = items.concat(value.item);                   
                    setItems(lItems);
                    setCreateItem(false);
                }
            }
            else{                
            }
        }).catch((error)=>{
            console.log("catch error");
        })
    }
    
    function handleCancelCreateItem(){
        setCreateItem(false);
    }
    
    function handleUpdateItem(pItem : string, pType : number, pStat : number){
        const lResponse = CharismaAssessmentTemplateApi.UpdateAssessmentTemplateItem(currentItem, pItem, pType, pStat);
        lResponse.then((value)=>{
            if(value.error === 0){ 
                if(value.item !== null){
                    const lItem = value.item;
                    let lItems = items.slice();
                    for (let lIndex = 0; lIndex < lItems.length ; lIndex++){
                        if(lItems[lIndex].id === lItem.id){
                            lItems[lIndex].title = lItem.title;
                            lItems[lIndex].type = lItem.type;
                            lItems[lIndex].stat = lItem.stat;
                            break;
                        }
                    }              
                    setItems(lItems);
                    setCurrentItem(0);
                }
            }
            else{                
            }
        }).catch((error)=>{
            console.log("catch error");
        })
    }
    
    function handleCancelUpdateItem(){
        setCurrentItem(0);
    }    
    
    function handleDeleteItems(){
        const lResponse = CharismaAssessmentTemplateApi.DeleteAssessmentTemplateItems(selected);
        lResponse.then((value)=>{
            if(value.error === 0){ 
                if(value.ids !== null){       
                    const lIds = value.ids;
                    const lItems = items.filter((item)=>(lIds.indexOf(item.id) === -1));            
                    setItems(lItems);
                }
            }
            else{                
            }
        }).catch((error)=>{
            console.log("catch error");
        })
    }
    
    
    function handleEditItem(pId : number, pTitle : string, pType:number, pStat:number){
        setCurrentItem(pId);
        setCurrentItemTitle(pTitle);
        setCurrentItemStat(pStat);
        setCurrentItemType(pType);
    }

    function handleSelected(pEvent : React.ChangeEvent<HTMLInputElement>, pId : number){
        let lNewSelected : number[] = [];
        let lIndex = selected.indexOf(pId);
        if(pEvent.target.checked && lIndex === -1){
            lNewSelected = lNewSelected.concat(selected, pId);
        }
        else if(lIndex !== -1){
            if(lIndex === 0){
                lNewSelected = lNewSelected.concat(selected.slice(1));
            }
            else if(lIndex === selected.length - 1){
                lNewSelected = lNewSelected.concat(selected.slice(0, -1));
            }
            else{
                lNewSelected = lNewSelected.concat(selected.slice(0, lIndex), selected.slice(lIndex + 1),);
            }
        }
        setSelected(lNewSelected);
    }        
        
    return(
        
        <Box>
            <Card>
                <CardHeader
                    title={title}
                    action={
                        <IconButton>
                            <EditIcon
                                onClick={()=>setModify(true)}
                            />
                        </IconButton>
                    } 
                />
                <CardContent>
                    <Toolbar>
                        <IconButton onClick={()=>setCreateItem(true)}>
                            <AddIcom/>
                        </IconButton>
                        {selected.length > 0 ?
                        <IconButton onClick={()=>setDeleteItems(true)}>
                            <DeleteIcon/>
                        </IconButton>
                        :
                        null}
                    </Toolbar>
                    <Table>                               
                        <TableHead>
                            <TableRow>
                                <TableCell padding="checkbox">
                                </TableCell>
                                <TableCell>
                                    Nom
                                </TableCell>
                                <TableCell>
                                    Type
                                </TableCell>
                                <TableCell>
                                    Evalué
                                </TableCell>
                                <TableCell padding="checkbox">
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                        {
                            items.map((item)=>(
                                <TableRow>
                                    <TableCell>
                                        <Checkbox
                                            checked={selected.indexOf(item.id) !== -1}
                                            onClick={(event)=>handleSelected(event, item.id)}
                                        />
                                    </TableCell>
                                    <TableCell>
                                        {item.title}
                                    </TableCell>
                                    <TableCell>
                                        {item.type === 0 ? "Evaluation" : "Commentaire"}
                                    </TableCell>
                                    <TableCell>
                                       <Checkbox
                                        disabled={true}
                                        checked={item.stat === 1}
                                       />
                                    </TableCell>
                                    <TableCell padding="checkbox">
                                        <IconButton
                                            onClick={()=>handleEditItem(item.id, item.title, item.type, item.stat)}
                                        >
                                            <EditIcon/>
                                        </IconButton>
                                    </TableCell>
                                </TableRow>     
                            ))
                        }
                        </TableBody>
                    </Table>
                </CardContent>
            </Card>  
            {modify ?
                <CharismaAssessmentTemplateForm
                    title={"Modification"}
                    id={lAssessmentId}
                    name={title}
                    handleSubmit={handleUpdate}
                    handleCancel={handleCancel}
                />
                :
                null
            }
            {createItem ?
                <CharismaAssessmentTemplateItemForm
                    title={"Ajout d'un item"}                    
                    handleSubmit={handleAddItem}
                    handleCancel={handleCancelCreateItem}
                />
                :
                null
            }
            {currentItem > 0 ?
                <CharismaAssessmentTemplateItemForm
                    title={"Modification d'un item"}
                    name={currentItemTitle}     
                    type={currentItemType}
                    stat={currentItemStat}               
                    handleSubmit={handleUpdateItem}
                    handleCancel={handleCancelUpdateItem}
                />
                :
                null
            }
            <Dialog
                open={deleteItems}
            >
                <DialogTitle>Suppression d'items</DialogTitle>
                <DialogContent>
                    <div>
                        <Typography>Etes-vous sûr de vouloir supprimer définitivement les items sélectionnés?</Typography>
                        <Alert severity='warning'>Cette action sera définitive</Alert>
                    </div>
                    
                </DialogContent>
                <DialogActions>
                    <Button autoFocus onClick={()=>setDeleteItems(false)}>
                        Annuler
                    </Button>
                    <Button onClick={()=>{handleDeleteItems();setDeleteItems(false)}}>
                        Valider
                    </Button>
                </DialogActions>
            </Dialog> 
        </Box>      
    );
    
    
}