import * as React from 'react';


import Box from '@mui/material/Box'
import CharismaUserDocumentsList from './userDocumentsList.tsx';
import Grid from '@mui/material/Grid'
import { useParams} from 'react-router-dom';


export default function CharismaUserDocument(props){
 
    return(
        
        <Box>
            
            <Grid container spacing={4}>
                <Grid item xs={12} sm={6}>
                    <CharismaUserDocumentsList
                        title={'Type de documents Formateurs'}
                        profile={1}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <CharismaUserDocumentsList
                        title={'Type de documents Tuteurs'}
                        profile={2}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <CharismaUserDocumentsList
                        title={'Type de documents Stagiaires'}
                        profile={3}
                    />
                </Grid>
            </Grid>
        </Box>      
    );
    
    
}