import * as React from 'react';


import Box from '@mui/material/Box'


import { CharismaTrainingApi } from '../api/trainings/CharismaTrainingApi.tsx';
import { CharismaEvaluationTemplateApi } from '../api/evaluations/CharismaEvaluationTemplateApi.tsx';
import CharismaStudentTrainingPeriod from './studentTrainingPeriod.tsx'

import { CharismaUserContext } from '../context.tsx';


export default function CharismaStudentTrainingBoard(props){
    


    const [periods, setPeriods] = React.useState([]);
    const [exams, setExams] = React.useState([]);
    const [tutorExams, setTutorExams] = React.useState([]);
    const [qcms, setQcms] = React.useState([]);
    const [homeworks, setHomeworks] = React.useState([]);
    const [periodReportsOf, setPeriodReportsOf] = React.useState([]);
    const [periodReportsTutor, setPeriodReportsTutor] = React.useState([]);
   
    const lCurrentUser = React.useContext(CharismaUserContext);
    //const lReadWrite = lCurrentUser !== null && lCurrentUser !== undefined && lCurrentUser.profile === 0;
    const lStudentTrainingId = props.studentTrainingId;
    const lTrainingId = props.trainingId;
    const lStudentId = props.studentId;   
    const lTrainingType = props.trainingType;


    React.useEffect(()=>{
        const lResponsePeriods = CharismaTrainingApi.ListTrainingPeriods(lTrainingId)
        lResponsePeriods.then((value)=>{
            if(value.error === 0){
                setPeriods(value.trainingPeriods);
            }
        }).catch((error)=>{
            
        })
    }, [lTrainingId])
    
    
    React.useEffect(()=>{
        if(lCurrentUser.profile === 0){
            const lResponseExams = CharismaEvaluationTemplateApi.ListEvaluationTemplatesByProfile(0, 1);
            lResponseExams.then((value)=>{
                if(value.error === 0){
                    if(value.evaluationTemplates !== null){
                        setExams(value.evaluationTemplates);
                    }
                    
            }
            }).catch((error)=>{
            
            })
            const lResponseTutorExams = CharismaEvaluationTemplateApi.ListEvaluationTemplatesByProfile(0, 2);
            lResponseTutorExams.then((value)=>{
                if(value.error === 0){
                    if(value.evaluationTemplates !== null){
                        setTutorExams(value.evaluationTemplates);
                    }
                    
            }
            }).catch((error)=>{
            
            })
            if(lTrainingType === 2){
                const lResponsePeriodsOf = CharismaEvaluationTemplateApi.ListEvaluationTemplatesByProfile(3, 1);
                lResponsePeriodsOf.then((value)=>{
                    if(value.error === 0){
                        if(value.evaluationTemplates !== null){
                            setPeriodReportsOf(value.evaluationTemplates);
                        }
                        
                }
                }).catch((error)=>{
                
                })
                const lResponsePeriodsTutor = CharismaEvaluationTemplateApi.ListEvaluationTemplatesByProfile(3, 2);
                lResponsePeriodsTutor.then((value)=>{
                    if(value.error === 0){
                        if(value.evaluationTemplates !== null){
                            setPeriodReportsTutor(value.evaluationTemplates);
                        }
                        
                }
                }).catch((error)=>{
                
                }) 
            }
            const lResponseQcms = CharismaEvaluationTemplateApi.ListEvaluationTemplates(1);
            lResponseQcms.then((value)=>{
                if(value.error === 0){
                    if(value.evaluationTemplates !== null){
                        setQcms(value.evaluationTemplates);
                    }
                    
                }
            }).catch((error)=>{
                
            })
            
            const lResponseHomeworks = CharismaEvaluationTemplateApi.ListEvaluationTemplates(2);
            lResponseHomeworks.then((value)=>{
                if(value.error === 0){
                    if(value.evaluationTemplates !== null){
                        setHomeworks(value.evaluationTemplates);
                    }
                    
                }
            }).catch((error)=>{
                
            })
        }
    },[lTrainingType, lCurrentUser.profile])
    
    
    return(
        
        <Box>   
            {
                periods.map((period)=>(
                    <CharismaStudentTrainingPeriod
                        periodId={period.id}
                        studentTrainingId={lStudentTrainingId}
                        trainingId={lTrainingId}
                        studentId={lStudentId}
                        exams={exams}
                        tutorExams={tutorExams}
                        qcms={qcms}
                        homeworks={homeworks}
                        periodReportsOf={periodReportsOf}
                        periodReportsTutor={periodReportsTutor}
                        trainingType={lTrainingType}
                    />
                ))
            }                
        </Box>      
    );
    
    
}