import * as React from 'react';


import Box from '@mui/material/Box'

import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import CardHeader from '@mui/material/CardHeader';
import { CharismaUserApi } from '../api/users/CharismaUserApi.tsx';
import { CharismaTrainingApi } from '../api/trainings/CharismaTrainingApi.tsx';
import { CharismaFinancalApi } from '../api/financals/CharismaFinancalApi.tsx';
import { CharismaStudentTrainingApi } from '../api/studentTrainings/CharismaStudentTrainingApi.tsx'
import CharismaTutorStudentsListItem from './tutorStudentsListItem.tsx';
import CharismaStudentTrainingSpace from '../studentTrainings/studentTrainingSpace.tsx'
import List from '@mui/material/List'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography';


export default function CharismaTutorDetails(props){
    
    const [studentTrainingId, setStudentTrainingId] = React.useState(0)
    const [studentTrainings, setStudentTrainings] = React.useState([]);
    const [trainingId, setTrainingId] = React.useState(0)
    const [tutors, setTutors] = React.useState([]);
    const [financals, setFinancals] = React.useState([]);
    const [courses, setCourses] = React.useState([]);
    
    React.useEffect(()=>{
        const lResponseTutor = CharismaUserApi.List(2, 0);
        lResponseTutor.then((value)=>{
            if(value.error === 0){
                setTutors(value.users);
            }
        }).catch((error)=>{
            
        })
        
        const lResponseFinancals = CharismaFinancalApi.ListFinancals();
        lResponseFinancals.then((value)=>{
            if(value.error === 0){
                setFinancals(value.financals);
            }
        }).catch((error)=>{
            
        })
                       
    },[]) 
    
    React.useEffect(()=>{    
    
        const lResponseStudentTraining = CharismaStudentTrainingApi.ListStudentsForTutor(props.tutorId);
        lResponseStudentTraining.then((value)=>{
            if(value.error === 0){
                setStudentTrainings(value.studentTrainings);                
            }
        }).catch((error)=>{
            
        })   
    },[props.tutorId])
    
    React.useEffect(()=>{
        const lResponseCourses = CharismaTrainingApi.ListCourses(trainingId, 0);
        lResponseCourses.then((value)=>{
            if(value.error === 0){
                setCourses(value.courses);
            }
        }).catch((error)=>{
            
        })
                       
    },[trainingId]) 
           
    
    function handleSelectTraining(pTrainingId: number, pStudentTrainingId : number){
        setStudentTrainingId(pStudentTrainingId);
        setTrainingId(pTrainingId);
    }
    
    function handleUpdate(pStudentTraining){
        let lTrainingStudents = studentTrainings.slice();
        for (let lIndex = 0; lIndex < lTrainingStudents.length; lIndex++){
            const lId = lTrainingStudents[lIndex].id;
            if(lId === pStudentTraining.id){
                lTrainingStudents[lIndex] = pStudentTraining;
                break;
            }
        }
        setStudentTrainings(lTrainingStudents);    
    }

    
    return(
        <Box>
            <Card>
                <CardHeader
                    title="Stagiaires" 
                />
                
                <CardContent>
                    <Grid container>
                        <Grid item xs={12} md={2}>
                            <Box>
                                <Typography variant="h5">{props.title}</Typography>
                                <List sx={{display:{xs:'flex', md:'block'}, overflow: 'auto', width:{xs:"300px", md:"100%"}, bgcolor: 'background.paper'}}>
                                    {studentTrainings.map((training)=>(    
                                        <div>          
                                        <CharismaTutorStudentsListItem
                                            id={training.id}
                                            training={training.training}
                                            student={training.student}
                                            financal={training.financal}
                                            course={training.course}
                                            startDate={training.startDate}
                                            endDate={training.endDate}
                                            running={training.running}
                                            reason={training.reason}
                                            subscribe={training.subscribe}
                                            selected={studentTrainingId === training.id}
                                            onSelect={handleSelectTraining}
                                    />    
                                    </div>                    
                                    ))}
                                </List>                       
                            </Box>   
                        </Grid>
                        <Grid item xs={12} md={10}>
                            {studentTrainingId > 0 ?                            
                                <CharismaStudentTrainingSpace
                                    studentTrainingId={studentTrainingId}
                                    tutors={tutors}
                                    financals={financals}
                                    courses={courses}
                                    update={handleUpdate}
                                />
                                :
                                null
                            }
                        </Grid>
                    </Grid>
            
                </CardContent>                                 
            </Card>
        </Box>      
    );
    
}