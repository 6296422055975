import * as React from 'react';

import Box from '@mui/material/Box'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import CardHeader from '@mui/material/CardHeader';
import { CharismaUserApi } from '../api/users/CharismaUserApi.tsx';
import CharismaUserForm  from './userForm.tsx';
import EditIcon from '@mui/icons-material/Edit'
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';



import { CharismaUserContext } from '../context.tsx';

export default function CharismaUserInfo(props){
    
    const lUser = props.user;
    const [update, setUpdate] = React.useState(false);
    const [mail, setMail] = React.useState(lUser.mail);
    const [phone, setPhone] = React.useState(lUser.phone);
    const [firstName, setFirstName] = React.useState(lUser.firstName);
    const [lastName, setLastName] = React.useState(lUser.lastName);
    

    const lCurrentUser = React.useContext(CharismaUserContext);
    
    function handleCancel(){
        setUpdate(false);
    }
    
    
    function handleSubmit(pFName : string, pLName : string, pMail: string, pPhone : string){
        const lResponse = CharismaUserApi.UpdateUser(pFName, pLName, pMail, pPhone, lUser.id);
        lResponse.then((value)=>{
            if(value.error === 0){
                setUpdate(false);
                if(value.user !== undefined && value.user !== null){
                    setFirstName(value.user.firstName);
                    setLastName(value.user.lastName);
                    setMail(value.user.mail);
                    setPhone(value.user.phone);
                }
            }
            else{
                
            }
        }).catch((error)=>{
                console.log("catch error")
        })
    }
    
    return(
        <Box>
            <Card>
                <CardHeader
                    action={
                        lCurrentUser.id === lUser.id || lCurrentUser.profile === 0 ?
                        <IconButton onClick={()=>setUpdate(true)}>
                            <EditIcon/>
                        </IconButton>
                        :
                        null
                    }  
                    title={lCurrentUser.id === lUser.id ? 'Mes Coordonnées' : 'Coordonnées'} 
                />
                <CardContent>
                    <Box>
                        <Typography>{firstName + ' ' + lastName}</Typography>
                        <Typography>{mail}</Typography>
                        <Typography>{phone}</Typography>  
                    </Box>        
                </CardContent>
            </Card>
            {update ?
                <CharismaUserForm
                    firstName = {firstName}
                    lastName = {lastName}
                    mail = {mail}
                    phone = {phone}
                    handleSubmit={handleSubmit}
                    handleCancel={handleCancel}
                />
                :
                null    
            }       
        </Box>      
    );
    
    
}