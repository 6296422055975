import * as React from 'react';


import Card from '@mui/material/Card'
import CardHeader from '@mui/material/CardHeader'
import CardContent from '@mui/material/CardContent'
import CharismaStudentTrainingEvaluationFormItem from './studentTrainingEvaluationFormItem.tsx'
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import TextField from '@mui/material/TextField';




export default function CharismaStudentTrainingEvaluationFormSection(props){

           
    const [comment, setComment] = React.useState(props.section.comment);
    
   function handleSetComment(pValue :  string){
       setComment(pValue);
       
   }
   
   function handleBlur(){
       props.handleSetComment(comment, props.section.id, "comment");
   }
    
    function createItemsTable(pItems){
        return(
            <Table>
                <TableBody>
                    {
                        pItems.map((item)=>(
                            <TableRow>
                                <TableCell>
                                    {item.title}
                                </TableCell>
                                <TableCell>
                                    <CharismaStudentTrainingEvaluationFormItem
                                        item = {item}
                                        handleSetValue = {props.handleChangeNote}
                                        readOnly = {props.readOnly}
                                    />
                                </TableCell>
                            </TableRow>  
                        ))
                    }
                    <TableRow>
                        <TableCell colSpan={2}>
                            {createSectionComment()}
                        </TableCell>
                    </TableRow>
                </TableBody>
            </Table>
        )
    }
        
    
    function createSectionComment(){
        return(
            <TextField
                id="partComment"
                name="partComment"
                fullWidth
                multiline
                label="Commentaire"
                value={comment}
                rows={5}    
                disabled={props.readOnly}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    handleSetComment(event.target.value);
                }}     
                onBlur={()=>handleBlur()}   
            />
            
        );
    }
                                   
    return(
            <Card>
                <CardHeader
                    title={props.section.title}
                    subheader={props.section.info}
                />
                <CardContent>
                    {createItemsTable(props.section.items)}
                </CardContent>
            </Card>      
    );
}