import * as React from 'react';

import TextField from '@mui/material/TextField'



export default function CharismaStudentTrainingEvaluationFormItems(props){
    
    const [studentComment, setStudentComment] = React.useState(props.studentComment);
    
        
    function handleBlur(){
        props.handleChangeComment(studentComment, props.itemId, "studentComment");
    }
    
    function handleChange(pValue : string){
        setStudentComment(pValue);
    }
  

                                              
    return(
            <TextField
                id="comment"
                name="comment"
                fullWidth
                multiline
                label="Réponse"
                value={studentComment}
                rows={5}    
                disabled={props.readOnly}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    handleChange(event.target.value);
                }}        
                onBlur={()=>handleBlur()}
            />   
    );
}