import * as React from 'react';

import Alert from '@mui/material/Alert'
import Button from '@mui/material/Button'
import Checkbox from '@mui/material/Checkbox';
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import FormControl from '@mui/material/FormControl'
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select'
import TextField from '@mui/material/TextField';

import { ValidateDate, ValidateDateRange } from '../utils/Validator.tsx';

export default function CharismaEvaluationForm(props){
    
    const [selectedCourses, setSelectedCourses] = React.useState([]);
    const [cError, setCError] = React.useState(0);    
    const [date, setDate] = React.useState(props.date);
    const [type, setType] = React.useState(props.evals.length > 0 ? props.evals[0].id : 0); 
    const [error, setError] = React.useState(0);
    const [wait, setWait] = React.useState(false);

    function handleSubmit(){
                
        let lValid: number = ValidateDate(date);
        let lValidMin = 0;
        let lValidMax = 0;
        let lValidCourse = 0
        if(lValid === 0 ){             
            if(props.minDate !== undefined){           
                lValidMin = ValidateDateRange(props.minDate, date);
                if(lValidMin === 1){
                    setError(2);
                }
            }            
            if(props.maxDate !== undefined){           
                lValidMax = ValidateDateRange(date, props.maxDate);
                if(lValidMin === 1){
                    setError(3);
                }
            }
            if(props.courses !== undefined && selectedCourses.length === 0){
                lValidCourse = 1;
                setCError(1);
            }
        }            
                    
        else{
            setError(1);
        }
         
        if(lValid === 0 && lValidMax === 0 && lValidMin === 0 && lValidCourse === 0){    
            setWait(true);        
            props.handleSubmit(date, type, selectedCourses);
        }
    }
    
    function handleAddCourse(pId : number, pEvent: React.ChangeEvent<HTMLInputElement>){
        const lAdd : boolean = pEvent.target.checked;
        let lNewSelected : number[] = [];
        let lIndex = selectedCourses.indexOf(pId);
        if(pEvent.target.checked && lIndex === -1){
            lNewSelected = lNewSelected.concat(selectedCourses, pId);
        }
        else if(lIndex !== -1){
            if(lIndex === 0){
                lNewSelected = lNewSelected.concat(selectedCourses.slice(1));
            }
            else if(lIndex === selectedCourses.length - 1){
                lNewSelected = lNewSelected.concat(selectedCourses.slice(0, -1));
            }
            else{
                lNewSelected = lNewSelected.concat(selectedCourses.slice(0, lIndex), selectedCourses.slice(lIndex + 1),);
            }
        }
        setSelectedCourses(lNewSelected);     
        if(lNewSelected.length > 0){
            setCError(0);
        }       
    }     
    

    
    return(
        <Dialog
            open={true}
        >
            <DialogTitle>
                {props.title !== undefined ?                    
                    props.title
                    :
                    null
                }
            </DialogTitle>
            <DialogContent>
                <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="date"
                    label="Date du bilan"
                    name="date"
                    InputLabelProps={{ shrink: true, required: true }}
                    type={'date'}
                    error={error > 0}
                    helperText={error === 1 ? 'Date antérieure au début de formation' : error===2 ? 'Date postérieure à la fin de formation' : null}
                    value={date}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                        setDate(event.target.value);
                    }}
                />      
                <FormControl fullWidth>
                <InputLabel id="label">Evaluation</InputLabel>
                    <Select
                        labelId="label"
                        id="type-select"
                        value={type}
                        label="Evaluation"
                        onChange={(event)=>setType(event.target.value)}
                    >
                    {
                        props.evals.map((lEval)=>(
                            <MenuItem value={lEval.id}>{lEval.title}</MenuItem> 
                        ))
                    }
                  </Select>
                </FormControl>
                {props.courses !== undefined ?
                    <FormControl>
                        <FormGroup>
                            {props.courses.map((course)=>(
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={selectedCourses.indexOf(course.id) >= 0}
                                            onClick={
                                                (event)=>handleAddCourse(course.id, event)
                                            }
                                        />
                                    }
                                    label={course.name}
                                />
                            ))
                                
                            }                    
                        </FormGroup>
                    </FormControl>   
                    :
                    null
                }               
                {wait ?
                    <Alert severity="info">{"Ajout de l'évaluation en cours"}</Alert>
                    :
                    null
                }   
                {cError === 1 ?
                    <Alert severity="error">{"Aucun parcours sélectionné"}</Alert>
                    :
                    null
                }                        
            </DialogContent>
            <DialogActions>
                <Button disabled={wait} onClick={()=>handleSubmit()}>Valider</Button>
                <Button disabled={wait} onClick={()=>props.handleCancel()}>Annuler</Button>
            </DialogActions>
        </Dialog>        
    );
}