import * as React from 'react';

import Alert from '@mui/material/Alert'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import FormControl from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select'



export default function CharismaTrainingAssessmentForm(props){
    
    const [template, setTemplate] = React.useState(props.templates !== undefined && props.templates.length > 0 ? props.templates[0].id : 0);
    const [templates, setTemplates] = React.useState([]);
    const [wait, setWait] = React.useState(false);

    React.useEffect(()=>{
        setTemplates(props.templates);    
    }, [props.templates])
    
    function submit(){
        setWait(true);
        props.handleSubmit(template);
    }
    
    return(
        <Dialog
            open={true}
        >
            <DialogTitle>
                {props.title === undefined ?
                    'Ajout d\'un modèle'
                    :
                    props.title
                }
            </DialogTitle>
            <DialogContent>
                <FormControl fullWidth>
                <InputLabel id="label">Modèle</InputLabel>
                    <Select
                        labelId="label"
                        id="type-select"                        
                        value={template}
                        label="Type de Formation"
                        onChange={(event)=>setTemplate(event.target.value)}
                    >
                    {templates.map((template)=>(
                        <MenuItem value={template.id}>{template.title}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
                {wait ?
                    <Alert severity="info">Ajout du modèle en cours</Alert>
                    :
                    null
                }             
            </DialogContent>
            <DialogActions>
                <Button disabled={wait} onClick={()=>submit()}>Valider</Button>
                <Button disabled={wait} onClick={()=>props.handleCancel()}>Annuler</Button>
            </DialogActions>
        </Dialog>        
    );
}