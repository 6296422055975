import * as React from 'react';

import Button from '@mui/material/Button'
import Checkbox from '@mui/material/Checkbox'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import { Link } from 'react-router-dom';
import Table from '@mui/material/Table'
import TableHead from '@mui/material/TableHead'
import TableBody from '@mui/material/TableBody'
import TableRow from '@mui/material/TableRow'
import TableCell from '@mui/material/TableCell'
import Typography from '@mui/material/Typography'



export default function CharismaTutorDocsToAcceptForm(props){
    
   
    const [validatedDocs, setValidatedDocs] = React.useState([]);


    function handleAccept(pEvent : React.ChangeEvent<HTMLInputElement>, pId : number){
        let lNewSelected : number[] = [];
        let lIndex = validatedDocs.indexOf(pId);
        if(pEvent.target.checked && lIndex === -1){
            lNewSelected = lNewSelected.concat(validatedDocs, pId);
        }
        else if(lIndex !== -1){
            if(lIndex === 0){
                lNewSelected = lNewSelected.concat(validatedDocs.slice(1));
            }
            else if(lIndex === validatedDocs.length - 1){
                lNewSelected = lNewSelected.concat(validatedDocs.slice(0, -1));
            }
            else{
                lNewSelected = lNewSelected.concat(validatedDocs.slice(0, lIndex), validatedDocs.slice(lIndex + 1),);
            }
        }
        setValidatedDocs(lNewSelected);        
    }
    
    function handleSubmit(){
        props.handleSubmit();
    }
    
    return(
        <Dialog
            open={true}
        >
            <DialogTitle>
                Documents à lire et accepter
            </DialogTitle>
            <DialogContent>
                <Typography>
                Vous êtes tuteur de {props.student} pour la formation {props.training}. A ce titre vous êtes tenus de lire et accepter les documents suivants:
                </Typography>
                <Table>
                    <TableHead>
                        <TableRow key="title">
                            <TableCell>
                                Documents
                            </TableCell>
                            <TableCell padding="checkbox">
                                Accepté
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {props.docs.map((lDoc)=>(
                            <TableRow>
                                <TableCell>
                                    <Link to={lDoc.url} target="_blank" rel="noopener noreferrer" >
                                        {lDoc.name}
                                    </Link>
                                </TableCell>
                                <TableCell>
                                    <Checkbox
                                        checked={validatedDocs.indexOf(lDoc.id) !== -1}
                                        onChange={(event)=>handleAccept(event, lDoc.id)}
                                    />
                                </TableCell>
                            </TableRow>
                            ))
                        }
                    </TableBody>
                </Table>          
                
            </DialogContent>
            <DialogActions>
                <Button disabled={validatedDocs.length !== props.docs.length} onClick={()=>handleSubmit()}>OK</Button>
            </DialogActions>
        </Dialog>        
    );
}