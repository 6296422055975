import * as React from 'react';

import Alert from '@mui/material/Alert'
import Autocomplete from '@mui/material/Autocomplete'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import FormControl from '@mui/material/FormControl'
import FormHelperText from '@mui/material/FormHelperText';
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import Select from '@mui/material/Select'
import TextField from '@mui/material/TextField';
import { ValidateDate } from '../utils/Validator.tsx';


export default function CharismaStudentTrainingForm(props){
    
    const [student, setStudent] = React.useState(null);
    const [studentError, setStudentError] = React.useState(0);
    const [course, setCourse] = React.useState(props.course !== undefined ? props.course  : 0);
    const [courseError, setCourseError] = React.useState(0);
    const [tutor, setTutor] = React.useState(props.tutor !== undefined ? props.tutor : null);
    const [tutorError, setTutorError] = React.useState(0);
    const [financal, setFinancal] = React.useState(props.financal !== undefined ? props.financal  : 0);
    const [startDate, setStartDate] = React.useState(props.startDate); 
    const [sError, setSError] = React.useState(0);
    const [endDate, setEndDate] = React.useState(props.endDate);     
    const [eError, setEError] = React.useState(0);
    const [reason, setReason] = React.useState(props.reason);
    const [wait, setWait] = React.useState(false);
    
    function handleSubmit(){

        let lValid :boolean = student !== null || props.id !== undefined;
        if(student === null && props.id===undefined){
            setStudentError(1);
        }
        
        lValid = lValid && course !== 0;
        if(course === 0){
            setCourseError(1);
        }
                       
        lValid = lValid;// && tutor !== null;
        let tutorId = null;
        if(tutor !== null){
            tutorId = tutor.id;
           // setTutorError(1);
        }
        
        if(reason > 0){
            let lValidDate = ValidateDate(endDate);
            lValid = lValid && lValidDate === 0;
            setEError(lValidDate);
        }
        
        if(lValid){
            if(props.id === undefined){
                setWait(true);
                props.handleSubmit(student.id, tutorId, course,financal, startDate);
            }
            else{
                setWait(true);
                props.handleSubmit(tutor.id, course,financal, startDate, endDate, reason);
            }
        }
    }

    React.useEffect(()=>{
        for(let lIndex = 0; lIndex < props.courses.length; lIndex++){
            if(props.courses[lIndex].id === course){
                if(startDate === undefined){
                    setStartDate(props.courses[lIndex].startDate);
                }
                if(endDate === undefined){
                    setEndDate(props.courses[lIndex].endDate);
                }
            }
        }
        
    }, [course, props.courses])   
    
    
    return(
        <Dialog
            open={true}
        >
            <DialogTitle>
                {props.title === undefined ?
                    'Modification des coordonnées'
                    :
                    props.title
                }
            </DialogTitle>
            <DialogContent>         
                {props.id === undefined ?
                <Autocomplete
                    freeSolo
                    id="free-solo-2-demo"
                    disableClearable
                    value={student}
                    options={props.students}
                    getOptionLabel={(option) => (option.firstName + " " + option.lastName)}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Stagiaire"
                        error={studentError > 0}
                        helperText={studentError > 0 ? "Stagiaire invalide" : ''}
                        InputProps={{
                          ...params.InputProps,
                          type: 'search',
                        }}
                      />
                    )}
                    onChange={(event, newValue) => {
                        setStudent(newValue);
                        setStudentError(0);
                    }}
                    sx={{mt:'10px'}}
                />    
                :
                null
                }                               
                <FormControl fullWidth sx={{mt:'10px'}}>
                <InputLabel id="label">Parcours</InputLabel>
                    <Select
                        labelId="label"
                        id="type-select"                        
                        value={course}
                        label="Parcours"

                        onChange={(event)=>{setCourse(event.target.value); setCourseError(0)}}
                    >
                    {
                        props.courses.map((course)=>(
                            <MenuItem key = {course.id} value={course.id}>{course.name}</MenuItem>  
                        ))
                    }
                  </Select>
                  {courseError > 0 ?
                      <FormHelperText error>Parcours invalide</FormHelperText>
                      :
                      null
                  }
                </FormControl>
                <FormControl fullWidth sx={{mt:'10px'}}>
                <InputLabel id="label">Financement</InputLabel>
                    <Select
                        labelId="label"
                        id="type-select"                        
                        value={financal}
                        label="Financement"

                        onChange={(event)=>setFinancal(event.target.value)}
                    >
                    {
                        props.financals.map((financal)=>(
                            <MenuItem key = {financal.id} value={financal.id}>{financal.name}</MenuItem>  
                        ))
                    }
                  </Select>
                </FormControl>
                <Autocomplete
                    freeSolo
                    id="free-solo-2-demo"
                    disableClearable
                    value={tutor}
                    options={props.tutors}
                    getOptionLabel={(option) => (option.firstName + " " + option.lastName)}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Tuteur"
                        error={tutorError > 0}
                        helperText={tutorError > 0 ? "Tuteur invalide" : ''}
                        InputProps={{
                          ...params.InputProps,
                          type: 'search',
                        }}
                      />
                    )}
                    onChange={(event, newValue) => {
                        setTutor(newValue);
                        setTutorError(0);
                    }}
                    sx={{mt:'10px'}}
                />     
                <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="endDate"
                    label="Début"
                    name="startDate"
                    InputLabelProps={{ shrink: true, required: true }}
                    autoFocus
                    type={'date'}
                    error={sError > 0}
                    helperText={sError > 0 ? 'Date invalide' : null}                   
                    value={startDate}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                        setStartDate(event.target.value);
                        setSError(0);
                    }}
                />             
                
                <FormControl fullWidth sx={{mt:'10px'}}>
                <InputLabel id="label">Statut</InputLabel>
                    <Select
                        labelId="label"
                        id="type-select"                        
                        value={reason}
                        label="Statut"

                        onChange={(event)=>setReason(event.target.value)}
                    >
                    <MenuItem key = {0} value={0}>En Cours</MenuItem>
                    <MenuItem key = {1} value={1}>Certifié</MenuItem>
                    <MenuItem key = {2} value={2}>Abandon</MenuItem>
                    <MenuItem key = {2} value={2}>Echec</MenuItem>                 
                  </Select>
                </FormControl>   
                {reason > 0 ? 
                <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="endDate"
                    label="Fin"
                    name="startDate"
                    InputLabelProps={{ shrink: true, required: true }}
                    autoFocus
                    type={'date'}
                    error={eError > 0}
                    helperText={eError > 0 ? 'Date de fin invalide' : null}                   
                    value={endDate}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                        setEndDate(event.target.value);
                        setEError(0);
                    }}
                />
                :
                null           
                }                 
                {wait ?
                    props.id === undefined ?
                        <Alert severity="info">{"Ajout du stagiaire à la formation en cours"}</Alert>
                    :
                       <Alert severity="info">{"Mise à jour en cours"}</Alert>
                    :
                    null
                }
            </DialogContent>            
            <DialogActions>
                <Button disabled={wait} onClick={()=>handleSubmit()}>Valider</Button>
                <Button disabled={wait} onClick={()=>props.handleCancel()}>Annuler</Button>
            </DialogActions>
        </Dialog>        
    );
}