import * as React from 'react';


import Box from '@mui/material/Box'


import AddIcon from '@mui/icons-material/Add'
import Alert from '@mui/material/Alert'
import Button from '@mui/material/Button'
import Card from'@mui/material/Card'
import CardContent from'@mui/material/CardContent'
import CardHeader from'@mui/material/CardHeader'
import { CharismaUserApi } from '../api/users/CharismaUserApi.tsx';
import CharismaUserDocumentForm from './userDocumentForm.tsx';
import Checkbox from '@mui/material/Checkbox';
import DeleteIcon from '@mui/icons-material/Delete'
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import EditIcon from '@mui/icons-material/Edit'
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';




export default function CharismaUserDocumentsList(props){
    
    const [userDocuments, setUserDocuments] = React.useState([]);
    const [create, setCreate] = React.useState(false);
    const [currentId, setCurrentId] = React.useState(null);
    const [currentType, setCurrentType] = React.useState(null);
    const [deleteDocument, setDeleteDocument] = React.useState(false);
    const [selectedUserDocuments, setSelectedUserDocuments] = React.useState([]);
    const lProfile = props.profile;
    
    React.useEffect(()=>{
        const lResponse = CharismaUserApi.ListUserDocument(lProfile);
        lResponse.then((value)=>{
            if(value.error === 0){
                if(value.documents !== null){
                    setUserDocuments(value.documents);
                }
                
            }
        }).catch((error)=>{
            
        })
        setSelectedUserDocuments([]);
    }, [lProfile])
    
    
    function handleCancel(){
        setCreate(false);
    }
        
    function handleSelectedDocument(pEvent : React.ChangeEvent<HTMLInputElement>, pId : number){
        let lNewSelected : number[] = [];
        let lIndex = selectedUserDocuments.indexOf(pId);
        if(pEvent.target.checked && lIndex === -1){
            lNewSelected = lNewSelected.concat(selectedUserDocuments, pId);
        }
        else if(lIndex !== -1){
            if(lIndex === 0){
                lNewSelected = lNewSelected.concat(selectedUserDocuments.slice(1));
            }
            else if(lIndex === selectedUserDocuments.length - 1){
                lNewSelected = lNewSelected.concat(selectedUserDocuments.slice(0, -1));
            }
            else{
                lNewSelected = lNewSelected.concat(selectedUserDocuments.slice(0, lIndex), selectedUserDocuments.slice(lIndex + 1),);
            }
        }
        setSelectedUserDocuments(lNewSelected);
    }
    
    
    function handleSubmit(pType : string){
        const lResponse = CharismaUserApi.CreateUserDocument(lProfile, pType);
        lResponse.then((value)=>{
            if(value.error === 0){
                setCreate(false);
                if(value.document !== null){
                    let lUserDocuments = userDocuments.concat(value.document);
                    setUserDocuments(lUserDocuments);
                }
                
                
            }
            else{
                
            }
        }).catch((error)=>{
                console.log("catch error")
        })
    }
    
    function handleUpdate(pType : string, pId : number){
        const lResponse = CharismaUserApi.UpdateUserDocument(pType, pId);
        lResponse.then((value)=>{
            if(value.error === 0){ 
                setCurrentId(null);
                setCurrentType(null);
                if(value.document !== null){
                    let lDocument = value.document;
                    let lDocumentsList = userDocuments;
                    for(let lIndex = 0; lIndex < lDocumentsList.length; lIndex++){
                        if(lDocument.id === lDocumentsList[lIndex].id){
                            lDocumentsList[lIndex].type = lDocument.type;
                            break;
                        }
                    }
                    setUserDocuments(lDocumentsList);
                    
                }
            }
            else{
                
            }
        }).catch((error)=>{
                console.log("catch error")
        })
    }
    
    function handleCancelUpdate(){
        setCurrentId(null);
        setCurrentType(null);
    }
    
    function handleEdit(pId : number, pType : string){
        setCurrentId(pId);
        setCurrentType(pType);
    }
    
    function handleDelete(){
        const lResponse = CharismaUserApi.DeleteUserDocument(selectedUserDocuments);
        lResponse.then((value)=>{
            if(value.error === 0){
                if(value.ids !== null){
                    let lDocuments = userDocuments.filter((document)=>(value.ids.indexOf(document.id) === -1));
                    setUserDocuments(lDocuments);
                    setSelectedUserDocuments([]);
                }
            }
            else{
                
            }
        }).catch((error)=>{
                console.log("catch error")
        }) 
    }
    
    return(
        
        <Box>
            <Card>
                <CardHeader
                    title={props.title}
                    action={
                        <div>
                        <IconButton>
                            <AddIcon
                                onClick={()=>setCreate(true)}
                            />
                        </IconButton>

                        {selectedUserDocuments.length > 0 ?
                            <IconButton onClick={()=>setDeleteDocument(true)}>
                                <DeleteIcon/>
                            </IconButton>  
                            :
                            null
                        } 
                        </div>
                    } 
                />
                <CardContent>
                    <Table>                               
                        <TableHead>
                            <TableRow>
                                <TableCell padding="checkbox">
                                </TableCell>
                                <TableCell>
                                    Type de document
                                </TableCell>
                                <TableCell padding="checkbox">
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                        {
                            userDocuments.map((document)=>(
                                <TableRow>
                                    <TableCell>
                                        <Checkbox
                                            checked={selectedUserDocuments.indexOf(document.id) !== -1}
                                            onClick={(event)=>handleSelectedDocument(event, document.id)}
                                        />
                                    </TableCell>
                                    <TableCell>
                                        {document.type}
                                    </TableCell>
                                    <TableCell padding="checkbox">
                                        <IconButton
                                            onClick={()=>handleEdit(document.id, document.type)}
                                        >
                                            <EditIcon/>
                                        </IconButton>
                                    </TableCell>
                                </TableRow>     
                            ))
                        }
                        </TableBody>
                    </Table>
                </CardContent>
            </Card>
               
            {create ?
                <CharismaUserDocumentForm
                    title={"Ajout d'un type de document"}
                    handleSubmit={handleSubmit}
                    handleCancel={handleCancel}
                />
                :
                null    
            }    
            {
                currentId !== null ?
                <CharismaUserDocumentForm
                    title={"Modification"}
                    type = {currentType}
                    id={currentId}
                    handleSubmit={handleUpdate}
                    handleCancel={handleCancelUpdate}
                />
                :
                null
            }
            <Dialog
                open={deleteDocument}
            >
                <DialogTitle>Suppression de Type de document</DialogTitle>
                <DialogContent>
                    <div>
                        <Typography>Etes-vous sûr de vouloir supprimer définitivement les type sélectionnés?</Typography>
                        <Alert severity='warning'>Cette action sera définitive</Alert>
                    </div>
                    
                </DialogContent>
                <DialogActions>
                    <Button autoFocus onClick={()=>setDeleteDocument(false)}>
                        Annuler
                    </Button>
                    <Button onClick={()=>{handleDelete();setDeleteDocument(false)}}>
                        Valider
                    </Button>
                </DialogActions>
            </Dialog> 
                  
        </Box>      
    );
    
    
}