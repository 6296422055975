import * as React from 'react';


import Box from '@mui/material/Box'


import AddIcon from '@mui/icons-material/Add'
import Alert from '@mui/material/Alert'
import Button from '@mui/material/Button'
import Card from'@mui/material/Card'
import CardContent from'@mui/material/CardContent'
import CardHeader from'@mui/material/CardHeader'
import { CharismaFinancalApi } from '../api/financals/CharismaFinancalApi.tsx';
import CharismaFinancalForm from './financalForm.tsx';
import CharismaFinancal from './financal.tsx';
import Checkbox from '@mui/material/Checkbox';
import DeleteIcon from '@mui/icons-material/Delete'
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import EditIcon from '@mui/icons-material/Edit'
import Grid from '@mui/material/Grid'
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';




export default function CharismaFinancalsList(props){
    
    const [financals, setFinancals] = React.useState([]);
    const [create, setCreate] = React.useState(false);
    const [currentId, setCurrentId] = React.useState(null);
    const [deleteFinancal, setDeleteFinancal] = React.useState(false);
    const [selected, setSelected] = React.useState([]);
    
    React.useEffect(()=>{
        const lResponse = CharismaFinancalApi.ListFinancals();
        lResponse.then((value)=>{
            if(value.error === 0){
                if(value.financals !== null){
                    setFinancals(value.financals);
                }
                
            }
        }).catch((error)=>{
            
        })
        setSelected([]);
    }, [])
    
    
    function handleCancel(){
        setCreate(false);
    }
    
    function handleSelected(pEvent : React.ChangeEvent<HTMLInputElement>, pId : number){
        let lNewSelected : number[] = [];
        let lIndex = selected.indexOf(pId);
        if(pEvent.target.checked && lIndex === -1){
            lNewSelected = lNewSelected.concat(selected, pId);
        }
        else if(lIndex !== -1){
            if(lIndex === 0){
                lNewSelected = lNewSelected.concat(selected.slice(1));
            }
            else if(lIndex === selected.length - 1){
                lNewSelected = lNewSelected.concat(selected.slice(0, -1));
            }
            else{
                lNewSelected = lNewSelected.concat(selected.slice(0, lIndex), selected.slice(lIndex + 1),);
            }
        }
        setSelected(lNewSelected);
    }
    
    
    function handleSubmit(pName : string){
        const lResponse = CharismaFinancalApi.CreateFinancal(pName);
        lResponse.then((value)=>{
            if(value.error === 0){
                setCreate(false);
                if(value.financal !== null){
                    let lFinancals = financals.concat(value.financal);
                    setFinancals(lFinancals);
                }                                
            }
            else{
                
            }
        }).catch((error)=>{
            console.log("catch error")
        })
    }
    
    function handleUpdate(pName : string, pId : number){
        let lFinancalsList = financals.slice();
        for(let lIndex = 0; lIndex < lFinancalsList.length; lIndex++){
            if(pId === lFinancalsList[lIndex].id){
                lFinancalsList[lIndex].name = pName;
                break;
            }
        }
        setFinancals(lFinancalsList);  
    }
    
    function handleCancelUpdate(){
        setCurrentId(null);
    }
    
    function handleEdit(pId : number){
        setCurrentId(pId);
    }
    
    function handleDelete(){
        const lResponse = CharismaFinancalApi.DeleteFinancals(selected);
        lResponse.then((value)=>{
            if(value.error === 0){
                if(value.ids !== null){
                    let lFinancals = financals.filter((financal)=>(value.ids.indexOf(financal.id) === -1));
                    setFinancals(lFinancals);
                    setSelected([]);
                }
            }
            else{
                
            }
        }).catch((error)=>{
                console.log("catch error")
        }) 
    }
    
    return(
        
        <Box>
            <Grid container spacing={4}>
                <Grid item xs={12} sm={4}>
                    <Card>
                        <CardHeader
                            title={"Liste des Financements"}
                            action={
                                <div>
                                <IconButton>
                                    <AddIcon
                                        onClick={()=>setCreate(true)}
                                    />
                                </IconButton>
                                {selected.length > 0 ?
                                    <IconButton onClick={()=>setDeleteFinancal(true)}>
                                        <DeleteIcon/>
                                    </IconButton>  
                                    :
                                    null
                                } 
                                </div>
                            } 
                        />
                        <CardContent>
                            <Table>                               
                                <TableHead>
                                    <TableRow>
                                        <TableCell padding="checkbox">
                                        </TableCell>
                                        <TableCell>
                                            Type de Financement
                                        </TableCell>
                                        <TableCell padding="checkbox">
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                {
                                    financals.map((financal)=>(
                                        <TableRow>
                                            <TableCell>
                                                <Checkbox
                                                    checked={selected.indexOf(financal.id) !== -1}
                                                    onClick={(event)=>handleSelected(event, financal.id)}
                                                />
                                            </TableCell>
                                            <TableCell>
                                                {financal.name}
                                            </TableCell>
                                            <TableCell padding="checkbox">
                                                <IconButton
                                                    onClick={()=>handleEdit(financal.id)}
                                                >
                                                    <EditIcon/>
                                                </IconButton>
                                            </TableCell>
                                        </TableRow>     
                                    ))
                                }
                                </TableBody>
                            </Table>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={12} sm={8}>
                    {currentId !== null ?
                        <CharismaFinancal
                            financalId={currentId}
                            handleUpdate={handleUpdate}
                            handleCancel={handleCancelUpdate}
                        />
                    :
                    null
                    }                
                </Grid>
            </Grid>
            {create ?
                <CharismaFinancalForm
                    title={"Ajout d'un financement"}
                    handleSubmit={handleSubmit}
                    handleCancel={handleCancel}
                />
                :
                null    
            }    
            <Dialog
                open={deleteFinancal}
            >
                <DialogTitle>Suppression de financements</DialogTitle>
                <DialogContent>
                    <div>
                        <Typography>Etes-vous sûr de vouloir supprimer définitivement les financements sélectionnés?</Typography>
                        <Alert severity='warning'>Cette action sera définitive</Alert>
                    </div>
                    
                </DialogContent>
                <DialogActions>
                    <Button autoFocus onClick={()=>setDeleteFinancal(false)}>
                        Annuler
                    </Button>
                    <Button onClick={()=>{handleDelete();setDeleteFinancal(false)}}>
                        Valider
                    </Button>
                </DialogActions>
            </Dialog> 
                  
        </Box>      
    );
    
    
}