import * as React from 'react';

import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import Avatar from '@mui/material/Avatar'
import CharismaFileForm from '../components/fileForm.tsx';
import CharismaUserUpdatePasswordForm from './userUpdatePasswordForm.tsx';
import CharismaSignatureFileForm from '../components/signatureFileForm.tsx';
import { CharismaUserApi } from '../api/users/CharismaUserApi.tsx';
import IconButton from '@mui/material/IconButton'
import { Link } from 'react-router-dom';
import LogoutIcon from '@mui/icons-material/Logout';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem'
import SettingsIcon from '@mui/icons-material/Settings';
import {useNavigate} from "react-router-dom"

import { CharismaUserContext } from '../context.tsx';

export default function CharismaUserMenu(props){
    
    const lCurrentUser = React.useContext(CharismaUserContext);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [updateAvatar, setUpdateAvatar] = React.useState(false);
    const [updatePassword, setUpdatePassword] = React.useState(false);
    const [createSignature, setCreateSignature] = React.useState(false);
    const [avatar, setAvatar] = React.useState(lCurrentUser.avatar !== undefined ? lCurrentUser.avatar : null);
    const open = Boolean(anchorEl);
    function handleSubmitAvatar(pAvatar:File){
        const lResponse = CharismaUserApi.UpdateAvatar(pAvatar, lCurrentUser.id);
        lResponse.then((value)=>{
            if(value.error === 0){
                setUpdateAvatar(false);
                setAvatar(value.link);
            }
            else{
                
            }
        }).catch((error)=>{
                console.log("catch error")
        })
    }
   
    
    function handleCancelUpdatePassword(){
        setUpdatePassword(false);
    }
    
    function handleCancelAvatar(){
        setUpdateAvatar(false);
    }
    
    function handleOpenMenu(event: React.MouseEvent<HTMLElement>){
        setAnchorEl(event.currentTarget);
    };
    
    function handleCloseMenu(){
        setAnchorEl(null);
    };
    
    function cancelSignature(){
        setCreateSignature(false);
    }
    
    let navigate = useNavigate();
    function onDisconnect(){
        const lResponse = CharismaUserApi.DisconnectUser();
        lResponse.then((value)=>{
            if(value.error === 0){
                navigate('/');
                props.handleDisconnect();
            }
            else{
                
            }
        }).catch((error)=>{
                console.log("catch error")
        })
    }
    return(
        <div>
            {avatar !== null ?
                    <IconButton onClick={(event)=>handleOpenMenu(event)}>
                        <Avatar
                            src={avatar}
                        />
                    </IconButton>
                    :
                    <IconButton onClick={(event)=>handleOpenMenu(event)}>
                        <Avatar>
                            {lCurrentUser.firstName[0]+lCurrentUser.lastName[0]}
                        </Avatar>
                    </IconButton>
            }
            <Menu
                open={open}
                anchorEl={anchorEl}
                onClose={handleCloseMenu}
                onClick={handleCloseMenu}
            >
                 <MenuItem onClick={()=>setUpdateAvatar(true)}>
                    <AccountCircleIcon/>
                    Photo de profil
                </MenuItem>
                <MenuItem
                    component={Link}
                    to={'/profile'}
                >
                    <SettingsIcon/>
                     Mes informations
                </MenuItem>
                 <MenuItem onClick={()=>setUpdatePassword(true)}>
                    <AccountCircleIcon/>
                    Changer mon mot de passe
                </MenuItem>
                <MenuItem onClick={()=>setCreateSignature(true)}>
                    Ajouter ma signature
                </MenuItem>
                <MenuItem onClick={()=>onDisconnect()}>
                    <LogoutIcon/>
                    Se déconnecter
                </MenuItem>
            </Menu>
            {updateAvatar?
                <CharismaFileForm
                    type={'img'}
                    handleSubmit={handleSubmitAvatar}
                    handleCancel={handleCancelAvatar}
                />
                :
                null
            } 
            {
                updatePassword ?
                <CharismaUserUpdatePasswordForm
                    handleCancel={handleCancelUpdatePassword}
                    userId={lCurrentUser.id}
                />
                :
                null
            }
            {
                createSignature ?
                    <CharismaSignatureFileForm
                        type={'img'}
                        title={'Ajouter ma signature'}
                        handleCancel={cancelSignature}
                        userId = {lCurrentUser.id}
                    />
                    :
                    null
            }
        </div>

    );
}