import * as React from 'react';


import Box from '@mui/material/Box'

import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import CardHeader from '@mui/material/CardHeader';
import { CharismaStudentTrainingApi } from '../api/studentTrainings/CharismaStudentTrainingApi.tsx'
import CharismaStudentTrainingBook from '../studentTrainings/studentTrainingBook.tsx'
import CharismaStudentsTrainingAssessmentsList from '../studentTrainings/studentTrainingAssessmentsList.tsx';
import CharismaStudentTrainingBoard from '../studentTrainings/studentTrainingBoard.tsx'
import CharismaTrainingDocs from '../training/trainingDocs.tsx'
import CharismaAbsencesList from '../studentTrainings/absencesList.tsx'
import CharismaScheduler from '../training/scheduler.tsx'
import CharismaStudentDocsToAcceptForm from './docToAcceptForm.tsx'
import CharismaImagesList from '../components/imagesList.tsx';
import CharismaStudentSpaceMenu from './studentSpaceMenu.tsx';
import Grid from '@mui/material/Grid'

import Typography from '@mui/material/Typography';
import {formatDate} from '../utils.tsx'




export default function CharismaStudentSpace(props){
    

    const [id, setId] = React.useState(1);
    const [tutor, setTutor] = React.useState(null);
    const [course, setCourse] = React.useState(null);
    const [training, setTraining] = React.useState(null);
    const [student, setStudent] = React.useState(null);
    const [financal, setFinancal]= React.useState(null);
    const [running, setRunning]= React.useState(true);
    const [startDate, setStartDate] = React.useState('');
    const [endDate, setEndDate] = React.useState('');
    const [reason, setReason] = React.useState(0);
    const [docAccepted, setDocAccepted] = React.useState(true);
    const [docsToAccept, setDocsToAccept] = React.useState([]);
    
    const [page, setPage] = React.useState('book');
    
    const lStudentTrainingId = props.studentTrainingId;
     
    React.useEffect(()=>{
        const lResponse = CharismaStudentTrainingApi.GetStudentTraining(lStudentTrainingId);
        lResponse.then((value)=>{
            if(value.error === 0 && value.studentTraining !== null){
                setId(value.studentTraining.id);
                setStartDate(value.studentTraining.startDate);
                setEndDate(value.studentTraining.endDate);
                setReason(value.studentTraining.reason);
                setRunning(value.studentTraining.running)
                setFinancal(value.studentTraining.financal);
                setTutor(value.studentTraining.tutor);  
                setStudent(value.studentTraining.student);
                setTraining(value.studentTraining.training);
                setCourse(value.studentTraining.course)
                setDocAccepted(value.studentTraining.acceptedByStudent);
                setDocsToAccept(value.studentTraining.docsToAccept);
            }
        }).catch((error)=>{
            
        })

    }, [lStudentTrainingId])
    
    React.useEffect(()=>{
        setPage(props.page);    
    },[props.page])
    
    const lReasons=["Abandon", "Certification", "Echec"];
    
    function handleAcceptDocs(){
        const lResponse = CharismaStudentTrainingApi.UpdateAccepted(lStudentTrainingId);
        lResponse.then((value)=>{
            if(value.error === 0 && value.studentTraining !== null){
                setDocAccepted(true);
            }
        }).catch((error)=>{
            
        })        
    }
    
    return(
        <Box>
            <Card>
                <CardHeader
                    title={
                        <Box>
                            <Typography>{training !== null ? training.training : ""}</Typography>  
                        </Box>
                    } 
                />
                
                <CardContent>
                    <Grid container spacing={4}>
                        <Grid item xs={12} sm={3}>                        
                            <Typography>{"Date d'entrée : " + formatDate(startDate)}</Typography>
                            <Typography>{tutor === null ? "Tuteur : " :  "Tuteur : " + tutor.firstName + ' ' + tutor.lastName}</Typography>
                            <Typography>{course === null ? "Parcours : " : "Parcours : " + course.course}</Typography>
                            {running ?
                                <Typography>Statut : En Cours</Typography>
                                :
                                <Typography>{"Statut : " + lReasons[reason]}</Typography>
                            }
                            <Typography>{financal == null ? "Financement : " : "Financement : " + financal.name}</Typography>
                        </Grid>
                        <Grid item xs={12} sm={9}>
                        {financal === null || financal.pictures.length === 0?
                            null
                            :
                            <CharismaImagesList
                                add={false}
                                pictures={financal.pictures}
                                width={'200px'}
                                grid={3}
                            />
                        }
                        </Grid>
                    </Grid>       
                    <CharismaStudentSpaceMenu
                        studentTrainingId ={lStudentTrainingId}
                    />
                    {page === "book" && training !== null && student !== null?
                        <CharismaStudentTrainingBook
                            trainingId={training.id}
                            studentId={student.id}
                            studentTrainingId={id}
                        />
                        :
                        null                        
                    }
                    {page === "scheduler" && training !== null && course !== null ?
                        <CharismaScheduler
                            trainingId={training.id}
                            courseId={course.id}
                        />
                        :
                        null
                    }
                    {page === "teaching-docs" && training !== null?
                        <CharismaTrainingDocs
                            type="teaching"
                            trainingId={training.id}
                            readOnly={true}
                            title={'Documents de Cours'}
                        />
                        :
                        null                        
                    }
                    {page === "daily-assessments" && student !== null && training !== null ?
                        <CharismaStudentsTrainingAssessmentsList
                            type={0}
                            studentTrainingId={id}
                            studentId={student.id}
                            title={'Bilans Quotidiens'}
                            trainingId={training.id}
                        />
                        :
                        null                        
                    }
                    {page === "monthly-assessments" && student !== null && training !== null ?
                        <CharismaStudentsTrainingAssessmentsList
                            type={1}
                            studentTrainingId={id}
                            studentId={student.id}
                            title={'Bilans Mensuels'}
                            trainingId={training.id}
                        />
                        :
                        null                        
                    }        
                    {page === "board" && student !== null && training !== null ?
                        <CharismaStudentTrainingBoard
                            studentTrainingId={id}
                            studentId={student.id}
                            trainingId={training.id}
                        />
                        :
                        null
                    }
                    {page === "absences" && student !== null ?
                        <CharismaAbsencesList
                            studentTrainingId={id}
                            studentId={student.id}
                        />
                        :
                        null
                    }            
                </CardContent>                                 
            </Card>
            {
                !docAccepted && docsToAccept["student"] !== undefined && docsToAccept["student"].length > 0?
                <CharismaStudentDocsToAcceptForm
                    docs={docsToAccept["student"]} 
                    handleSubmit={handleAcceptDocs}
                />
                :
                null
            }

        </Box>      
    );
    
}