
import { CharismaApi } from "../CharismaApi.js";

export const CharismaDocumentsApi = {
    AddFile: async function(pFile : File,  pPath : string){
        const lDatas = new FormData();
        lDatas.append('file', pFile);
        lDatas.append('path', pPath);
        const lResponse = await CharismaApi.request({
            url:`/documents/addFile.php`,
            method:"POST",
            data:lDatas
        })
        return lResponse.data;
    },
    CreateDirectory: async function(pPath : string,  pName : string){
        const lDatas = new FormData();
        lDatas.append('name', pName);
        lDatas.append('path', pPath);
        const lResponse = await CharismaApi.request({
            url:`/documents/createDirectory.php`,
            method:"POST",
            data:lDatas
        })
        return lResponse.data;
    },
    Scan: async function(pPath : string){
        const lDatas = new FormData();
        lDatas.append('path', pPath);
        const lResponse = await CharismaApi.request({
            url:`/documents/scan.php`,
            method:"POST",
            data:lDatas
        })
        return lResponse.data;
    },
    UpdateEntries: async function(pPath : string){
        const lDatas = new FormData();
        lDatas.append('path', pPath);
        const lResponse = await CharismaApi.request({
            url:`/documents/updateEntries.php`,
            method:"POST",
            data:lDatas
        })
        return lResponse.data;
    },
    DeleteFiles: async function(pFiles : string[], pPath : string){
        const lDatas = new FormData();
        pFiles.forEach((lFile)=>{
            lDatas.append("files[]", lFile);    
        });
        lDatas.append("path", pPath);
        const lResponse = await CharismaApi.request({
            url:`/documents/deleteFiles.php`,
            method:"POST",
            data:lDatas
        })
        return lResponse.data;
    },  
    DeleteDirs: async function(pDirs : string[], pPath : string){
        const lDatas = new FormData();
        pDirs.forEach((lDir)=>{
            lDatas.append("dirs[]", lDir);    
        });
        lDatas.append("path", pPath);
        const lResponse = await CharismaApi.request({
            url:`/documents/deleteDirs.php`,
            method:"POST",
            data:lDatas
        })
        return lResponse.data;
    } 
}