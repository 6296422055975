import * as React from 'react';


import Box from '@mui/material/Box'


import AddIcon from '@mui/icons-material/Add'
import Alert from '@mui/material/Alert'
import Button from '@mui/material/Button'
import Card from'@mui/material/Card'
import CardContent from'@mui/material/CardContent'
import CardHeader from'@mui/material/CardHeader'
import { CharismaStudentTrainingApi } from '../api/studentTrainings/CharismaStudentTrainingApi.tsx';
import { CharismaUserContext } from '../context.tsx';
import Checkbox from '@mui/material/Checkbox';
import DeleteIcon from '@mui/icons-material/Delete'
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import EditIcon from '@mui/icons-material/Edit'
import {formatDate} from '../utils.tsx'
import Grid from '@mui/material/Grid'
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';





export default function CharismaTrainigTrashedStudentsList(props){
    
    
    const [studentsList, setStudentsList] = React.useState([]);
    const [deleteStudents, setDeleteStudents] = React.useState(false);
    const [selectedStudents, setSelectedStudents] = React.useState([]);   
   
    const lCurrentUser = React.useContext(CharismaUserContext);
    const lReadWrite = lCurrentUser !== null && lCurrentUser !== undefined && lCurrentUser.profile === 0;
    const lTrainingId = props.trainingId;    


    React.useEffect(()=>{
        const lResponsePeriods = CharismaStudentTrainingApi.ListTrashedStudents(lTrainingId);
        lResponsePeriods.then((value)=>{
            if(value.error === 0){
                setStudentsList(value.studentTrainings);
            }
        }).catch((error)=>{
            
        })
    }, [lTrainingId])
   
        
    function handleDeleteStudents(pSelectedCourses){
        const lResponse = CharismaStudentTrainingApi.DeleteStudentTrainings(selectedStudents);
        lResponse.then((value)=>{
            if(value.error === 0){
                if(value.ids !== null){
                    const lIds = value.ids;
                    let lStudents = studentsList.filter((lPeriod)=>lIds.indexOf(lPeriod.id) === -1);
                    setStudentsList(lStudents);
                    setSelectedStudents([]);
                    setDeleteStudents(false);                  
                }
;            }
            else{
            
            }
        }).catch((error)=>{
            console.log("catch error")
        })           
    }            
    
    function handleSelectedStudent(pEvent : React.ChangeEvent<HTMLInputElement>, pId : number){
        let lNewSelected : number[] = [];
        let lIndex = selectedStudents.indexOf(pId);
        if(pEvent.target.checked && lIndex === -1){
            lNewSelected = lNewSelected.concat(selectedStudents, pId);
        }
        else if(lIndex !== -1){
            if(lIndex === 0){
                lNewSelected = lNewSelected.concat(selectedStudents.slice(1));
            }
            else if(lIndex === selectedStudents.length - 1){
                lNewSelected = lNewSelected.concat(selectedStudents.slice(0, -1));
            }
            else{
                lNewSelected = lNewSelected.concat(selectedStudents.slice(0, lIndex), selectedStudents.slice(lIndex + 1),);
            }
        }
        setSelectedStudents(lNewSelected);
    }
            

    
    function createStudentListTable(pStudents, pSelected : number[]){
        return(
            pStudents.map((student)=>(
                <TableRow key={student.id}>
                    {lReadWrite ?
                        <TableCell padding="checkbox">
                            <Checkbox
                                checked={pSelected.indexOf(student.id) >= 0}
                                onClick={                                    
                                    (event)=>handleSelectedStudent(event, student.id)
                                    }
                            />
                        </TableCell>
                        :
                        null
                    }
                    <TableCell>
                        {student.student !== null ?
                            student.student.firstName + " " + student.student.lastName
                            :
                            null
                        }
                    </TableCell>
                    <TableCell>
                        {
                            student.course !== null ?
                            student.course.course
                            :
                            null
                        }
                    </TableCell>                    
                </TableRow>
                 
            ))
        )
    }
    
    
    return(
        
        <Box>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={12}>
                    <Card>
                        <CardHeader
                            title="Périodes de formation"
                            action={
                                <div>
                                {lReadWrite?
                                <div>

                                {selectedStudents.length > 0?
                                    <IconButton onClick={()=>setDeleteStudents(true)}>
                                        <DeleteIcon/>
                                    </IconButton>  
                                    :
                                    null
                                } 
                                </div>
                                :
                                null
                                }                                
                                </div>
                            }
                        />
                        <CardContent>
                            <Table>                               
                                <TableHead>
                                    <TableRow>
                                        {lReadWrite ?
                                            <TableCell padding="checkbox">
                                                </TableCell>
                                              :
                                              null
                                        }
                                        <TableCell>Stagiaire</TableCell>
                                        <TableCell>Parcours</TableCell>
                                        
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                {
                                    createStudentListTable(studentsList, selectedStudents)
                                }
                                </TableBody>
                            </Table>                            
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>

            
            <Dialog
                open={deleteStudents}
            >
                <DialogTitle>Suppression des stagiaire</DialogTitle>
                <DialogContent>
                    <Typography>Etes-vous sûr de vouloir supprimer définitivement les stagiaires sélectionnés?</Typography>
                    <Alert severity='warning'>
                        Cette action est définitive, l'ensemble des documents et des évaluation seront supprimés
                    </Alert>
                    {
                        false?
                            <Alert severity='info'>Suppression en cours...</Alert>
                        :
                        null
                    }
                </DialogContent>
                <DialogActions>
                    <Button autoFocus onClick={()=>setDeleteStudents(false)}>
                        Annuler
                    </Button>
                    <Button onClick={()=>handleDeleteStudents()}>
                        Valider
                    </Button>
                </DialogActions>
            </Dialog>                     
        </Box>      
    );
    
    
}