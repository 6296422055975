import * as React from 'react';


import Box from '@mui/material/Box'


import AddIcon from '@mui/icons-material/Add'
import { CharismaUserApi } from '../api/users/CharismaUserApi.tsx';
import { CharismaTrainingApi } from '../api/trainings/CharismaTrainingApi.tsx';
import { CharismaFinancalApi } from '../api/financals/CharismaFinancalApi.tsx';
import CharismaUserForm  from './userForm.tsx';
import CharismaImportFileForm from './importFileForm.tsx';
import CharismaStudentForm  from './studentForm.tsx';
import CharismaUsersListItem from './usersListItem.tsx';
import CharismaMessage from '../components/message.tsx';
import FormControl from '@mui/material/FormControl'
import FileUploadIcon from '@mui/icons-material/FileUpload';
import Grid from '@mui/material/Grid'
import IconButton from '@mui/material/IconButton';
import InputBase from '@mui/material/InputBase'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import SearchIcon from '@mui/icons-material/Search';
import { styled, alpha } from '@mui/material/styles';
import Select from '@mui/material/Select'
import Toolbar from '@mui/material/Toolbar'
import Typography from '@mui/material/Typography';


const Search = styled('div')(({ theme }) => ({
  position: 'relative',
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  '&:hover': {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginLeft: 0,
  width: '100%',
  [theme.breakpoints.up('sm')]: {
    marginLeft: theme.spacing(1),
    width: 'auto',
  },
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: '100%',
  position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: 'inherit',
  width: '100%',
  '& .MuiInputBase-input': {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create('width'),
    [theme.breakpoints.up('sm')]: {
      width: '12ch',
      '&:focus': {
        width: '20ch',
      },
    },
  },
}));



export default function CharismaUsersList(props){
    
    const [create, setCreate] = React.useState(false);
    const [users, setUsers] = React.useState([]);
    const [displayedUsers, setDisplayedUsers] = React.useState([]);
    const [error, setError] = React.useState(0);
    const [trainings, setTrainings] = React.useState([{id:0, name:'Toutes les formations'}]);
    const [trainingId, setTrainingId] = React.useState(0);
    const [search, setSearch] = React.useState('');
    const [deleting, setDeleting] = React.useState(false);
    const [tutors, setTutors]= React.useState([]);
    const [financals, setFinancals]= React.useState([]);
    const [importUsers, setImportUsers]= React.useState(false);
    const lUsersProfile : number = props.profile === undefined ? -1 : props.profile;
    const lTrash : number = props.trash === undefined ? 0 : 1; 

    React.useEffect(()=>{
        const lResponse = CharismaUserApi.List(lUsersProfile, lTrash);
        lResponse.then((value)=>{
            if(value.error === 0){
                setUsers(value.users);
                setDisplayedUsers(value.users);
                setSearch('');
            }
        }).catch((error)=>{
            
        })
        
        if(lUsersProfile === 3){
            const lResponseTrainings = CharismaTrainingApi.ListTrainings(0);
            lResponseTrainings.then((value)=>{
                if(value.error === 0){
                    let lTrainings = [{id:0, name:'Toutes'}].concat(value.trainings);
                    setTrainings(lTrainings);
                }
            }).catch((error)=>{
                
            })
            const lResponseTutors = CharismaUserApi.List(3, 0);
            lResponseTutors.then((value)=>{
                if(value.error === 0){
                    let lTutors = [{id:0, firstName:'Aucun', lastName:''}].concat(value.users);
                    setTutors(lTutors);
                }
            }).catch((error)=>{
                
            })
            const lResponseFinancals = CharismaFinancalApi.ListFinancals()
            lResponseFinancals.then((value)=>{
                if(value.error === 0){
                    let lFinancals = [{id:0, name:'Aucun'}].concat(value.financals);
                    setFinancals(lFinancals);
                }
            }).catch((error)=>{
                
            })
        }
        
    }, [lUsersProfile, lTrash])
    /*
    React.useEffect(()=>{
        const lResponse = CharismaTrainingApi.ListPromotions(trainingId, 0)
        lResponse.then((value)=>{
            if(value.error === 0){   
                let lPromotions = [{id:0, name:'Toutes'}].concat(value.promotions);      
                setPromotions(lPromotions);
            }
        }).catch((error)=>{
            
        })        
        
    }, [trainingId])    
    */
    React.useEffect(()=>{
        if(search.length > 0){
            let lUsers = users.filter((user)=>(
                user.firstName.toLowerCase().includes(search.toLowerCase()) || user.lastName.toLowerCase().includes(search.toLowerCase()))
             );
             setDisplayedUsers(lUsers);
        }   
        else{
            setDisplayedUsers(users);
        } 
        
    }, [search, users])    
    
    function handleCancel(){
        setCreate(false);
    }
    
    function handleDeleteUser(pId : number){
        setDeleting(true);
        if(!lTrash){
            const lResponse = CharismaUserApi.TrashUser(pId, lUsersProfile);
            lResponse.then((value)=>{
                if(value.error === 0 && value.id !== undefined){
                    const lId = value.id;
                    const lUsers = users.filter((lUser)=>lUser.id !== lId);
                    setDisplayedUsers(lUsers);
                    setSearch("");
                    setUsers(lUsers);
                    setDeleting(false);
                }
                else{
                
                }
            }).catch((error)=>{
                console.log("catch error")
            })
        }
        else{
            const lResponse = CharismaUserApi.DeleteUser(pId, lUsersProfile);
            lResponse.then((value)=>{
                if(value.error === 0 && value.id !== undefined){
                    const lId = value.id;
                    const lUsers = users.filter((lUser)=>lUser.id !== lId);
                    setUsers(lUsers);
                    setDisplayedUsers(lUsers);
                    setSearch("");
                    setDeleting(false);
                }
                else{
                
                }
            }).catch((error)=>{
                console.log("catch error")
            })    
        }       
    }
    
    function handleRestoreUser(pId : number){
        const lResponse = CharismaUserApi.RestoreUser(pId, lUsersProfile);
        setDeleting(true);
        lResponse.then((value)=>{
            if(value.error === 0 && value.id !== undefined){
                const lId = value.id;
                const lUsers = users.filter((lUser)=>lUser.id !== lId);
                setUsers(lUsers);
                setDisplayedUsers(lUsers);
                setSearch("");
                setDeleting(false);
            }
            else{
            
            }
        }).catch((error)=>{
            console.log("catch error")
        })           
    }
    
    function handleImport(pFile : File){
        const lResponse = CharismaUserApi.ImportUsers(pFile, lUsersProfile);
        lResponse.then((value)=>{
            if(value.error === 0){
                setImportUsers(false);
            }
            else{
                
            }
        }).catch((error)=>{
                console.log("catch error")
        })
    }
    
    function cancelImport(){
        setImportUsers(false);
    }

    function handleCreateStudent(pFName : string, pLName : string, pMail: string, pPhone : string,
            pTrainingId:number, pCourseId:number, pTutorId:number, pFinancalId:number, pStartDate:string, pEndDate:string){
        setError(0);
        const lResponse = CharismaUserApi.CreateStudent(pFName, pLName, pMail, pPhone, 
            lUsersProfile, pTrainingId, pCourseId, pTutorId, pFinancalId, pStartDate, pEndDate);
        lResponse.then((value)=>{
            setError(value.error);
            if(value.error === 0){
                setCreate(false);
                if(value.user !== undefined && value.user !== null){
                    let lNewUsers = users;
                    lNewUsers.push(value.user);
                    setUsers(lNewUsers);
                }
            }
            else{
                
            }
        }).catch((error)=>{
                console.log("catch error")
        })
    }    
    
    function handleSubmit(pFName : string, pLName : string, pMail: string, pPhone : string){
        setError(0);
        const lResponse = CharismaUserApi.CreateUser(pFName, pLName, pMail, pPhone, lUsersProfile);
        lResponse.then((value)=>{
            setError(value.error);
            if(value.error === 0){
                setCreate(false);
                if(value.user !== undefined && value.user !== null){
                    let lNewUsers = users;
                    lNewUsers.push(value.user);
                    setUsers(lNewUsers);
                }
            }
            else{
                
            }
        }).catch((error)=>{
                console.log("catch error")
        })
    }
    return(
        
        <Box>
            <Typography variant="h5">{props.title}</Typography>
            {!lTrash?
                <Toolbar>
                    {
                        lUsersProfile !== -1 ?
                        <IconButton onClick={()=>setImportUsers(true)}>
                            <FileUploadIcon/>
                        </IconButton>
                        :
                        null
                    }
                    <IconButton onClick={()=>setCreate(true)}>
                        <AddIcon/>
                    </IconButton>
                    <Search>
                        <SearchIconWrapper>
                            <SearchIcon />
                        </SearchIconWrapper>
                        <StyledInputBase
                            placeholder="Rechercher…"
                            inputProps={{ 'aria-label': 'search' }}
                            onChange={(event)=>setSearch(event.target.value)}
                            value={search}
                        />
                    </Search>
                </Toolbar>
                :
                null               
            }
             {
                lUsersProfile === 3?
                <Box sx={{mb: 4}}>
                <FormControl sx={{mr: 4}}>
                <InputLabel id="training-label">Formation</InputLabel>
                <Select
                    id="trainings"
                    labelId='training-label'
                    value={trainingId}
                    label='Formation'
                    onChange={(event)=>setTrainingId(event.target.value)}
                    sx={{minWidth:'500px'}}
                >
                {trainings.map((training)=>(
                    <MenuItem key={training.id}value={training.id}>{training.name}</MenuItem>
                    ))
                }
                </Select>
                </FormControl>                
                </Box>
                    :
                    null
                }
            <Grid container spacing={4}>
            {displayedUsers.map((user)=>(
                <Grid item xs={12} sm={4} key={user.id}>
                    <CharismaUsersListItem
                        user={user}
                        handleDeleteUser={handleDeleteUser}
                        handleRestoreUser={handleRestoreUser}
                        trash={props.trash}
                        deleting={deleting}
                        profile={lUsersProfile}
                    />
                </Grid>
            ))}
            </Grid>
            {create && lUsersProfile !== 3?
                <CharismaUserForm
                    title={"Ajout d'un utilisateur"}
                    firstName = {''}
                    lastName = {''}
                    mail = {''}
                    phone = {''}
                    handleSubmit={handleSubmit}
                    handleCancel={handleCancel}
                />
                :
                null    
            }
            {create && lUsersProfile === 3?
                <CharismaStudentForm
                    title={"Ajout d'un stagiaire"}
                    firstName = {''}
                    lastName = {''}
                    mail = {''}
                    phone = {''}
                    handleSubmit={handleCreateStudent}
                    handleCancel={handleCancel}
                    tutors={tutors}
                    financals={financals}
                    trainings={trainings.filter((training)=>(training.id > 0))}
                />
                :
                null    
            }   
            {
                error === 2 ?
                    <CharismaMessage
                        level='error'
                        message="Cette adresse mail est déjà utilisée"
                    />
                    :
                    null
            }    
            {importUsers ?
                <CharismaImportFileForm
                    title="Import"
                    handleSubmit={handleImport}
                    handleCancel={cancelImport}                    
                />
                :
                null
            }
        </Box>      
    );
    
    
}