import * as React from 'react';

import Alert from "@mui/material/Alert"
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Carousel from "react-material-ui-carousel"
import { CharismaUserApi } from '../api/users/CharismaUserApi.tsx';
import CharismaUserForgotPasswordForm from './userForgotPasswordForm.tsx'
import EmailIcon from '@mui/icons-material/Email';
import FormControl from '@mui/material/FormControl';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Paper from '@mui/material/Paper';
import PersonIcon from '@mui/icons-material/Person';
import SchoolIcon from '@mui/icons-material/School';
import Select from '@mui/material/Select';
import SupervisorAccountIcon from '@mui/icons-material/SupervisorAccount';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import VisibilityIcon from '@mui/icons-material/Visibility';


import { ValidateEmail } from '../utils/Validator.tsx';
import { SelectChangeEvent } from '@mui/material';




export default function CharismaSignIn(props) {
    const [mail, setMail] = React.useState('');
    const [mailValid, setMailValid] = React.useState(true);
    const [password, setPassword] = React.useState('');
    const [forgot, setForgot] = React.useState(false);
    const [showPassword, setShowPassword] = React.useState(false);
    const [profile, setProfile] = React.useState(0);
    const [error, setError] = React.useState(0);
    const [firstImageLoad, setFirstImageLoad] = React.useState(false);

    var items = [
        {
            src: "/noe_saut.webp",
        },
        {
            src:"/fae_groupe.webp"
        },
        {
            src:"/nico_fae.webp"
        }
    ]
    
    const mailRef = React.useRef();
    const passwordRef = React.useRef();

    function handleSubmit() {
        let lMail = mailRef.current.value.trim();
        let lPassword = passwordRef.current.value;
        let lValidMail: boolean = ValidateEmail(lMail.trim());
        setMailValid(lValidMail);
        if(lValidMail){
            let lUserData = CharismaUserApi.ConnectUser(lMail, lPassword, profile);
            lUserData.then((value)=>{
                if(value.error === 0){
                    props.setUser(value.user);
                }
                else{
                    setError(value.error);
                }
            }).catch((error)=>{
                console.log("catch error")
            })
        }
    }
    
    function Item(props)
    {
        return (
            
                <img 
                    src={props.src} 
                    style={{width:'100%'}}
                    onLoad={()=>setFirstImageLoad(true)}
                />
            
        )
    }
    
    function onOkForgot(){
        setForgot(false);
    }

    return (
        <Box>
            <Box
                sx={{
                    display: {xs:'flex', md:'none'},
                    flexDirection: 'row',
                    alignItems: 'center'               
                }}
            >
                <img alt="fae" height={50} src="/fae.png"/>
                <img alt="qualiopi" height={50} src="/qualiopi.png"/>
            </Box>
            <Grid container>
                <Grid item xs={false} md={8}>
                <Box
                        sx={{
                            display: {xs:'none', md:'block'},
                        }}
                    >
                {firstImageLoad ?
                    <Carousel
                        animation='slide'
                        stopAutoPlayOnHover={false}
                        indicators={false}
                        swipe={false}
                    >
                    {
                        items.map( (item) => <Item src={item.src} key={item.src}/> )
                    }
                    </Carousel>
                    :
                    <Item src="/noe_saut.webp"/>
                 }
                </Box>
                </Grid>
                <Grid item
                    xs={12}
                    md={4}
                >
                   <Box
                        sx={{
                            my: 8,
                            mx: 4,
                            display: {xs:'block', md:'none'},
                            flexDirection: 'column',
                            alignItems: 'center'
                        }}
                    >

                    <Item src="/noe_saut.webp"/>

                    </Box>
                    <Box
                        sx={{
                            my: 8,
                            mx: 4,
                            display: {xs:'none', md:'flex'},
                            flexDirection: 'column',
                            alignItems: 'center',                        
                        }}
                    >
                        <img width={256} src="/fae.png"/>
                    </Box>
                    <Box sx={{ mt: 1 , ml:'10px', mr:'10px'}}>
                        <TextField
                            inputRef={mailRef}
                            margin="normal"
                            required
                            error={!mailValid}
                            fullWidth
                            id="email"
                            label="Adresse Mail"
                            name="email"
                            autoComplete="email"
                            autoFocus
                            helperText={mailValid ? '' : 'Mail invalide'}
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                setMailValid(true);
                            }}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <EmailIcon />
                                    </InputAdornment>
                                ),
                            }}
                        />
                        <TextField
                            inputRef={passwordRef}
                            margin="normal"
                            required
                            fullWidth
                            name="password"
                            label="Mot de Passe"
                            type={showPassword ? "text" : "password"}
                            id="password"
                            autoComplete="current-password"                            
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="start">
                                        <IconButton
                                            onClick={() => { setShowPassword(false) }}
                                            onMouseDown={() => { setShowPassword(true) }}
                                        >
                                            <VisibilityIcon />
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                        />
                        <FormControl
                            margin="normal" 
                            fullWidth
                        >
                            <InputLabel id="profileLabel">Profil</InputLabel>
                            <Select
                                labelId="profileLabel"
                                id="profileSelect"
                                label="Profil"
                                value={profile}
                                onChange={(event: SelectChangeEvent<HTMLInputElement>)=> {
                                    setProfile(event.target.value);
                                }}
                            >
                                <MenuItem value={0}>
                                    <div style={{ display: 'flex', alignItems: 'center'}}>
                                        <AdminPanelSettingsIcon />
                                        <div style={{marginLeft:'10px'}}>Administrateur</div>
                                    </div>
                                </MenuItem>
                                <MenuItem value={1}>
                                    <div style={{ display: 'flex', alignItems: 'center'}}>
                                        <SchoolIcon />
                                        <div style={{marginLeft:'10px'}}>Formateur</div>
                                    </div>
                                </MenuItem>
                                <MenuItem value={2}>
                                    <div style={{ display: 'flex', alignItems: 'center'}}>
                                        <SupervisorAccountIcon />
                                        <div style={{marginLeft:'10px'}}>Tuteur</div>
                                    </div>
                                </MenuItem>
                                <MenuItem value={3}>
                                    <div style={{ display: 'flex', alignItems: 'center'}}>
                                        <PersonIcon />
                                        <div style={{marginLeft:'10px'}}>Stagiaire</div>
                                    </div>
                                </MenuItem>
                            </Select>
                        </FormControl>
                        <Button
                            fullWidth
                            variant="contained"
                            sx={{ mt: 3, mb: 2 }}
                            onClick={() => { handleSubmit() }}
                        >
                            Se Connecter
                        </Button>
                        <Button onClick={()=>setForgot(true)}>
                            Mot de passe oublié
                        </Button>
                    </Box>
                    {error === 1 ?
                        <Alert severity="error">Identfiants invalides</Alert>
                        :
                     error === 2 ?
                        <Alert severity="error">{"Vous n'etes pas autorisé à vous connecter avec ce profil"}</Alert>
                        :
                        null
                    }
                   <Box
                        sx={{
                            my: 8,
                            mx: 4,
                            display: {xs:'none', md:'flex'},
                            flexDirection: 'column',
                            alignItems: 'center'
                        }}
                    >
                        <img width={512} src="/qualiopi.png"/>
                    </Box>
                    <Box
                        sx={{
                            my: 8,
                            mx: 4,
                            display: {xs:'none', md:'flex'},
                            flexDirection: 'row',
                            alignItems: 'center'
                        }}
                    >
                        <img width={32} src="/Charisma32.png"/>
                        <Typography>
                            Plateforme de formation propulsée par <i>Charisma</i>
                        </Typography>
                    </Box>
                </Grid>
            </Grid>
            {forgot?
                <CharismaUserForgotPasswordForm
                    onOk = {onOkForgot}
                />
                :
                null
            }
        </Box>
    );
}
