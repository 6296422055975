import * as React from 'react'
import CharismaStudentTrainingsList from '../students/studentTrainingsList.tsx'
import CharismaStudentSpace from '../students/studentSpace.tsx';
import Grid from '@mui/material/Grid'

import { useParams} from 'react-router-dom';    

export default function CharismaWelcomeStudent(props){
    
    let params = useParams();
    const lTrainingStudentId: number =  parseInt(params['trainingId']);
    const lPage: string =  params['page'];
    
    
    return(
        <Grid container spacing={4}>
            <Grid item xs={12} sm={2}>
                <CharismaStudentTrainingsList
                    studentId={props.studentId}
                    studentTrainingId = {lTrainingStudentId}
                />
            </Grid>
            <Grid item xs={12} sm={10}>
                {lTrainingStudentId !== undefined && !isNaN(lTrainingStudentId) ?                                   
                    <CharismaStudentSpace
                        studentTrainingId = {lTrainingStudentId}
                        page={lPage}
                    />
                    :
                    null
                } 
            </Grid>
        </Grid>
  
    );
}