import * as React from 'react';

import Alert from '@mui/material/Alert'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import FormControl from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select'
import TextField from '@mui/material/TextField';


import { ValidateString } from '../utils/Validator.tsx';

export default function CharismaAssessmentTemplateItemForm(props){
    
    const [name, setName] = React.useState(props.name);
    const [type, setType] = React.useState(props.type !== undefined ? props.type : 0);
    const [stat, setStat] = React.useState(props.stat !== undefined ? props.stat : 0);
    const [nError, setNError] = React.useState(0);
    const [wait, setWait] = React.useState(false);

    
    function handleSubmit(){
        const lValidName: boolean = ValidateString(name, 32);
        if(!lValidName){
            setNError(1);
        }
        
        else if(lValidName && props.id === undefined){
            setWait(false);
            props.handleSubmit(name, type, stat);
        }
        else if(lValidName){
            setWait(false);
            props.handleSubmit(name, type, stat, props.id);
        }
        
    }
    
    return(
        <Dialog
            open={true}
        >
            <DialogTitle>
                {props.title === undefined ?
                    'Modification du rapport'
                    :
                    props.title
                }
            </DialogTitle>
            <DialogContent>
                <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="name"
                    label="Nom"
                    name="type"
                    autoFocus
                    error={nError > 0}
                    helperText={nError > 0 ? 'Nom invalide' : null}
                    value={name}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                        setName(event.target.value);
                        setNError(0);
                    }}
                />   
                <FormControl fullWidth sx={{mt:'10px'}}>
                <InputLabel id="label">Type</InputLabel>
                    <Select
                        labelId="label"
                        id="type-select"
                        value={type}
                        label="Type"
                        onChange={(event)=>setType(event.target.value)}
                    >
                    <MenuItem value={0}>Evaluation</MenuItem>
                    <MenuItem value={1}>Commentaire</MenuItem>
                  </Select>
                </FormControl>     
                <FormControl fullWidth sx={{mt:'10px'}}>
                <InputLabel id="label">Evalué</InputLabel>
                    <Select
                        labelId="label"
                        id="type-select"
                        value={stat}
                        label="Evalué"
                        onChange={(event)=>setStat(event.target.value)}
                    >
                    <MenuItem value={0}>Non</MenuItem>
                    <MenuItem value={1}>Oui</MenuItem>
                  </Select>
                </FormControl>
                {wait ?
                    <Alert severity="info">{props.id===undefined ? "Création en cours" : "Modification en cours"}</Alert>
                    :
                    null
                }                      
            </DialogContent>
            <DialogActions>
                <Button disabled={wait} onClick={()=>handleSubmit()}>Valider</Button>
                <Button disabled={wait} onClick={()=>props.handleCancel()}>Annuler</Button>
            </DialogActions>
        </Dialog>        
    );
}