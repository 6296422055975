import * as React from 'react';


import Box from '@mui/material/Box'


import AddIcon from '@mui/icons-material/Add'
import Alert from '@mui/material/Alert'
import Button from '@mui/material/Button'
import Card from'@mui/material/Card'
import CardContent from'@mui/material/CardContent'
import CardHeader from'@mui/material/CardHeader'
import { CharismaTrainingApi } from '../api/trainings/CharismaTrainingApi.tsx';
import CharismaTrainingPeriodForm from './trainingPeriodForm.tsx';
import { CharismaUserContext } from '../context.tsx';
import Checkbox from '@mui/material/Checkbox';
import DeleteIcon from '@mui/icons-material/Delete'
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import EditIcon from '@mui/icons-material/Edit'
import {formatDate} from '../utils.tsx'
import Grid from '@mui/material/Grid'
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';




export default function CharismaTrainigPeriodsList(props){
    
    interface trainingPeriod{
        id: number,
        name: string,
        startDate: string,
        endDate: string
    }    
    const [currentTrainingPeriod, setCurrentTrainingPeriod] = React.useState(null);
    const [create, setCreate] = React.useState(false);
    const [trainingPeriodsList, setTrainingPeriodsList] = React.useState([]);
    const [deleteTrainingPeriods, setDeleteTrainingPeriods] = React.useState(false);
    const [selectedTrainingPeriods, setSelectedTrainingPeriods] = React.useState([]);   
   
    const lCurrentUser = React.useContext(CharismaUserContext);
    const lReadWrite = lCurrentUser !== null && lCurrentUser !== undefined && lCurrentUser.profile === 0;
    const lTrainingId = props.trainingId;    


    React.useEffect(()=>{
        const lResponsePeriods = CharismaTrainingApi.ListTrainingPeriods(lTrainingId);
        lResponsePeriods.then((value)=>{
            if(value.error === 0){
                setTrainingPeriodsList(value.trainingPeriods);
            }
        }).catch((error)=>{
            
        })
    }, [lTrainingId])
    
    
          
    function handleCreateTrainingPeriod(pName : string, pStartDate : string, pEndDate: string){
        const lResponse = CharismaTrainingApi.CreateTrainingPeriod(pName, pStartDate, pEndDate, lTrainingId);
        lResponse.then((value)=>{
            if(value.error === 0){
                if(value.trainingPeriod !== null){
                    let lTrainingPeriods = trainingPeriodsList.concat(value.trainingPeriod);
                    setTrainingPeriodsList(lTrainingPeriods);
                    setCreate(false);
                }
                
            }
            else{
                
            }
        }).catch((error)=>{
                console.log("catch error")
        })
    } 
    function handleCancelCreate(){
        setCreate(false);
    }
       
    function handleUpdateTrainingPeriod(pName : string, pStartDate : string, pEndDate: string, pId : number){
        const lResponse = CharismaTrainingApi.UpdateTrainingPeriod(pName, pStartDate, pEndDate, pId);
        lResponse.then((value)=>{
            if(value.error === 0){
                if(value.trainingPeriod !== null){
                    const lTrainingsPeriods = trainingPeriodsList.map((lPeriod)=>lPeriod.id !== value.trainingPeriod.id ? lPeriod : value.trainingPeriod);
                    setTrainingPeriodsList(lTrainingsPeriods);
                    setCurrentTrainingPeriod(null);
                }
                
            }
            else{
                
            }
        }).catch((error)=>{
                console.log("catch error")
        })
    }       
        
    function handleDeleteTrainingPeriods(pSelectedCourses){
        const lResponse = CharismaTrainingApi.DeleteTrainingPeriods(selectedTrainingPeriods);
        lResponse.then((value)=>{
            if(value.error === 0){
                if(value.ids !== null){
                    const lIds = value.ids;
                    let lPeriods = trainingPeriodsList.filter((lPeriod)=>lIds.indexOf(lPeriod.id) === -1);
                    setTrainingPeriodsList(lPeriods);
                    setDeleteTrainingPeriods(false);                  
                }
;            }
            else{
            
            }
        }).catch((error)=>{
            console.log("catch error")
        })           
    }            
    
    function handleSelectedTrainingPeriod(pEvent : React.ChangeEvent<HTMLInputElement>, pId : number){
        let lNewSelected : number[] = [];
        let lIndex = selectedTrainingPeriods.indexOf(pId);
        if(pEvent.target.checked && lIndex === -1){
            lNewSelected = lNewSelected.concat(selectedTrainingPeriods, pId);
        }
        else if(lIndex !== -1){
            if(lIndex === 0){
                lNewSelected = lNewSelected.concat(selectedTrainingPeriods.slice(1));
            }
            else if(lIndex === selectedTrainingPeriods.length - 1){
                lNewSelected = lNewSelected.concat(selectedTrainingPeriods.slice(0, -1));
            }
            else{
                lNewSelected = lNewSelected.concat(selectedTrainingPeriods.slice(0, lIndex), selectedTrainingPeriods.slice(lIndex + 1),);
            }
        }
        setSelectedTrainingPeriods(lNewSelected);
    }
            
    
    function handleCancelUpdate(){
        setCurrentTrainingPeriod(null);
    }
    
    function handleEdit(pId : number){
        const lResponse = CharismaTrainingApi.GetTrainingPeriod(pId);
        lResponse.then((value)=>{
            if(value.error === 0){
                setCurrentTrainingPeriod(value.trainingPeriod);
                
            }
            else{
                
            }
        }).catch((error)=>{
                console.log("catch error")
        })  
    }
    
    
    function createTrainingPeriodsTable(pTrainingPeriods : trainingPeriod[], pSelected : number[]){
        return(
            pTrainingPeriods.map((trainingPeriod)=>(
                <TableRow key={trainingPeriod.id}>
                    {lReadWrite ?
                        <TableCell padding="checkbox">
                            <Checkbox
                                checked={pSelected.indexOf(trainingPeriod.id) >= 0}
                                onClick={                                    
                                    (event)=>handleSelectedTrainingPeriod(event, trainingPeriod.id)
                                    }
                            />
                        </TableCell>
                        :
                        null
                    }
                    <TableCell>
                        {trainingPeriod.name}
                    </TableCell>
                    <TableCell>
                        {formatDate(trainingPeriod.startDate)}
                    </TableCell>
                    <TableCell>
                        {formatDate(trainingPeriod.endDate)}
                    </TableCell>
                    {lReadWrite  ?
                        <TableCell padding="checkbox">
                            <IconButton
                                onClick={()=>handleEdit(trainingPeriod.id)}
                            >
                                <EditIcon/>
                            </IconButton>
                        </TableCell>
                        :
                        null
                    }
                </TableRow>
                 
            ))
        )
    }
    
    
    return(
        
        <Box>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={12}>
                    <Card>
                        <CardHeader
                            title="Périodes de formation"
                            action={
                                <div>
                                {lReadWrite?
                                <div>
                                <IconButton onClick={()=>setCreate(true)}>
                                    <AddIcon/>
                                </IconButton>
                                {selectedTrainingPeriods.length > 0?
                                    <IconButton onClick={()=>setDeleteTrainingPeriods(true)}>
                                        <DeleteIcon/>
                                    </IconButton>  
                                    :
                                    null
                                } 
                                </div>
                                :
                                null
                                }                                
                                </div>
                            }
                        />
                        <CardContent>
                            <Table>                               
                                <TableHead>
                                    <TableRow>
                                        {lReadWrite ?
                                            <TableCell padding="checkbox">
                                                </TableCell>
                                              :
                                              null
                                        }
                                        <TableCell>Nom</TableCell>
                                        <TableCell>Début</TableCell>
                                        <TableCell>Fin</TableCell>
                                        {lReadWrite ?
                                            <TableCell padding="checkbox">
                                            </TableCell>
                                              :
                                              null
                                        }
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                {
                                    createTrainingPeriodsTable(trainingPeriodsList, selectedTrainingPeriods)
                                }
                                </TableBody>
                            </Table>                            
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
            {create ?
                <CharismaTrainingPeriodForm
                    title={"Ajout d'une période de formation"}
                    handleSubmit={handleCreateTrainingPeriod}
                    handleCancel={handleCancelCreate}
                    minDate={props.startDate}
                    maxDate={props.endDate}
                />
                :
                null    
            }    
            {
                currentTrainingPeriod !== null ?
                <CharismaTrainingPeriodForm
                    title={currentTrainingPeriod.name}
                    name = {currentTrainingPeriod.name}
                    startDate={currentTrainingPeriod.startDate}
                    endDate={currentTrainingPeriod.endDate}
                    id={currentTrainingPeriod.id}
                    minDate={props.startDate}
                    maxDate={props.endDate}
                    handleSubmit={handleUpdateTrainingPeriod}
                    handleCancel={handleCancelUpdate}
                />
                :
                null
            }
            
            <Dialog
                open={deleteTrainingPeriods}
            >
                <DialogTitle>Suppression de Parcours</DialogTitle>
                <DialogContent>
                    <Typography>Etes-vous sûr de vouloir supprimer définitivement les périodes sélectionnés?</Typography>
                    {
                        false?
                            <Alert severity='info'>Suppression en cours...</Alert>
                        :
                        null
                    }
                </DialogContent>
                <DialogActions>
                    <Button autoFocus onClick={()=>setDeleteTrainingPeriods(false)}>
                        Annuler
                    </Button>
                    <Button onClick={handleDeleteTrainingPeriods}>
                        Valider
                    </Button>
                </DialogActions>
            </Dialog>                     
        </Box>      
    );
    
    
}