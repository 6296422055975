import * as React from 'react';

import AppBar from '@mui/material/AppBar'
import Box from '@mui/material/Box'
import IconButton from '@mui/material/IconButton'
import MenuIcon from '@mui/icons-material/Menu'
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem'
import Toolbar from '@mui/material/Toolbar'


export default function CharismaStudentTrainingMenu(props){
    
    
    const [anchorReportMenu, setAnchorReportMenu] = React.useState(null);
    const openReportMenu: boolean = Boolean(anchorReportMenu);

    const [anchorFollowUpMenu, setAnchorFollowUpMenu] = React.useState(null);
    const openFollowUpMenu: boolean = Boolean(anchorFollowUpMenu);

    const [anchorMobileMenu, setAnchorMobileMenu] = React.useState(null);
    const openMobileMenu: boolean = Boolean(anchorMobileMenu);
    
    //const lCurrentUser = React.useContext(CharismaUserContext);
    
    

    function handleOpenReportMenu(event: React.MouseEvent<HTMLElement>){
        setAnchorReportMenu(event.currentTarget);
    };
    
    function handleCloseReportMenu(){
        setAnchorReportMenu(null);
    };

    function handleOpenFollowUpMenu(event: React.MouseEvent<HTMLElement>){
        setAnchorFollowUpMenu(event.currentTarget);
    };
    
    function handleCloseFollowUpMenu(){
        setAnchorFollowUpMenu(null);
    };
    
    function handleOpenMobileMenu(event: React.MouseEvent<HTMLElement>){
        setAnchorMobileMenu(event.currentTarget);
    };
    
    function handleCloseMobileMenu(){
        setAnchorMobileMenu(null);
    };

    return(
        <AppBar position="static" style={{marginBottom:'10px', background:'gray'}}>
            <Toolbar>
                 <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>

                    <MenuItem
                        onClick={()=>props.handleMenu(0)}
                    >
                        Livret de Formation
                    </MenuItem>
                    <MenuItem
                        onClick={()=>props.handleMenu(1)}
                    >
                        Cours
                    </MenuItem>
                    <MenuItem
                        onClick={(event)=>handleOpenReportMenu(event)}
                    >
                        Bilans
                    </MenuItem>                   
                    <MenuItem
                        onClick={()=>props.handleMenu(2)}
                    >
                        Carnet de Bord
                    </MenuItem>
                    <MenuItem
                        onClick={()=>props.handleMenu(5)}
                    >
                        Alertes
                    </MenuItem>
                    <MenuItem
                        onClick={(event)=>handleOpenFollowUpMenu(event)}
                    >
                        Suivis
                    </MenuItem>
                    <MenuItem
                        onClick={()=>props.handleMenu(8)}
                    >
                        Absences
                    </MenuItem>                 
                 </Box>                 
                 <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
                    <IconButton color='inherit'
                        onClick={(event)=>handleOpenMobileMenu(event)}
                    >
                        <MenuIcon/>
                    </IconButton>
                 </Box>
            </Toolbar>
            <Menu
                open={openReportMenu}
                anchorEl={anchorReportMenu}
                onClose={handleCloseReportMenu}
                onClick={handleCloseReportMenu}
            >
                <MenuItem
                    onClick={()=>props.handleMenu(3)}
                >
                    Bilans Quotidiens
                </MenuItem>
                <MenuItem
                    onClick={()=>props.handleMenu(4)}
                >
                    Bilans Mensuels
                </MenuItem>
            </Menu>
            <Menu
                open={openFollowUpMenu}
                anchorEl={anchorFollowUpMenu}
                onClose={handleCloseFollowUpMenu}
                onClick={handleCloseFollowUpMenu}
            >
                <MenuItem
                    onClick={()=>props.handleMenu(6)}
                >
                    Visites Tuteur
                </MenuItem>
                <MenuItem
                    onClick={()=>props.handleMenu(7)}
                >
                    Visites de Régulation
                </MenuItem>
            </Menu>
            <Menu
                open={openMobileMenu}
                anchorEl={anchorMobileMenu}
                onClose={handleCloseMobileMenu}
                onClick={handleCloseMobileMenu}
            >
                <MenuItem
                    onClick={()=>props.handleMenu(0)}
                >
                    Livret de Formation
                </MenuItem>
                <MenuItem
                    onClick={()=>props.handleMenu(3)}
                >
                    Bilans Quotidiens
                </MenuItem>
                <MenuItem
                    onClick={()=>props.handleMenu(4)}
                >
                    Bilans Mensuels
                </MenuItem>
                <MenuItem
                    onClick={()=>props.handleMenu(2)}
                >
                    Carnet de Bord
                </MenuItem>        
                <MenuItem
                    onClick={()=>props.handleMenu(8)}
                >
                    Absences
                </MenuItem>                        
            </Menu>                
        </AppBar>  
    );
}