import * as React from 'react';

import Alert from "@mui/material/Alert"
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import TextField from '@mui/material/TextField';


export default function CharismaNameForm(props){
    
    const [name, setName] = React.useState(props.name === undefined ? '' : props.name);
    
    function handleSubmit(){
        props.handleSubmit(name)       
    }
    
    return(
        <Dialog
            open={true}
        >
            <DialogTitle>
                {props.title === undefined ?
                    'Renommer'
                    :
                    props.title
                }
            </DialogTitle>
            <DialogContent>
                <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="fName"
                    label="Nom"
                    name="name"
                    autoFocus
                    value={name}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                        setName(event.target.value);
                    }}
                />
                {props.wait ?
                    <Alert severity="info">{"Changement en cours..."}</Alert>
                    :
                    null
                }
            </DialogContent>
            <DialogActions>
                <Button disabled={props.wait} onClick={()=>handleSubmit()}>Valider</Button>
                <Button disabled={props.wait} onClick={()=>props.handleCancel()}>Annuler</Button>
            </DialogActions>
        </Dialog>        
    );
}