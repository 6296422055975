import * as React from 'react';

import AddIcon from '@mui/icons-material/Add'
import Alert from '@mui/material/Alert';

import Box from '@mui/material/Box'
import Button from "@mui/material/Button"
import Breadcrumbs from '@mui/material/Breadcrumbs';
import { CharismaDocumentsApi } from '../api/documents/CharismaDocumentsApi.tsx';
import CharismaFileForm from '../components/fileForm.tsx';
import CharismaNameForm from '../components/nameForm.tsx';
import Checkbox  from '@mui/material/Checkbox';
import DeleteIcon from '@mui/icons-material/Delete'
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import '../globals.js'
import Grid from '@mui/material/Grid'
import IconButton from '@mui/material/IconButton';
import InputBase from '@mui/material/InputBase'
import { Link, useParams} from 'react-router-dom';
import SearchIcon from '@mui/icons-material/Search';
import { styled, alpha } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Toolbar from '@mui/material/Toolbar'
import Typography from '@mui/material/Typography'
import {formatDate} from '../utils.tsx'

const Search = styled('div')(({ theme }) => ({
  position: 'relative',
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  '&:hover': {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginLeft: 0,
  width: '100%',
  [theme.breakpoints.up('sm')]: {
    marginLeft: theme.spacing(1),
    width: 'auto',
  },
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: '100%',
  position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: 'inherit',
  width: '100%',
  '& .MuiInputBase-input': {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create('width'),
    [theme.breakpoints.up('sm')]: {
      width: '12ch',
      '&:focus': {
        width: '20ch',
      },
    },
  },
}));

export default function CharismaDocumentsManager(props){
    
    
    let path = useParams();
    
    const [addFile, setAddFile] = React.useState(false);
    const [createDir, setCreateDir] = React.useState(false);
    const [deleteDirs, setDeleteDirs] = React.useState(false);
    const [deleteFiles, setDeleteFiles] = React.useState(false);
    const [dirs, setDirs] = React.useState([]);
    const [files, setFiles] = React.useState([]);
    const [searchDirs, setSearchDirs] = React.useState('');
    const [searchFiles, setSearchFiles] = React.useState('');
    const [selectedFiles, setSelectedFiles] = React.useState([]);
    const [selectedDirs, setSelectedDirs] = React.useState([]);
    const [wait, setWait] = React.useState(false);

    
    //const lCurrentUser = React.useContext(CharismaUserContext);
    const lReadWrite = true;
    
    React.useEffect(()=>{
        const lResponse = CharismaDocumentsApi.Scan(path["*"]);
        lResponse.then((value)=>{
            if(value.error === 0){
                setDirs(value.directories);
                setFiles(value.files);
                setSelectedDirs([]);
                setSelectedFiles([]);
                setSearchDirs('');
                setSearchFiles('');
            }
        }).catch((error)=>{
            
        })
    },[path])
    
    function handleAddFile(pFile:File){
        const lResponse = CharismaDocumentsApi.AddFile(pFile, path["*"]);
        setWait(true);
        lResponse.then((value)=>{
            if(value.error === 0){
                setAddFile(false);
                setFiles(value.files);
                setSelectedFiles([]);
            }
            else{
                
            }
            setWait(false);
        }).catch((error)=>{
            console.log("catch error");
            setWait(false);
        })     
    }
    
    function handleDeleteFiles(){
        const lResponse = CharismaDocumentsApi.DeleteFiles(selectedFiles, path["*"]);
        setWait(true);
        lResponse.then((value)=>{
            if(value.error === 0){
                setFiles(value.files);
                setDeleteFiles(false);
                setSelectedFiles([]);
            }
            else{
                
            }
            setWait(false);
        }).catch((error)=>{
            console.log("catch error")
        })         
    }
    
    function handleDeleteDirs(){
        const lResponse = CharismaDocumentsApi.DeleteDirs(selectedDirs, path["*"]);
        setWait(true);
        lResponse.then((value)=>{
            if(value.error === 0){
                setDirs(value.dirs);
                setDeleteDirs(false);
                setSelectedDirs([]);
            }
            else{
                
            }
            setWait(false);
        }).catch((error)=>{
            console.log("catch error")
        })    
    }
    
    function handleCancelAddFile(){
        setAddFile(false);
    }
    
    
    function handleCancelCreateDir(){
        setCreateDir(false);
    }
    
    function handleCreateDir(pName : string){
        const lResponse = CharismaDocumentsApi.CreateDirectory(path["*"], pName);
        setWait(true);
        lResponse.then((value)=>{
            if(value.error === 0){
                setCreateDir(false);
                setDirs(value.directories);
                setSelectedDirs([]);
            }
            else{
                
            }
            setWait(false);
        }).catch((error)=>{
            console.log("catch error");
            setWait(false);
        })           
    }    
    
    
    function handleSelectedFile(pEvent : React.ChangeEvent<HTMLInputElement>, pFilename : string){
        let lNewSelected : string[] = [];
        let lIndex = selectedFiles.indexOf(pFilename);
        if(pEvent.target.checked && lIndex === -1){
            lNewSelected = lNewSelected.concat(selectedFiles, pFilename);
        }
        else if(lIndex !== -1){
            if(lIndex === 0){
                lNewSelected = lNewSelected.concat(selectedFiles.slice(1));
            }
            else if(lIndex === selectedFiles.length - 1){
                lNewSelected = lNewSelected.concat(selectedFiles.slice(0, -1));
            }
            else{
                lNewSelected = lNewSelected.concat(selectedFiles.slice(0, lIndex), selectedFiles.slice(lIndex + 1),);
            }
        }
        setSelectedFiles(lNewSelected);
    }
    
    function handleUpdateEntries(){
        const lResponse = CharismaDocumentsApi.UpdateEntries(path["*"]);
        setWait(true);
        lResponse.then((value)=>{
            if(value.error === 0){

            }
            else{
                
            }
            setWait(false);
        }).catch((error)=>{
            console.log("catch error");
            setWait(false);
        })    
    }
    
    function handleSelectedDir(pEvent : React.ChangeEvent<HTMLInputElement>, pFilename : string){
        let lNewSelected : string[] = [];
        let lIndex = selectedDirs.indexOf(pFilename);
        if(pEvent.target.checked && lIndex === -1){
            lNewSelected = lNewSelected.concat(selectedDirs, pFilename);
        }
        else if(lIndex !== -1){
            if(lIndex === 0){
                lNewSelected = lNewSelected.concat(selectedDirs.slice(1));
            }
            else if(lIndex === selectedDirs.length - 1){
                lNewSelected = lNewSelected.concat(selectedDirs.slice(0, -1));
            }
            else{
                lNewSelected = lNewSelected.concat(selectedDirs.slice(0, lIndex), selectedDirs.slice(lIndex + 1),);
            }
        }
        setSelectedDirs(lNewSelected);
    }
    
    let lPathes : string[] = path["*"].split('/').filter((lPath)=>lPath !== "");
    let lBreadcrumbsLink = global.documentsRoute;
    let lBrItems=[];
    
    lPathes.forEach((lPath)=>{
        lBreadcrumbsLink = lBreadcrumbsLink + '/' + lPath;
        lBrItems.push({'path':lPath, 'link':lBreadcrumbsLink});
    });

    const lDirs = dirs.filter((dir)=>(dir.name.toLowerCase().includes(searchDirs.toLowerCase())));
    const lFiles = files.filter((file)=>(file.name.toLowerCase().includes(searchFiles.toLowerCase())));

    return(
        <Box>
            <Typography variant="h5">Gestion des Documents</Typography>
            <Toolbar>
                <Breadcrumbs aria-label="breadcrumb" separator=">">
                   <Link 
                        to={global.documentsRoute}
                    >
                    Documents
                    </Link>
                    {
                        lBrItems.map((lItem)=>(
                            <Link 
                                to={lItem.link}
                            >
                            {lItem.path}
                            </Link>
                        ))
                    }
                </Breadcrumbs>
            </Toolbar>
            <Grid container spacing={4}>
                <Grid item xs={12} sm={6}>
                    <Toolbar>
                        <Typography>Fichiers</Typography>
                        {lReadWrite?
                            <IconButton onClick={()=>setAddFile(true)}>
                                <AddIcon/>
                            </IconButton>
                            :
                            null
                        }   
                        {lReadWrite && selectedFiles.length > 0 ?
                        <IconButton onClick={()=>setDeleteFiles(true)}>
                            <DeleteIcon/>
                        </IconButton>                    
                        :
                        null
                        }   

                    </Toolbar>
                    <Search>
                        <SearchIconWrapper>
                            <SearchIcon />
                        </SearchIconWrapper>
                        <StyledInputBase
                            placeholder="Rechercher…"
                            inputProps={{ 'aria-label': 'search' }}
                            onChange={(event)=>setSearchFiles(event.target.value)}
                            value={searchFiles}
                        />
                    </Search>
                    <Table>
                        <TableHead>
                            <TableRow>
                                {lReadWrite ?
                                    <TableCell padding="checkbox">
                                    </TableCell>
                                    :
                                    null
                                }
                                <TableCell>Nom</TableCell>
                                <TableCell>Ajouté par</TableCell>
                                <TableCell>Mis à jour le</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>                      
                        {
                            lFiles.map((file)=>(
                                <TableRow key={file.name}>
                                    {lReadWrite ?
                                        <TableCell padding="checkbox">
                                            <Checkbox
                                                checked={selectedFiles.indexOf(file.name) >= 0}
                                                onClick={(event)=>handleSelectedFile(event, file.name)}
                                            />
                                        </TableCell>
                                        :
                                        null
                                    }
                                    <TableCell>
                                        <Link to={file.link} target="_blank" rel="noopener noreferrer" >
                                            {file.name}
                                        </Link>
                                    </TableCell>
                                    <TableCell>
                                        {file.owner}
                                    </TableCell>
                                    <TableCell>
                                        {formatDate(file.lastModified)}
                                    </TableCell>
                                </TableRow>
                            ))
                        }                      
                        
                        </TableBody>
                    </Table>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Toolbar>
                    <Typography>Répertoire</Typography>
                    {lReadWrite?
                        <IconButton onClick={()=>setCreateDir(true)}>
                            <AddIcon/>
                        </IconButton>
                        :
                        null
                    }  
                    {lReadWrite && selectedDirs.length > 0 ?
                    <IconButton onClick={()=>setDeleteDirs(true)}>
                        <DeleteIcon/>
                    </IconButton>                    
                    :
                    null
                    }                      
                    </Toolbar>
                    <Search>
                        <SearchIconWrapper>
                            <SearchIcon />
                        </SearchIconWrapper>
                        <StyledInputBase
                            placeholder="Rechercher…"
                            inputProps={{ 'aria-label': 'search' }}
                            onChange={(event)=>setSearchDirs(event.target.value)}
                            value={searchDirs}
                        />
                    </Search>
                    <Table>
                        <TableHead>
                            <TableRow>
                                {lReadWrite ?
                                    <TableCell padding="checkbox">
                                    </TableCell>
                                    :
                                    null
                                }
                                <TableCell>Nom</TableCell>
                                <TableCell>Ajouté par</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>                      
                        {
                            lDirs.map((dir)=>(
                                <TableRow>
                                    {lReadWrite ?
                                        <TableCell padding="checkbox">
                                            <Checkbox
                                                checked={selectedDirs.indexOf(dir.name) >= 0}
                                                onClick={(event)=>handleSelectedDir(event, dir.name)}
                                            />
                                        </TableCell>
                                        :
                                        null
                                    }
                                    <TableCell>
                                        <Link to={global.documentsRoute+dir.link} >
                                            {dir.name}
                                        </Link>
                                    </TableCell>
                                    <TableCell>
                                        {dir.owner}
                                    </TableCell>
                                </TableRow>
                            ))
                        }                      
                        
                        </TableBody>
                    </Table>
                </Grid>                        
            </Grid>

            <Dialog
                open={deleteFiles}
            >
                <DialogTitle>Suppression de fichiers</DialogTitle>
                <DialogContent>
                    <Typography>Etes-vous sûr de vouloir supprimer les fichiers sélectionnés?</Typography>
                    {
                        wait?
                            <Alert severity='info'>Suppression en cours...</Alert>
                        :
                        null
                    }
                </DialogContent>
                <DialogActions>
                    <Button disabled={wait} autoFocus onClick={()=>setDeleteFiles(false)}>
                        Annuler
                    </Button>
                    <Button disabled={wait} onClick={handleDeleteFiles}>
                        Valider
                    </Button>
                </DialogActions>
            </Dialog>
            
            <Dialog
                open={deleteDirs}
            >
                <DialogTitle>Suppression de répertoire</DialogTitle>
                <DialogContent>
                    <Typography>Etes-vous sûr de vouloir supprimer les répertoires sélectionnés et leurs contenus?</Typography>
                </DialogContent>
                <DialogActions>
                    <Button disabled={wait} autoFocus onClick={()=>setDeleteDirs(false)}>
                        Annuler
                    </Button>
                    <Button disabled={wait} onClick={handleDeleteDirs}>
                        Valider
                    </Button>
                </DialogActions>
            </Dialog>            
            
            {
                addFile?
                    <CharismaFileForm
                        title={'Ajout d\'un document'}
                        handleCancel={handleCancelAddFile}
                        handleSubmit={handleAddFile}
                        wait={wait}
                    />
                    :
                    null
            }
            {
                createDir?
                    <CharismaNameForm
                        title={'Création d\'un répertoire'}
                        handleCancel={handleCancelCreateDir}
                        handleSubmit={handleCreateDir}
                        wait={wait}
                    />
                    :
                    null
            }            
        </Box>

    );
    
}