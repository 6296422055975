import * as React from 'react';


import AddIcon from "@mui/icons-material/Add"
import Box from '@mui/material/Box'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import CardHeader from '@mui/material/CardHeader';
import { CharismaAssessmentApi } from '../api/assessments/CharismaAssessmentApi.tsx';
import { CharismaEvaluationApi } from '../api/evaluations/CharismaEvaluationApi.tsx';
import { CharismaTrainingApi } from '../api/trainings/CharismaTrainingApi.tsx';
import { CharismaStudentTrainingApi } from '../api/studentTrainings/CharismaStudentTrainingApi.tsx'

import CharismaAssessmentDateForm from './assessmentDateForm.tsx'
import CharismaStudentTrainingShuttleForm from './studentTrainingShuttleForm.tsx'
import CharismaStudentTrainingEvaluationForm from './studentTrainingEvaluationForm.tsx'
import CharismaEvaluationForm from './evaluationForm.tsx'

import { CharismaUserContext } from '../context.tsx';
import EditIcon from "@mui/icons-material/Edit"
import {formatDate} from '../utils.tsx'
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem'
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';




export default function CharismaStudentTrainingPeriod(props){
    
    interface dateChangeInterface{
        id:number,
        evalType:string,
        oldDate:string       
    }


    const [period, setPeriod] = React.useState(null);
    const [evals, setEvals] = React.useState([]);
    const [currentEval, setCurrentEval] = React.useState(null);
    const [currentEvalType, setCurrentEvalType] = React.useState("");
    const [anchorMenu, setAnchorMenu] = React.useState(null);
    const [createTrainingSheet, setCreateTrainingSheet] = React.useState(false);
    const [createTutorSheet, setCreateTutorSheet] = React.useState(false);
    const [createExam, setCreateExam] = React.useState(false);    
    const [evaluationProfile, setEvaluationProfile] = React.useState(0);
    const [evaluationType, setEvaluationType] = React.useState(0);
    const [dateData, setDateData] = React.useState(null);
    const openMenu: boolean = Boolean(anchorMenu);

   
    const lCurrentUser = React.useContext(CharismaUserContext);
    const lReadWrite = lCurrentUser !== null && lCurrentUser !== undefined && lCurrentUser.profile === 0;
    const lStudentTrainingId = props.studentTrainingId;
    const lTrainingId = props.trainingId;
    const lPeriodId = props.periodId;
    const lStudentId = props.studentId;   


    React.useEffect(()=>{
        const lResponsePeriods = CharismaTrainingApi.GetTrainingPeriod(lPeriodId)
        lResponsePeriods.then((value)=>{
            if(value.error === 0){
                setPeriod(value.trainingPeriod);
            }
        }).catch((error)=>{
            
        })
    }, [lPeriodId])
    
    const lEvaluationTitle = [
        "Créer une évaluation",
        "Créer un QCM",
        "Créer un devoir écrit",
        "Créer un bilan périodique"
    ]
    
    React.useEffect(()=>{
        if(period !== null){
            const lResponse = CharismaStudentTrainingApi.ListPeriodEvals(period.startDate, period.endDate, props.studentTrainingId)
            lResponse.then((value)=>{
                if(value.error === 0){  
                    let lEvals = value.evals;
                    lEvals = lEvals.sort(function(a,b){
                                return (new Date(b.date)).getTime() - (new Date(a.date)).getTime(); 
                            });
                    setEvals(lEvals);         
                }
            }).catch((error)=>{
                
            })
        }
    }, [period,props.studentTrainingId])    
        
    function handleOpenMenu(event: React.MouseEvent<HTMLElement>){
        setAnchorMenu(event.currentTarget);
    };
    function handleCloseMenu(){
        setAnchorMenu(null);
    };   
    
    function handleCreateTutorSheet(pDate : string){
        const lResponse = CharismaAssessmentApi.CreateAssessment(lTrainingId, 3, pDate, lStudentTrainingId, lStudentId)
        lResponse.then((value)=>{
            if(value.error === 0){
                if(value.assessment !== null){
                    let lEvals = evals.concat(value.assessment);
                     lEvals = lEvals.sort(function(a,b){
                                return (new Date(b.date)).getTime() - (new Date(a.date)).getTime(); 
                            });
                    setEvals(lEvals);
                    setCreateTutorSheet(false);
                }
                
            }
            else{
                
            }
        }).catch((error)=>{
                console.log("catch error")
        })        
        
    }
    
    function handleValidChangeDate(pDate : string){
        if(dateData !== null){
            if(dateData.evalType === "assessment"){
                const lResponse = CharismaAssessmentApi.UpdateAssessmentDate(pDate, dateData.id)
                lResponse.then((value)=>{
                if(value.error === 0){
                        if(value.assessment !== null){
                        const lAssessmentId = value.assessment.id;
                        let lEvals = evals.slice();
                        for(let lIndex = 0; lIndex < lEvals.length; lIndex++){
                            if(lEvals[lIndex].id === lAssessmentId && lEvals[lIndex].evalType === "assessment"){
                                lEvals[lIndex].date = value.assessment.date;
                                lEvals = lEvals.sort(function(a,b){
                                    return (new Date(b.date)).getTime() - (new Date(a.date)).getTime(); 
                                });
                                setEvals(lEvals);
                                break;
                                
                            }
                        }                  
                    }                
                    setDateData(null);                                          
                }
                else{
                    
                }
                }).catch((error)=>{
                    console.log("catch error")
                })
            } 
            else if(dateData.evalType === "evaluation"){
                const lResponse = CharismaEvaluationApi.UpdateEvaluationDate(pDate, dateData.id)
                lResponse.then((value)=>{
                if(value.error === 0){
                        if(value.evaluation !== null){
                        const lId = value.evaluation.id;
                        let lEvals = evals.slice();
                        for(let lIndex = 0; lIndex < lEvals.length; lIndex++){
                            if(lEvals[lIndex].id === lId && lEvals[lIndex].evalType === "evaluation"){
                                lEvals[lIndex].date = value.evaluation.date;
                                lEvals = lEvals.sort(function(a,b){
                                    return (new Date(b.date)).getTime() - (new Date(a.date)).getTime(); 
                                });
                                setEvals(lEvals);
                                break;
                                
                            }
                        }                  
                    }                
                    setDateData(null);                                          
                }
                else{
                    
                }
                }).catch((error)=>{
                    console.log("catch error")
                })
            }               
        }     
    }
    
    function cancelChangeDate(){
        setDateData(null);
    }
    
    
    function cancelCreateTutorSheet(pDate : string){
        setCreateTutorSheet(false);
    }
    
    function handleCreateTrainingSheet(pDate : string){
        const lResponse = CharismaAssessmentApi.CreateAssessment(lTrainingId, 2, pDate, lStudentTrainingId, lStudentId)
        lResponse.then((value)=>{
            if(value.error === 0){
                if(value.assessment !== null){
                    let lEvals = evals.concat(value.assessment);
                     lEvals = lEvals.sort(function(a,b){
                                return (new Date(b.date)).getTime() - (new Date(a.date)).getTime(); 
                            });
                    setEvals(lEvals);
                    setCreateTrainingSheet(false);
                }
                
            }
            else{
                
            }
        }).catch((error)=>{
                console.log("catch error")
        })
    }
    
    function cancelCreateTrainingSheet(pDate : string){
        setCreateTrainingSheet(false);
    }
    
    
    function handleCreateExam(pDate : string, pTemplateId : number){
        const lResponse = CharismaEvaluationApi.CreateEvaluation(pTemplateId, evaluationType, pDate, lStudentTrainingId, lStudentId, evaluationProfile);

        lResponse.then((value)=>{
            if(value.error === 0){
                if(value.evaluation !== null){
                    let lEvals = evals.concat(value.evaluation);
                     lEvals = lEvals.sort(function(a,b){
                                return (new Date(b.date)).getTime() - (new Date(a.date)).getTime(); 
                            });
                    setEvals(lEvals);
                    setCreateExam(false);
                }
                
            }
            else{
                
            }
        }).catch((error)=>{
                console.log("catch error")
        })                
    }
    
    function onCreateExam(pProfile: number, pType : number){
        setCreateExam(true);
        setEvaluationProfile(pProfile);
        setEvaluationType(pType);
    }
    
    function handleCancelCreateExam(){
        setCreateExam(false);
        setEvaluationProfile(0);
        setEvaluationType(0);
    }
    
    function handleChangeDate(pId : number, pType : string, pDate:string){
        let lDateChange : dateChangeInterface = {evalType:pType, oldDate:pDate, id:pId};
        setDateData(lDateChange);
    }
            
    function handleEdit(pId : number, pType : string){
        if(pType === "assessment"){
            const lResponse = CharismaAssessmentApi.GetAssessment(pId)
            lResponse.then((value)=>{
                if(value.error === 0){
                    if(value.assessment !== null){
                        setCurrentEval(value.assessment);       
                        setCurrentEvalType(pType);               
                    }
                }
                else{
                
                }
            }).catch((error)=>{
                console.log("catch error")
            })  
        }       
        else if(pType === "evaluation"){
            const lResponse = CharismaEvaluationApi.GetEvaluation(pId)
            lResponse.then((value)=>{
                if(value.error === 0){
                    if(value.evaluation !== null){
                        setCurrentEval(value.evaluation);
                        setCurrentEvalType(pType);                      
                    }
                }
                else{
                
                }
            }).catch((error)=>{
                console.log("catch error")
            })            
        }
    }
    
    function handleCancel(){
        setCurrentEval(null);
        setCurrentEvalType("");
    }
        
    function handleSubmitEvaluation(pEvaluationId : number, pGlobalNote : number, pGlobalComment: string){
        const lResponse = CharismaEvaluationApi.ValidEvaluation(pEvaluationId, pGlobalNote, pGlobalComment);
        lResponse.then((value)=>{
            if(value.error === 0){                                    
                setCurrentEval(null);
                setCurrentEvalType("");        
                const lId = value.id;
                const lNote = value.note;
                let lEvals = evals.slice();
                for(let lIndex = 0; lIndex < lEvals.length; lIndex++){
                    let lEval = lEvals[lIndex];
                    if(lEval.id === lId && lEval.evalType === "evaluation"){
                        lEval.globalNote = lNote;
                        break;
                    }
                }   
                setEvals(lEvals);  
            }
            else{
            
            }
        }).catch((error)=>{
            console.log("catch error")
        })         
    }        
    
    function submitShuttle(pItems){
        const lResponse = CharismaAssessmentApi.UpdateAssessmentNotes(pItems, "", 0, 0)
        lResponse.then((value)=>{
            if(value.error === 0){
                if(value.assessment !== null){                                       
                    setCurrentEval(null);
                    setCurrentEvalType("");
                }
            }
            else{
            
            }
        }).catch((error)=>{
            console.log("catch error")
        })  
    }
    
    function getEvaluationColor(pEvaluation){
        let lColor = "";
        if(pEvaluation.evalType === "evaluation"){
            pEvaluation.globalNote >=2 ? lColor = "green" : pEvaluation.globalNote !== 0 ? lColor="red" : lColor = "pink";
        }
        return lColor;        
    }    
    
    function getFillBy(pEvaluation){        
        if(pEvaluation.evalType === "evaluation"){
            if(pEvaluation.type === 0){
                return "Formateur";
            }
            else if(pEvaluation.type === 1){
                return "Stagiaire";
            }
            else if(pEvaluation.type === 2){
                return "Stagiaire";
            }
            else if(pEvaluation.type === 3){
                if(pEvaluation.profile === 1){
                    return "Stagiaire/Formateur";
                }
                else if(pEvaluation.profile === 2){
                    return "Stagiaire/Tuteur";
                }
            }
        }
        return "";
    }
    
    function getStatus(pEvaluation){
        if(pEvaluation.evalType === "evaluation"){
            if(pEvaluation.type === 0){
                if(pEvaluation.signed){
                    return "Fait";
                }
                else{
                    return "A remplir";
                }
            }
            else if(pEvaluation.type === 1){
                if(pEvaluation.signedByStudent){
                    return "Fait";
                }
                else{
                    return "A faire";
                }
            }
            else if(pEvaluation.type === 2){
                if(pEvaluation.signedByStudent){
                    if(pEvaluation.signed){
                        return "Fait et corrigé";
                    }
                    else{
                        return "Fait, à corriger";
                    }
                }
                else{
                    return "A faire";
                }
            }
            else if(pEvaluation.type === 3){
                if(pEvaluation.profile === 1){
                    if(pEvaluation.signed){
                        if(pEvaluation.signedByStudent){
                            return "Fait";
                        }
                        else{
                            return "Rempli par le formateur";
                        }
                    }
                    else{
                        if(pEvaluation.signedByStudent){
                            return "Rempli par le stagiaire";
                        }
                        else{
                            return "A remplir";
                        }
                    }
                }
                else if(pEvaluation.profile === 2){
                    if(pEvaluation.signed){
                        if(pEvaluation.signedByStudent){
                            return "Fait";
                        }
                        else{
                            return "Rempli par le tuteur";
                        }
                    }
                    else{
                        if(pEvaluation.signedByStudent){
                            return "rempli par le stagiaire";
                        }
                        else{
                            return "A remplir";
                        }
                    }
                }
            }
        }
        return "";
    }
    
    return(
        
        <Box>   
            {
                period !== null ?
                <Card>
                    <CardHeader
                        title={period.name}
                        subheader={"du " + formatDate(period.startDate) + " au " + formatDate(period.endDate) }
                        action={
                            lReadWrite ?
                            <IconButton onClick={(event)=>handleOpenMenu(event)}>
                                <AddIcon/>
                            </IconButton>
                            :
                            null
                        }
                    />
                    <CardContent>
                        <TableContainer overflowX='auto'>
                        <Table>
                            <TableHead>
                                <TableRow key="title">
                                    <TableCell>
                                        Date
                                    </TableCell>
                                    <TableCell>
                                        Type
                                    </TableCell>
                                    <TableCell>
                                        A remplir par
                                    </TableCell>
                                    <TableCell>
                                        Statut
                                    </TableCell>
                                    <TableCell padding="checkbox">
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {
                                    evals.map((lEval)=>(
                                        <TableRow key={lEval.id} sx={{background:getEvaluationColor(lEval)}}>
                                            <TableCell>
                                                {formatDate(lEval.date)}
                                                {(lCurrentUser.profile === 0 && lEval.evalType === "evaluation") ||
                                                 lEval.evalType === "assessment" 
                                                    ?
                                                    <IconButton onClick={()=>handleChangeDate(lEval.id, lEval.evalType, lEval.date)}>
                                                        <EditIcon/>
                                                    </IconButton>
                                                    :
                                                    null
                                                }
                                            </TableCell>
                                            <TableCell>
                                                {lEval.title}
                                            </TableCell>
                                            <TableCell>
                                                {
                                                    getFillBy(lEval)
                                                }
                                            </TableCell>                                            
                                            <TableCell>
                                                {
                                                    getStatus(lEval)
                                                }
                                            </TableCell>  
                                            <TableCell>
                                                <IconButton onClick={()=>handleEdit(lEval.id, lEval.evalType)}>
                                                    <EditIcon/>
                                                </IconButton>
                                            </TableCell>
                                        </TableRow>    
                                    ))
                                }
                            </TableBody>
                        </Table>
                        </TableContainer>
                    </CardContent>
                </Card>
                :
                null
            }   
            <Menu
                open={openMenu}
                anchorEl={anchorMenu}
                onClose={handleCloseMenu}
                onClick={handleCloseMenu}
            >
                <MenuItem
                    onClick={()=>onCreateExam(1, 0)}
                >
                    Ajouter une évaluation OF
                </MenuItem>
                <MenuItem
                    onClick={()=>onCreateExam(2, 0)}
                >
                    Ajouter une évaluation Tuteur
                </MenuItem>
                {props.trainingType === 2 ?
                    <MenuItem
                        onClick={()=>onCreateExam(1,3)}
                    >
                        Ajouter un bilan périodique OF
                    </MenuItem>
                    :
                    null
                }
                {props.trainingType === 2 ?
                    <MenuItem
                        onClick={()=>onCreateExam(2,3)}
                    >
                        Ajouter un bilan périodique Tuteur
                    </MenuItem>
                    :
                    null
                }
                <MenuItem
                    onClick={()=>onCreateExam(3,1)}
                >
                    Ajouter un QCM
                </MenuItem>
                <MenuItem
                    onClick={()=>onCreateExam(3,2)}
                >
                    Ajouter un devoir
                </MenuItem>
                <MenuItem
                    onClick={()=>setCreateTrainingSheet(true)}
                >
                    Ajouter  une fiche navette OF
                </MenuItem>
                <MenuItem
                    onClick={()=>setCreateTutorSheet(true)}
                >
                    Ajouter  une fiche navette Tutorat
                </MenuItem>
            </Menu>  
            {
                dateData !== null ?
                <CharismaAssessmentDateForm
                    minDate = {period.startDate}
                    maxDate = {period.endDate}
                    title="Changer la date"
                    handleSubmit= {handleValidChangeDate}
                    handleCancel={cancelChangeDate}
                 />
                 :
                 null                
            }
            {createTrainingSheet ?
                <CharismaAssessmentDateForm
                    minDate = {period.startDate}
                    maxDate = {period.endDate}
                    handleSubmit= {handleCreateTrainingSheet}
                    handleCancel={cancelCreateTrainingSheet}
                 />
                 :
                 null
             }
             {createTutorSheet ?
                <CharismaAssessmentDateForm
                    minDate = {period.startDate}
                    maxDate = {period.endDate}
                    handleSubmit= {handleCreateTutorSheet}
                    handleCancel={cancelCreateTutorSheet}
                 />
                 :
                 null
             }
             {createExam ?
                <CharismaEvaluationForm
                    title = {lEvaluationTitle[evaluationType]}
                    minDate = {period.startDate}
                    maxDate = {period.endDate}
                    evals={
                        evaluationType ===  0 && evaluationProfile === 1 ? 
                            props.exams 
                            :
                        evaluationType ===  0 && evaluationProfile === 2 ? 
                            props.tutorExams
                            :
                        evaluationType ===  1 && evaluationProfile === 3 ? 
                            props.qcms
                            :
                        evaluationType ===  2 && evaluationProfile === 3 ?
                            props.homeworks
                            :
                        evaluationType ===  3 && evaluationProfile === 1 ?
                            props.periodReportsOf 
                            :
                        evaluationType ===  3 && evaluationProfile === 2 ?
                            props.periodReportsTutor
                         :
                         null                      
                    }
                    handleCancel={handleCancelCreateExam}
                    handleSubmit={handleCreateExam}
                />
                :
                null
             }             
             {currentEval !== null && currentEvalType === "assessment" ?
                <CharismaStudentTrainingShuttleForm
                    assessment={currentEval}
                    id={currentEval.id}
                    handleCancel={handleCancel}
                    handleSubmit={submitShuttle}
                    userProfile = {lCurrentUser.profile}
                    type = {currentEval.type}
                    readOnly = {!((lCurrentUser.profile === 0 || lCurrentUser.profile === 1) && currentEval.type === 2 || 
                                  (lCurrentUser.profile === 2 && currentEval.type === 3) || lCurrentUser.profile === 3)}
                />
                :
                null
             }
             {currentEval !== null && currentEvalType === "evaluation" ?
                <CharismaStudentTrainingEvaluationForm
                    evaluation={currentEval}
                    id={currentEval.id}
                    userProfile={lCurrentUser.profile}
                    handleCancel={handleCancel}             
                    handleSubmit={handleSubmitEvaluation}       
  
                />
                :
                null
             }
        </Box>      
    );
    
    
}