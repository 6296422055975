import React from 'react';
import App from './App.tsx';
import { createRoot } from 'react-dom/client';
import { frFR } from '@mui/material/locale';
import { createTheme, ThemeProvider } from '@mui/material/styles';

const domNode = document.getElementById('root');
const root = createRoot(domNode);
    const theme = createTheme(
    {
        palette: {
        primary: { main: '#1976d2' },
        },
    },
    frFR,
    );
root.render(
    <ThemeProvider theme={theme}>
    <App />
    </ThemeProvider>
    );
