import * as React from 'react';


import CharismaUserFilesManager from './userFilesManager.tsx';
import CharismaUserInfo from './userInfo.tsx';
import Grid from '@mui/material/Grid'



export default function CharismaUserProfile(props){
    const lUser=props.user;
    
    return(
        <Grid container spacing={4}>
            <Grid item xs={12} sm={4}>
                <CharismaUserInfo
                    user={lUser}
                />
            </Grid>
            <Grid item xs={12} sm={8}>
                <CharismaUserFilesManager
                    userId={lUser.id}
                    userPofile={lUser.profile}
                />
            </Grid>
        </Grid>

    );
    
    
}