import * as React from 'react';

import AddIcon from '@mui/icons-material/Add'
import Button from "@mui/material/Button"
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import CardHeader from '@mui/material/CardHeader';
import CharismaUserFileForm from './userFileForm.tsx';
import { CharismaUserApi } from '../api/users/CharismaUserApi.tsx';
import { CharismaUserContext } from '../context.tsx';
import Checkbox  from '@mui/material/Checkbox';
import DeleteIcon from '@mui/icons-material/Delete'
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import {formatDate} from '../utils.tsx'
import IconButton from '@mui/material/IconButton';
import { Link } from 'react-router-dom';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography'


export default function CharismaUserFilesManager(props){
    
    const [files, setFiles] = React.useState([]);
    const [addFile, setAddFile] = React.useState(false);
    const [deleteFiles, setDeleteFiles] = React.useState(false);
    const [selected, setSelected] = React.useState([]);
    const [userDocuments, setUserDocuments] = React.useState([]);
    
    const lCurrentUser = React.useContext(CharismaUserContext);
    const lReadWrite = lCurrentUser.id === props.userId
    const lUserProfile = props.userPofile;
    const lUserId = props.userId;
    React.useEffect(()=>{
        const lResponse = CharismaUserApi.ListUserDocument(lUserProfile === 0 ? 1 : lUserProfile);
        lResponse.then((value)=>{
            if(value.error === 0){
                setUserDocuments(value.documents);
            }
        }).catch((error)=>{});
        const lResponseFiles = CharismaUserApi.ListFiles(lUserId,lUserProfile);
        lResponseFiles.then((value)=>{
            if(value.error === 0){
                if(value.documents !== null){
                    setFiles(value.documents);
                }
            }
        }).catch((error)=>{})
    }, [lUserProfile, lUserId])
    
    function handleAddFile(pFile:File, pType : number){
        const lResponse = CharismaUserApi.AddFile(pFile, pType, props.userId, lUserProfile);
        lResponse.then((value)=>{
            if(value.error === 0){
                setAddFile(false);
                if(value.file !== null){
                    let lFiles = files.concat(value.file);
                    setFiles(lFiles);
                }
            }
            else{
                
            }
        }).catch((error)=>{
            console.log("catch error")
        })     
    }
    
    function handleCancelAddFile(){
        setAddFile(false);
    }
    
    function handleDelete(){
        const lResponse = CharismaUserApi.DeleteFiles(selected);
        lResponse.then((value)=>{
            if(value.error === 0){
                setDeleteFiles(false);
                setSelected([]);
                if(value.ids !== null){
                    const lIds = value.ids;
                    let lFiles = files.filter((lFile)=>(lIds.indexOf(lFile.path) === -1));
                    setFiles(lFiles);
                }
            }
            else{
                
            }
        }).catch((error)=>{
            console.log("catch error")
        })           
    }
    
    function handleSelected(pEvent : React.ChangeEvent<HTMLInputElement>, pId : string){
        let lNewSelected : number[] = [];
        let lIndex = selected.indexOf(pId);
        if(pEvent.target.checked && lIndex === -1){
            lNewSelected = lNewSelected.concat(selected, pId);
        }
        else if(lIndex !== -1){
            if(lIndex === 0){
                lNewSelected = lNewSelected.concat(selected.slice(1));
            }
            else if(lIndex === selected.length - 1){
                lNewSelected = lNewSelected.concat(selected.slice(0, -1));
            }
            else{
                lNewSelected = lNewSelected.concat(selected.slice(0, lIndex), selected.slice(lIndex + 1),);
            }
        }
        setSelected(lNewSelected);
    }
    
    function getTypeName(typeId : number){
        let lType : string = "";
        for(let lIndex = 0; lIndex < userDocuments.length; lIndex++ ){
            const currentDoc = userDocuments[lIndex];
            if(currentDoc.id === typeId){
                lType = currentDoc.type;
            }
        }
        return lType;
    }
    
    return(
        <Card>
            <CardHeader
                title={'Documents Personnels'}
                action={
                    <div>
                        {lReadWrite && selected.length > 0 ?
                         <IconButton onClick={()=>setDeleteFiles(true)}>
                            <DeleteIcon/>
                        </IconButton>                    
                        :
                        null
                       }
                        {lReadWrite  && userDocuments.length > 0?
                        <IconButton onClick={()=>setAddFile(true)}>
                            <AddIcon/>
                        </IconButton>
                        :
                        null
                        }
                    </div>
                }  
            />
            <CardContent>
  
                {files !== null ? 
                <Table>
                    <TableHead>
                        <TableRow>
                            {lReadWrite ?
                                <TableCell padding="checkbox">
                                </TableCell>
                                :
                                null
                            }
                            <TableCell>Nom</TableCell>
                            <TableCell>Type</TableCell>
                            <TableCell>Mis à jour le</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                    {
                        files.map((file)=>(
                            <TableRow>
                                {lReadWrite ?
                                    <TableCell padding="checkbox">
                                        <Checkbox
                                            checked={selected.indexOf(file.path) >= 0}
                                            onClick={(event)=>handleSelected(event, file.path)}
                                        />
                                    </TableCell>
                                    :
                                    null
                                }
                                <TableCell>
                                    <Link to={file.url} target="_blank" rel="noopener noreferrer" >
                                        {file.filename}
                                    </Link>
                                </TableCell>
                                <TableCell>
                                    {getTypeName(file.type)}
                                </TableCell>
                                <TableCell>
                                    {formatDate(file.updated)}
                                </TableCell>
                            </TableRow>
                        ))
                    }
                    
                    </TableBody>
                </Table>
                :
                null
                }
            </CardContent>
            {
                addFile?
                    <CharismaUserFileForm
                        title={'Ajout d\'un document personnel'}
                        type='any'
                        handleCancel={handleCancelAddFile}
                        handleSubmit={handleAddFile}
                        documentTypes={userDocuments}
                    />
                    :
                    null
            }

            <Dialog
                open={deleteFiles}
            >
                <DialogTitle>Suppression de fichiers</DialogTitle>
                <DialogContent>
                    <Typography>Etes-vous sûr de vouloir supprimer les fichiers sélectionnés?</Typography>
                </DialogContent>
                <DialogActions>
                    <Button autoFocus onClick={()=>setDeleteFiles(false)}>
                        Annuler
                    </Button>
                    <Button onClick={handleDelete}>
                        Valider
                    </Button>
                </DialogActions>
            </Dialog>
        </Card>
    );
    
}