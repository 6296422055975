import * as React from 'react';


import AddIcon from "@mui/icons-material/Add"
import Box from '@mui/material/Box'
import { CharismaEvaluationTemplateApi } from '../api/evaluations/CharismaEvaluationTemplateApi.tsx';
import CharismaAssessmentDateForm from './assessmentDateForm.tsx'
import CharismaStudentTrainingShuttleForm from './studentTrainingShuttleForm.tsx'
import CharismaStudentTrainingEvaluationForm from './studentTrainingEvaluationForm.tsx'
import CharismaEvaluationForm from './evaluationForm.tsx'


import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem'





export default function CharismaCreateEvaluation(props){


    const [anchorMenu, setAnchorMenu] = React.useState(null);
    const [createTrainingSheet, setCreateTrainingSheet] = React.useState(false);
    const [createTutorSheet, setCreateTutorSheet] = React.useState(false);
    const [createExam, setCreateExam] = React.useState(false);    
    const [evaluationProfile, setEvaluationProfile] = React.useState(0);
    const [evaluationType, setEvaluationType] = React.useState(0);    
    const [exams, setExams] = React.useState([]);
    const [tutorExams, setTutorExams] = React.useState([]);
    const [qcms, setQcms] = React.useState([]);
    const [homeworks, setHomeworks] = React.useState([]);
    const [periodReportsOf, setPeriodReportsOf] = React.useState([]);
    const [periodReportsTutor, setPeriodReportsTutor] = React.useState([]);  
    const openMenu: boolean = Boolean(anchorMenu);
    const [evalType, setEvalType] = React.useState(0);
    
    const lTrainingType = props.trainingType; 


    
    const lEvaluationTitle = [
        "Créer une évaluation",
        "Créer un QCM",
        "Créer un devoir écrit",
        "Créer un bilan périodique"
    ]
    
    React.useEffect(()=>{
        const lResponseExams = CharismaEvaluationTemplateApi.ListEvaluationTemplatesByProfile(0, 1);
        lResponseExams.then((value)=>{
            if(value.error === 0){
                if(value.evaluationTemplates !== null){
                    setExams(value.evaluationTemplates);
                }
                
        }
        }).catch((error)=>{
        
        })
        const lResponseTutorExams = CharismaEvaluationTemplateApi.ListEvaluationTemplatesByProfile(0, 2);
        lResponseTutorExams.then((value)=>{
            if(value.error === 0){
                if(value.evaluationTemplates !== null){
                    setTutorExams(value.evaluationTemplates);
                }
                
        }
        }).catch((error)=>{
        
        })
        if(lTrainingType === 2){
            const lResponsePeriodsOf = CharismaEvaluationTemplateApi.ListEvaluationTemplatesByProfile(3, 1);
            lResponsePeriodsOf.then((value)=>{
                if(value.error === 0){
                    if(value.evaluationTemplates !== null){
                        setPeriodReportsOf(value.evaluationTemplates);
                    }
                    
            }
            }).catch((error)=>{
            
            })
            const lResponsePeriodsTutor = CharismaEvaluationTemplateApi.ListEvaluationTemplatesByProfile(3, 2);
            lResponsePeriodsTutor.then((value)=>{
                if(value.error === 0){
                    if(value.evaluationTemplates !== null){
                        setPeriodReportsTutor(value.evaluationTemplates);
                    }
                    
            }
            }).catch((error)=>{
            
            }) 
        }
        const lResponseQcms = CharismaEvaluationTemplateApi.ListEvaluationTemplates(1);
        lResponseQcms.then((value)=>{
            if(value.error === 0){
                if(value.evaluationTemplates !== null){
                    setQcms(value.evaluationTemplates);
                }
                
            }
        }).catch((error)=>{
            
        })
        
        const lResponseHomeworks = CharismaEvaluationTemplateApi.ListEvaluationTemplates(2);
        lResponseHomeworks.then((value)=>{
            if(value.error === 0){
                if(value.evaluationTemplates !== null){
                    setHomeworks(value.evaluationTemplates);
                }
                
            }
        }).catch((error)=>{
            
        })        
    }, [lTrainingType])    
               
    function handleOpenMenu(event: React.MouseEvent<HTMLElement>){
        setAnchorMenu(event.currentTarget);
    };
    function handleCloseMenu(){
        setAnchorMenu(null);
    };   
    
    function handleCreateTutorSheet(pDate : string, pCourses : number[]){
        props.handleSubmit(0, 3, 3,0, pDate, pCourses);
        setCreateTutorSheet(false);        
    }
            
    function cancelCreateTutorSheet(){        
        setCreateTutorSheet(false);
        
    }
    
    function handleCreateTrainingSheet(pDate : string, pCourses : number[]){
        props.handleSubmit(0, 2, 2, 0, pDate, pCourses);
        setCreateTrainingSheet(false);        
    }
    
    function cancelCreateTrainingSheet(){
        setCreateTrainingSheet(false);
    }
    
    
    function handleCreateExam(pDate : string, pTemplateId : number, pCourses : number[]){        
        props.handleSubmit(1, evaluationType, evaluationProfile, pTemplateId, pDate, pCourses);
        setCreateExam(false);
    }
    
    function onCreateExam(pProfile: number, pType : number){
        setCreateExam(true);
        setEvaluationProfile(pProfile);
        setEvaluationType(pType);
    }
    
    function handleCancelCreateExam(){
        setCreateExam(false);
        setEvaluationProfile(0);
        setEvaluationType(0);
    }
    
    
    return(
        
        <Box>   
            <IconButton onClick={(event)=>handleOpenMenu(event)}>
                <AddIcon/>
            </IconButton>            
            <Menu
                open={openMenu}
                anchorEl={anchorMenu}
                onClose={handleCloseMenu}
                onClick={handleCloseMenu}
            >
                <MenuItem
                    onClick={()=>onCreateExam(1, 0)}
                >
                    Ajouter une évaluation OF
                </MenuItem>
                <MenuItem
                    onClick={()=>onCreateExam(2, 0)}
                >
                    Ajouter une évaluation Tuteur
                </MenuItem>
                {props.trainingType === 2 ?
                    <MenuItem
                        onClick={()=>onCreateExam(1,3)}
                    >
                        Ajouter un bilan périodique OF
                    </MenuItem>
                    :
                    null
                }
                {props.trainingType === 2 ?
                    <MenuItem
                        onClick={()=>onCreateExam(2,3)}
                    >
                        Ajouter un bilan périodique Tuteur
                    </MenuItem>
                    :
                    null
                }
                <MenuItem
                    onClick={()=>onCreateExam(3,1)}
                >
                    Ajouter un QCM
                </MenuItem>
                <MenuItem
                    onClick={()=>onCreateExam(3,2)}
                >
                    Ajouter un devoir
                </MenuItem>
                <MenuItem
                    onClick={()=>setCreateTrainingSheet(true)}
                >
                    Ajouter  une fiche navette OF
                </MenuItem>
                <MenuItem
                    onClick={()=>setCreateTutorSheet(true)}
                >
                    Ajouter  une fiche navette Tutorat
                </MenuItem>
            </Menu>  
            {createTrainingSheet ?
                <CharismaAssessmentDateForm
                    title={"Fiche Navette OF"}
                    minDate = {props.startDate}
                    maxDate = {props.endDate}
                    handleSubmit= {handleCreateTrainingSheet}
                    handleCancel={cancelCreateTrainingSheet}
                    courses={props.courses}
                 />
                 :
                 null
             }
             {createTutorSheet ?
                <CharismaAssessmentDateForm
                    title={"Fiche Navette Tutorat"}
                    minDate = {props.startDate}
                    maxDate = {props.endDate}
                    handleSubmit= {handleCreateTutorSheet}
                    handleCancel={cancelCreateTutorSheet}
                    courses={props.courses}
                 />
                 :
                 null
             }
             {createExam ?
                <CharismaEvaluationForm
                    title = {lEvaluationTitle[evaluationType]}
                    minDate = {props.startDate}
                    maxDate = {props.endDate}
                    courses={props.courses}
                    evals={
                        evaluationType ===  0 && evaluationProfile === 1 ? 
                            exams 
                            :
                        evaluationType ===  0 && evaluationProfile === 2 ? 
                            tutorExams
                            :
                        evaluationType ===  1 && evaluationProfile === 3 ? 
                            qcms
                            :
                        evaluationType ===  2 && evaluationProfile === 3 ?
                            homeworks
                            :
                        evaluationType ===  3 && evaluationProfile === 1 ?
                            periodReportsOf 
                            :
                        evaluationType ===  3 && evaluationProfile === 2 ?
                            periodReportsTutor
                         :
                         null                      
                    }
                    handleCancel={handleCancelCreateExam}
                    handleSubmit={handleCreateExam}
                />
                :
                null
             }             
        </Box>      
    );
    
    
}