import * as React from 'react';

import Alert from "@mui/material/Alert"
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem'
import Select from '@mui/material/Select'
import { ValidatePicture, ValidateFormat } from '../utils/Validator.tsx';

export default function CharismaStudentTrainingDocsForm(props){
    
    const [file, setFile] = React.useState(null);
    const [docType, setDocType] = React.useState(props.docTypes !== undefined && props.docTypes.length > 0 ? props.docTypes[0].id : 0);
    const [error, setError] = React.useState(0);
    const [wait, setWait] = React.useState(false);
    const [message, setMessage] = React.useState('');
    
    function handleSubmit(){
        let lValidFile: boolean = file !== null;
        
        if(!lValidFile){
            setMessage("Sélectionner un fichier");
            setError(2);
        }

        if(props.fileType === 'img' && lValidFile){
            lValidFile = ValidatePicture(file);
            if(!lValidFile){
                setMessage("Seules les images sont autorisées");
                setError(1);
            }
        }
        else if(props.fileType !== undefined && lValidFile){
            lValidFile = ValidateFormat(file, props.fileType);
            if(!lValidFile){
                setMessage("Seuls les fichiers au format "+ props.fileType + " sont autorisés");
                setError(1);
            }
        }
        
        if(lValidFile){
            let lDocType = "";
            if(props.type === 'subscribe'){
                for(let lIndex = 0; lIndex < props.docTypes.length; lIndex++){
                    const lDoc = props.docTypes[lIndex];
                    if(lDoc.id === docType){
                        lDocType = lDoc.type;
                    }
                }
                
            }
            setWait(true);
            props.handleSubmit(file, lDocType)
        }
    }
    
    return(
        <Dialog
            open={true}
        >
            <DialogTitle>
                {props.title === undefined ?
                    'Photo de profil'
                    :
                    props.title
                }
            </DialogTitle>
            <DialogContent>
                <FormControl>
                    <input
                        type='file'
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                            setFile(event.target.files[0]);
                            setError(0);
                    }}
                    />
                </FormControl>     
                {props.type === 'subscribe' && props.docTypes !== undefined?
                    <FormControl fullWidth sx={{mt:'10px'}}>
                    <InputLabel id="type">Type de Document</InputLabel>
                        <Select
                            labelId="type"
                            id="select"
                            value={docType}                            
                            label="Type de Document"
                            onChange={(event)=>setDocType(event.target.value)}
                        >
                        {
                            props.docTypes.map((document)=>(
                                <MenuItem value={document.id}>{document.type}</MenuItem>   
                            ))   
                        }
                      </Select>
                    </FormControl>  
                    :
                    null
                
                }          
                {error === 1 ?
                    <Alert severity="error">{"Format de fichier invalide:" + message}</Alert>
                    :
                  error === 2?
                    <Alert severity="error">{message}</Alert>
                    :
                    null
                }
                {wait ?
                    <Alert severity="info">{"Transfert du fichier en cours..."}</Alert>
                    :
                    null
                }
            </DialogContent>
            <DialogActions>
                <Button disabled={wait} onClick={()=>handleSubmit()}>Valider</Button>
                <Button disabled={wait} onClick={()=>props.handleCancel()}>Annuler</Button>
            </DialogActions>
        </Dialog>        
    );
}