
import { CharismaApi } from "../CharismaApi.js";

export const CharismaAssessmentApi = {
    CreateAssessment: async function(pTrainingId : number,  pType : number, pDate : string, pStudentTrainingId : number, pStudentId : number){
        const lDatas = new FormData();
        lDatas.append('type', pType);
        lDatas.append('date', pDate);
        lDatas.append('studentTrainingId', pStudentTrainingId);
        lDatas.append('studentId', pStudentId);
        lDatas.append('trainingId', pTrainingId);
        const lResponse = await CharismaApi.request({
            url:`/assessments/createAssessment.php`,
            method:"POST",
            data:lDatas
        })
        return lResponse.data;
    },
    ListAssessments: async function(pType : number, pStudentTrainingId : number){

        const lResponse = await CharismaApi.request({
            url:`/assessments/listAssessments.php`,
            method:"GET",
            params:{
                "type":pType,
                "studentTrainingId":pStudentTrainingId
            }
        })
        return lResponse.data;
    },    
    
    UpdateAssessmentDate: async function(pDate : string, pAssessmentId: number){
        const lDatas = new FormData();
        lDatas.append('date', pDate);
        lDatas.append('id', pAssessmentId);
        const lResponse = await CharismaApi.request({
            url:`/assessments/updateAssessmentDate.php`,
            method:"POST",
            data:lDatas
        })
        return lResponse.data;
    },
    
    UpdateAssessmentPresence: async function(pIsPresent : number, pAssessmentId: number){
        const lDatas = new FormData();
        lDatas.append('isPresent', pIsPresent);
        lDatas.append('id', pAssessmentId);
        const lResponse = await CharismaApi.request({
            url:`/assessments/updateAssessmentPresence.php`,
            method:"POST",
            data:lDatas
        })
        return lResponse.data;
    },
    
    DeleteAssessments: async function(pIds : number[]){
        const lDatas = new FormData();
        pIds.forEach((lId)=>{
            lDatas.append("ids[]", lId);    
        });
        const lResponse = await CharismaApi.request({
            url:`/assessments/deleteAssessments.php`,
            method:"POST",
            data:lDatas
        })
        return lResponse.data;
    },
    GetAssessment: async function(pId : number){
   
        const lResponse = await CharismaApi.request({
            url:`/assessments/getAssessment.php`,
            method:"GET",
            params:{
                "id" : pId
            }
        })
        return lResponse.data;
    },
    UpdateAssessmentNotes: async function(pItems, pComment:string, pPartId:number, pAssessmentId : number){
        const lDatas = new FormData();
        pItems.forEach((lItem)=>{
            lDatas.append("notes[]", lItem.id + "," + lItem.value);
            lDatas.append("comments[]", lItem.id + "," + lItem.comment); 
        });
        lDatas.append("partComment", pComment);
        lDatas.append("partId", pPartId);
        lDatas.append("assessmentId", pAssessmentId);
       
        const lResponse = await CharismaApi.request({
            url:`/assessments/updateAssessment.php`,
            method:"POST",
            data:lDatas
        })
        return lResponse.data;
    },
}